import Input from '@components/Input';
import PhoneNumberInput from '@components/PhoneNumberInput';
import { useTranslation } from 'react-i18next';
import SimpleBar from 'simplebar-react';
import Signataire from '@models/Signataire';
import { BsFillPlusCircleFill } from 'react-icons/bs';
import { FaTimesCircle, FaTrashAlt } from 'react-icons/fa';
import { SyntheticEvent, useRef, useState } from 'react';
import {
  SignataireForType,
  signataireInit
} from '@pages/Space/Souscription/Stepper/Configuration/types';
import { SignataireService, InvestorService } from '@services';
import { DANGER } from '@constants/colors';
import './Configuration.scss';

export interface Step1Props {
  step: number;
  signers: Signataire[];
  id: string;
  setStep: (step: number) => void;
  setSigners: (signers: Signataire[]) => void;
  goToPrevious: () => void;
  loadSigners: (id: string) => void;
  postData: () => void;
}

export default function Step1({
  step,
  signers,
  id,
  setStep,
  setSigners,
  goToPrevious,
  loadSigners,
  postData
}: Step1Props) {
  const { t } = useTranslation();
  const [addSigner, setAddSigner] = useState(false);
  const [signer, setSigner] = useState<SignataireForType>(signataireInit);

  const newSignerFormRef = useRef<HTMLFormElement>(null);

  const handleFormSigneChange = (key: string, value: string) => {
    setSigner({
      ...signer,
      [key]: value
    });
  };

  const handleFormSigneArrayChange = (
    key: string,
    value: string,
    id: string
  ) => {
    setSigners(
      signers.map((signer) => {
        if (signer.id === id) {
          return { ...signer, [key]: value };
        }
        return signer;
      })
    );
  };

  const saveSigner = async (e: SyntheticEvent) => {
    e.preventDefault();
    if (!newSignerFormRef.current?.checkValidity()) {
      newSignerFormRef.current?.reportValidity();
      return;
    }

    await SignataireService.store(signer, id || '');
    setAddSigner(false);
    setSigner({
      firstname: '',
      lastname: '',
      email: '',
      telephone: '',
      title: ''
    });
    loadSigners(id);
  };

  const removeSigner = async (idSigner: string) => {
    await SignataireService.delete(idSigner);
    loadSigners(id);
  };

  const updateSigner = async (idSigner: string) => {
    const data = signers.find((signer) => signer.id === idSigner);
    await SignataireService.update(data, idSigner);
  };

  const handleSubmit = () => {
    postData();
    (signers || []).forEach((signer: { id: string }) => {
      updateSigner(signer.id);
    });
    setStep(step + 1);
  };

  return (
    <>
      <SimpleBar className="w-100" style={{ height: '48vh' }}>
        <div className="row mt-4">
          <form onSubmit={handleSubmit} id="step1">
            {(signers || []).map((signe, index) => (
              <div className="col-md-12 mb-4" key={index}>
                <div className="my-3">
                  <div className="d-flex justify-content-between align-items-center w-100 mb-1">
                    <div className="lh-sm fw-600 fs-5">
                      {t(
                        'souscriptions.stepper.step.configuration.form.signer.form.update.title',
                        { index: index + 1 }
                      )}
                    </div>
                    <div className="d-flex align-items-center">
                      <div
                        className="cursor-pointer"
                        onClick={() => removeSigner(signe.id)}
                      >
                        <FaTrashAlt size={16} color={DANGER} />
                      </div>
                    </div>
                  </div>
                  <hr className="m-0" />
                </div>
                <div className="row g-md-4">
                  <Input
                    required
                    classBox="col-md-4"
                    lable={
                      t(
                        'souscriptions.stepper.step.configuration.form.signer.nom.label'
                      ) || ''
                    }
                    placeholder={
                      t(
                        'souscriptions.stepper.step.configuration.form.signer.nom.placeholder'
                      ) || ''
                    }
                    value={signe.lastname || ''}
                    classInput="text-extra"
                    classLable="text-extra fw-600"
                    onChange={(e) =>
                      handleFormSigneArrayChange(
                        'lastname',
                        e.target.value,
                        signe.id
                      )
                    }
                  />
                  <Input
                    required
                    classBox="col-md-4"
                    lable={
                      t(
                        'souscriptions.stepper.step.configuration.form.signer.prenom.label'
                      ) || ''
                    }
                    placeholder={
                      t(
                        'souscriptions.stepper.step.configuration.form.signer.prenom.placeholder'
                      ) || ''
                    }
                    value={signe.firstname || ''}
                    classInput="text-extra"
                    classLable="text-extra fw-600"
                    onChange={(e) =>
                      handleFormSigneArrayChange(
                        'firstname',
                        e.target.value,
                        signe.id
                      )
                    }
                  />
                  <Input
                    required
                    classBox="col-md-4"
                    lable={
                      t(
                        'souscriptions.stepper.step.configuration.form.signer.titre.label'
                      ) || ''
                    }
                    placeholder={
                      t(
                        'souscriptions.stepper.step.configuration.form.signer.titre.placeholder'
                      ) || ''
                    }
                    value={signe.title || ''}
                    classInput="text-extra"
                    classLable="text-extra fw-600"
                    onChange={(e) =>
                      handleFormSigneArrayChange(
                        'title',
                        e.target.value,
                        signe.id
                      )
                    }
                  />
                  <Input
                    required
                    classBox="col-md-4"
                    lable={
                      t(
                        'souscriptions.stepper.step.configuration.form.signer.email.label'
                      ) || ''
                    }
                    placeholder={
                      t(
                        'souscriptions.stepper.step.configuration.form.signer.email.placeholder'
                      ) || ''
                    }
                    type="email"
                    value={signe.email || ''}
                    classInput="text-extra"
                    classLable="text-extra fw-600"
                    onChange={(e) =>
                      handleFormSigneArrayChange(
                        'email',
                        e.target.value,
                        signe.id
                      )
                    }
                  />
                  <div className="col-md-4">
                    <PhoneNumberInput
                      required
                      lable={
                        t(
                          'souscriptions.stepper.step.configuration.form.signer.telephone.label'
                        ) || ''
                      }
                      placeholder={
                        t(
                          'souscriptions.stepper.step.configuration.form.signer.telephone.placeholder'
                        ) || ''
                      }
                      value={signe?.telephone || ''}
                      classInput="text-extra"
                      classLable="text-extra fw-600"
                      onChangeValue={(value) =>
                        handleFormSigneArrayChange('telephone', value, signe.id)
                      }
                    />
                  </div>
                </div>
              </div>
            ))}
          </form>

          {addSigner ? (
            <form
              ref={newSignerFormRef}
              onSubmit={saveSigner}
              className="col-md-12 mb-4"
            >
              <div className="mb-3">
                <div className="d-flex justify-content-between align-items-center w-100 mb-1">
                  <div className="lh-sm fw-600 fs-5">
                    {t(
                      'souscriptions.stepper.step.configuration.form.signer.form.add.title'
                    )}
                  </div>
                  <div
                    className="cursor-pointer"
                    onClick={() => setAddSigner(false)}
                  >
                    <FaTimesCircle size={20} />
                  </div>
                </div>
                <hr className="m-0" />
              </div>

              <div className="row g-md-4">
                <Input
                  required
                  classBox="col-md-4"
                  lable={
                    t(
                      'souscriptions.stepper.step.configuration.form.signer.nom.label'
                    ) || ''
                  }
                  placeholder={
                    t(
                      'souscriptions.stepper.step.configuration.form.signer.nom.placeholder'
                    ) || ''
                  }
                  value={signer.lastname || ''}
                  classInput="text-extra"
                  classLable="text-extra fw-600"
                  onChange={(e) =>
                    handleFormSigneChange('lastname', e.target.value)
                  }
                />

                <Input
                  required
                  classBox="col-md-4"
                  lable={
                    t(
                      'souscriptions.stepper.step.configuration.form.signer.prenom.label'
                    ) || ''
                  }
                  placeholder={
                    t(
                      'souscriptions.stepper.step.configuration.form.signer.prenom.placeholder'
                    ) || ''
                  }
                  value={signer.firstname || ''}
                  classInput="text-extra"
                  classLable="text-extra fw-600"
                  onChange={(e) =>
                    handleFormSigneChange('firstname', e.target.value)
                  }
                />

                <Input
                  required
                  classBox="col-md-4"
                  placeholder={
                    t(
                      'souscriptions.stepper.step.configuration.form.signer.titre.placeholder'
                    ) || ''
                  }
                  lable={
                    t(
                      'souscriptions.stepper.step.configuration.form.signer.titre.label'
                    ) || ''
                  }
                  value={signer.title || ''}
                  classInput="text-extra"
                  classLable="text-extra fw-600"
                  onChange={(e) =>
                    handleFormSigneChange('title', e.target.value)
                  }
                />

                <Input
                  required
                  classBox="col-md-4"
                  placeholder={
                    t(
                      'souscriptions.stepper.step.configuration.form.signer.email.placeholder'
                    ) || ''
                  }
                  type="email"
                  lable={
                    t(
                      'souscriptions.stepper.step.configuration.form.signer.email.label'
                    ) || ''
                  }
                  value={signer.email || ''}
                  classInput="text-extra"
                  classLable="text-extra fw-600"
                  onChange={(e) =>
                    handleFormSigneChange('email', e.target.value)
                  }
                />

                <div className="col-md-4">
                  <PhoneNumberInput
                    required
                    lable={
                      t(
                        'souscriptions.stepper.step.configuration.form.signer.telephone.label'
                      ) || ''
                    }
                    placeholder={
                      t(
                        'souscriptions.stepper.step.configuration.form.signer.telephone.placeholder'
                      ) || ''
                    }
                    value={signer?.telephone || ''}
                    classInput="text-extra"
                    classLable="text-extra fw-600"
                    onChangeValue={(value) =>
                      handleFormSigneChange('telephone', value)
                    }
                  />
                </div>

                <div className="col-md-4">
                  <button
                    type="submit"
                    className="btn btn-basic text-white mt-4 me-2"
                  >
                    {t('souscriptions.stepper.button.valider')}
                  </button>
                </div>
              </div>
            </form>
          ) : (
            <div className="col-md-12 d-flex align-items-center">
              <div
                className="text-extra d-flex align-items-center add-signataire-btn cursor-pointer"
                onClick={() => setAddSigner(true)}
              >
                <BsFillPlusCircleFill size={16} color="#1a468e" />
                <span
                  className="ms-2 fs-6 pl-2 fw-600"
                  style={{ textTransform: 'none' }}
                >
                  {signers.length === 0
                    ? t('souscriptions.stepper.button.saveSigner')
                    : t('souscriptions.stepper.button.addSigner')}
                </span>
              </div>
            </div>
          )}
        </div>
      </SimpleBar>

      <div className="d-flex justify-content-center gap-4 pt-4">
        <button
          type="button"
          onClick={goToPrevious}
          className="btn btn-extra border-2 me-2"
        >
          {t('souscriptions.stepper.button.retour')}
        </button>

        {!addSigner && (
          <button
            type="submit"
            className="btn btn-basic text-white"
            form="step1"
          >
            {t('souscriptions.stepper.button.valider')}
          </button>
        )}
      </div>
    </>
  );
}
