import profileImg from '@assets/images/profile.jpg';
import { RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import './Header.scss';

interface ProfileMenuProps {
  toggleProfile: boolean;
  handleToggleProfile: () => void;
  toggleLogoutDialog: () => void;
  profileMenuRef: RefObject<HTMLDivElement>;
}

const ProfileMenu = ({
  toggleProfile,
  handleToggleProfile,
  toggleLogoutDialog,
  profileMenuRef
}: ProfileMenuProps) => {
  const { t, i18n } = useTranslation();
  return (
    <div
      className={`avatar cursor-pointer`}
      ref={profileMenuRef}
      onClick={handleToggleProfile}
    >
      <img className="avatar__image" src={profileImg} alt={''} />

      <div
        className={`header__nav__menu__container header__nav__profile__container rounded shadow ${
          toggleProfile ? 'd-flex' : 'd-none'
        } flex-column`}
      >
        <NavLink
          className={(navData) =>
            `header__nav__menu__item ${navData.isActive ? 'active' : ''}`
          }
          to="/space/profil"
        >
          {t('menu.profile')}
        </NavLink>

        <NavLink
          className={(navData) =>
            `header__nav__menu__item ${navData.isActive ? 'active' : ''}`
          }
          to="/space/users"
        >
          {t('menu.userManagement')}
        </NavLink>

        <div
          className="header__nav__menu__item cursor-pointer"
          onClick={toggleLogoutDialog}
        >
          {t('menu.logout')}
        </div>
      </div>
    </div>
  );
};

export default ProfileMenu;
