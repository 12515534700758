import { TextareaHTMLAttributes } from 'react';
import uuid from 'react-uuid';

import './Textarea.scss';

interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  id?: string;
  lable?: string;
  icon?: any;
  classTextarea?: string;
  classLable?: string;
  classBox?: string;
  classIcon?: string;
  error?: {
    state: boolean;
    message: string;
  };
}

const Textarea = ({
  id = uuid(),
  classLable = '',
  classTextarea = '',
  classBox = 'mb-4',
  classIcon = '',
  lable,
  icon,
  placeholder = '',
  error = { state: false, message: '' },
  ...args
}: TextareaProps) => {
  return (
    <div className={`form-input ${classBox}`}>
      {lable && (
        <label htmlFor={id} className={`form-input__lable mb-1 ${classLable}`}>
          {lable}
        </label>
      )}
      <div className="form-input__container position-relative">
        <textarea
          id={id}
          placeholder={placeholder}
          className={`${
            icon ? 'form-input__control' : ''
          } form-control ${classTextarea} ${error.state ? 'is-invalid' : ''}`}
          {...args}
        />

        {icon && !error.state && (
          <span className={`form-input__icon ${classIcon}`}>{icon}</span>
        )}
      </div>

      {error.message && (
        <small className="form-input__error-text form-text text-danger">
          {error.message}
        </small>
      )}
    </div>
  );
};

export default Textarea;
