import Logo from '@components/Logo';
import LanguageDropdown from '@containers/Header/LanguageDropdown';
import { useTranslation } from 'react-i18next';
import './index.scss';

interface HeaderSecondaryProps {
  classNames?: string;
}

const HeaderSecondary = ({ classNames = '' }: HeaderSecondaryProps) => {
  const { t } = useTranslation();

  return (
    <header
      className={`header-secondary container pt-5 d-flex justify-content-between ${classNames}`}
    >
      <div className="header-secondary__brand d-flex align-items-center">
        <Logo style={{ width: '8rem' }} />{' '}
        <span className="fs-8 fw-300">{t('menu.aboutPortal')}</span>
      </div>
      <LanguageDropdown />
    </header>
  );
};

export default HeaderSecondary;
