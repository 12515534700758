import { BASIC } from '@constants/colors';
import { mdiDotsGrid, mdiFormatListBulletedSquare } from '@mdi/js';
import Icon from '@mdi/react';
import { Section } from '@models/Souscription';
import { Displays } from '@pages/Space/Souscription/Stepper/Dossier/types';
import { containsString } from '@utils/functions';
import { useTranslation } from 'react-i18next';
import { BsFillPlusCircleFill } from 'react-icons/bs';

interface DossierHeaderProps {
  display: Displays;
  selectedSection: Section | null;
  setDisplay: (type: Displays) => void;
  setShowAddEnterpriseDocument: (value: boolean) => void;
}

const DossierHeader = ({
  display,
  selectedSection,
  setDisplay,
  setShowAddEnterpriseDocument
}: DossierHeaderProps) => {
  const { t } = useTranslation();

  return (
    <div className="souscriptions-stepper-dossier__header d-flex align-items-center justify-content-between px-4">
      {!containsString(selectedSection?.type, 'formulaire') ? (
        <div
          onClick={() => setShowAddEnterpriseDocument(true)}
          className="cursor-pointer fw-600 d-flex align-items-center gap-2"
        >
          <BsFillPlusCircleFill size={20} color="#1a468e" />
          <span className=" pl-2">
            {t('souscriptions.stepper.button.addDocument')}
          </span>
        </div>
      ) : (
        <div></div>
      )}

      <div className="souscriptions-stepper-dossier__header__actions">
        <span className="badge bg-secondary rounded-pill d-flex align-items-center toggle-view me-2">
          <span
            className={`${
              display === 'LIST' ? 'active' : ''
            } p-1 cursor-pointer`}
            onClick={() => setDisplay('LIST')}
          >
            <Icon
              path={mdiFormatListBulletedSquare}
              size={'.9rem'}
              color={BASIC}
            />
          </span>
          <span
            className={`${
              display === 'GRID' ? 'active' : ''
            } p-1 cursor-pointer`}
            onClick={() => setDisplay('GRID')}
          >
            <Icon path={mdiDotsGrid} size={'.9rem'} color={BASIC} />
          </span>
        </span>
      </div>
    </div>
  );
};

export default DossierHeader;
