import { QUESTION_TYPES } from '@constants/question';
import { CountryType } from './Country';
import Investor from './Investor';
import { MultiSelectValuesType } from './SelectType';

export default interface Souscription {
  id: string;
  status: {
    id: string;
    label: string;
  };
  fund: {
    id: string;
    name: string;
  };
  id_investor: string;
  investor: Investor;
  id_partner: string;
  investortype: string;
  amount: number;
  shares_count: number;
  subscription_name: string;
  isOriginal: boolean;
  preonboarding_started: boolean;
  onboarding_started: boolean;
  added: string;
  added_ts: number;
  date_fullsign: string | null;
  reopened?: boolean;
  docsent: boolean;
}

export interface SouscriptionSplit {
  id_investor: string;
  amount: number;
}

export interface SouscriptionForm {
  id: string;
  name: string;
  data: {
    sections: Section[];
  };
}

export enum SectionCategory {
  BO = 'BO',
  ENTITY = 'ENTITY',
  FORM = 'FORM'
}

export interface Section {
  id: string;
  name: string;
  type: string;
  data: {
    documents?: Document[];
    questions?: Question[];
    formulaires?: Section[];
  };
  category: {
    name: string;
    code: SectionCategory;
  };
}

export interface SectionForm {
  id_section: string;
  questions: QuestionForm[];
}

export interface QuestionForm {
  id: string;
  value: string | number | CountryType[] | number[];
  currency: string;
  other_value: string;
}

export interface Document {
  id?: string;
  tip?: string;
  type?: string;
  id_section: string;
  self: boolean;
  partner_replaced: boolean;
  admin_replaced: boolean;
  certified: boolean;
  forced: boolean;
  expiration: string;
  label: string;
  templatename: string;
  templatecontent: string;
  filename?: string;
  cleanfilename?: string;
  thumbnail?: string;
  status?: number;
  added: number | string;
  updated: number | string;
  refusal_message?: string;
  user?: string;
  help?: string;
  doctype?: string;
  suggested_document?: {
    id: string;
    filetype: string;
    added: number;
    updated: number;
    filename: string;
    thumbnail: string;
  };
}

export interface Question {
  alert?: boolean;
  answer?: any;
  id?: string;
  label?: string;
  help?: string;
  status?: number;
  tip?: any;
  type: QUESTION_TYPES;
  type_label?: string;
  values?: any;
  visible?: boolean;
  id_condition?: string;
  condition_answer?: string[];
  selected_values?: MultiSelectValuesType[];
  hidden?: boolean;
  canSee?: boolean;
  suggestion?: string;
  datatype?: string;
  children?: Question[];
  mandatory?: boolean;
}

export interface DocumentForm {
  type?: string;
  id_section: string;
  id_document?: string | number;
  filename: string;
  filetype: string;
  file: string;
  name?: string;
  id_originaldoc?: string;
  user?: string;
  id?: string;
}
