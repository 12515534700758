import { AppState } from '@store';

export const getOngoinSouscriptions = (state: AppState) =>
  state.souscription.ongoing;
export const getPraparedSouscriptions = (state: AppState) =>
  state.souscription.prepared;
export const getLoadingOngoing = (state: AppState) =>
  state.souscription.loadingOngoing;
export const getStartedPrepared = (state: AppState) =>
  state.souscription.startedPrepared;
