import Souscription from '@models/Souscription';
import { SouscriptionService } from '@services';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';

interface ValidationFormModalProps {
  progress: string;
  showValidationForm?: boolean;
  setShowValidationForm: (value: boolean) => void;
  setProgress: (value: string) => void;
}

const ValidationFormModal = ({
  progress,
  showValidationForm,
  setShowValidationForm,
  setProgress
}: ValidationFormModalProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const [messageValidatedPost, setMessageValidatedPost] = useState('');

  const goToNextSouscriptionStep = async () => {
    if (progress === 'incomplete') {
      const data = await SouscriptionService.validedFolder(id || '', {
        message: messageValidatedPost
      });
      setShowValidationForm(false);

      if (data.data.status) {
        if (data.data.status === 'pending_validation') setProgress('complete');
      }
    } else {
      const data = await SouscriptionService.reload();
      const souscriptions = data.ongoing.filter((s) => s.id === id);
      const souscription: Souscription = souscriptions[0];
      if (souscription.reopened) {
        toast.error(t('toast.doKycStepAgain'));
      } else {
        if (souscription.status.id === 'sst-5') {
          navigate(`/space/souscriptions/${id}/stepper/validate`, {
            replace: true
          });
        } else {
          toast(t('toast.documentUderReview'), {
            icon: '!',
            style: {
              background: '#FFA833',
              color: '#fff'
            }
          });
        }
      }
    }
  };

  return (
    <Modal
      size="lg"
      show={showValidationForm}
      onHide={() => setShowValidationForm(false)}
      className="mt-20 modal-kyc"
    >
      <Modal.Body className="text-black py-4 modal-kyc__body">
        <div className="px-10 py-4 modal-kyc__content">
          <div className="modal-kyc__title">
            {t('souscriptions.stepper.step.kyc.validation.title')}
          </div>
          <span className="modal-kyc__space"></span>
          <form className="modal-kyc__form">
            <div className="row">
              <div className="col-md-3">
                {t('souscriptions.stepper.step.kyc.validation.label')}
              </div>
              <div className="col-md-9">
                <textarea
                  value={messageValidatedPost}
                  onChange={(e) => setMessageValidatedPost(e.target.value)}
                  className="text"
                  rows={2}
                ></textarea>
              </div>
            </div>
          </form>
          <div className="mt-3 d-flex justify-content-center align-items-center gap-2">
            <button
              className="btn btn-primary"
              onClick={goToNextSouscriptionStep}
            >
              {t('souscriptions.stepper.button.envoyer')}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ValidationFormModal;
