import { FormEvent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Icon from '@mdi/react';
import { mdiLock, mdiAccountCircleOutline } from '@mdi/js';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AuthService } from '@services';
import Input from '@components/Input';
import { EXTRA } from '@constants/colors';
import './Login.scss';

const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [userForm, setUserForm] = useState({
    email: '',
    password: ''
  });
  const [formError, setFormError] = useState({
    state: false,
    message: ''
  });
  const [redirectURL, setRedirectURL] = useState('');

  useEffect(() => {
    let url = '/space';

    if (location.state && (location.state as any)?.path) {
      url = (location.state as any)?.path;
    }

    setRedirectURL(url);
  }, []);

  const handleLogin = async (event: FormEvent) => {
    event.preventDefault();
    try {
      await AuthService.login(userForm);
      navigate('/auth/doubleauthenticate', {
        replace: true,
        state: { path: redirectURL }
      });
    } catch (error) {
      setFormError({
        state: true,
        message: ''
      });
    }
  };

  const handleFormChange = (key: string, value: string) => {
    setUserForm((prevState) => {
      return {
        ...prevState,
        [key]: value
      };
    });
  };

  return (
    <div className="login">
      <Helmet>
        <title>{t('titles.logIn')}</title>
      </Helmet>

      <div className="login__form container px-3">
        <form onSubmit={handleLogin}>
          <Input
            required
            lable={t('auth.email').toString()}
            placeholder={t('auth.email').toString()}
            icon={
              <Icon
                path={mdiAccountCircleOutline}
                size={'1.2rem'}
                color={EXTRA}
              />
            }
            classInput="bg-light text-extra border-none"
            classLable="fs-7"
            onChange={(e) => handleFormChange('email', e.target.value)}
            value={userForm.email}
            autoComplete="username"
            error={formError}
          />

          <Input
            required
            lable={t('auth.password').toString()}
            placeholder={t('auth.password').toString()}
            type="password"
            icon={<Icon path={mdiLock} size={'1.2rem'} color={EXTRA} />}
            classInput="bg-light text-extra border-none"
            classLable="fs-7"
            classBox="mb-2"
            onChange={(e) => handleFormChange('password', e.target.value)}
            value={userForm.password}
            autoComplete="current-password"
            password
            error={formError}
          />

          <div className="login__form__forgot-password-link d-flex justify-content-end">
            <Link
              to="/auth/forgotpassword"
              className="text-white text-decoration-none fs-7"
            >
              {t('auth.forgot_password')}
            </Link>
          </div>

          <div className="login__form__button d-flex justify-content-center mt-5">
            <button
              type="submit"
              className="btn btn-primary text-uppercase px-5"
            >
              {t('auth.loginBtn')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
