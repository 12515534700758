import { File } from '@/core/types/file';
import Input from '@components/Input';
import Select from '@components/Select';
import Title from '@components/Title';
import { Box } from '@containers';
import { UserForm } from '@models/User';
import { initialUser } from '@pages/Space/User/Profile/constants';
import UpdatePasswordModal from '@pages/Space/User/Profile/UpdatePasswordModal';
import { UserService } from '@services';
import { useAppSelector } from '@store';
import { getAuthUser } from '@store/auth/selectors';
import { convertBase64 } from '@utils/functions';
import { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import './Profile.scss';

const Profile = () => {
  const { t } = useTranslation();

  const [show, setShow] = useState(false);
  const [userID, setUserID] = useState<string | null>(null);
  const [editForm, setEditForm] = useState(false);
  const [user, setUser] = useState<UserForm>(initialUser);

  const inputFile = useRef<any>(null);

  const profile = useAppSelector(getAuthUser);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const isEditable = editForm && user.role === 'manager';

  const handleImageUpload = async (files: FileList | null) => {
    if (files && files.length > 0) {
      const data = await convertBase64(files[0] as unknown as File);
      setUser({
        ...user,
        identity: data.base64,
        identity_filename: data.name
      });
    }
  };

  const handleFormChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setUser({
      ...user,
      [event.target.name]: event.target.value
    });
  };

  const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (inputFile.current?.value) {
      UserService.update(user, userID || '');
    } else {
      const toastLoading = toast.loading(t('toast.loading'));
      toast.error(t('toast.addIDCard'), {
        id: toastLoading
      });

      window.setTimeout(() => {
        toast.dismiss(toastLoading);
      }, 2000);
    }
  };

  useEffect(() => {
    const loadUser = async () => {
      const data = await UserService.show(profile?.id || '');
      setUserID(data.id);
      setUser({
        ...user,
        lastname: data?.lastname,
        firstname: data?.firstname,
        email: data.email,
        telephone: data.telephone,
        role: data.role,
        identity: ''
      });
    };

    loadUser();
  }, []);

  return (
    <>
      <div className="profile">
        <Helmet>
          <title>{`${user?.lastname} ${user?.firstname}`}</title>
        </Helmet>

        <div className="profile__container container px-3">
          <Title
            title={
              t('titles.profil').toString() +
              ` ${user?.lastname} ${user?.firstname}`
            }
          />

          <Box>
            <form onSubmit={handleFormSubmit} className="profile__form p-3">
              <div className="row gx-md-5">
                <div className="col-md-6">
                  <Input
                    lable={t('profile.firstname').toString()}
                    placeholder={t('profile.firstname').toString()}
                    disabled={!editForm}
                    value={user?.lastname}
                    classInput="text-extra"
                    classLable="text-extra fw-600"
                    classBox="mb-4"
                    name="lastname"
                    onChange={handleFormChange}
                  />
                  {isEditable ? (
                    <Select
                      lable={t('profile.role').toString()}
                      placeholder={t('profile.role').toString()}
                      disabled={!editForm}
                      value={user?.role}
                      classLable="text-extra fw-600"
                      classBox="mb-4"
                      name="role"
                      onChange={handleFormChange}
                    >
                      <option value="user">{t('profile.user')}</option>
                      <option value="manager">{t('profile.admin')}</option>
                    </Select>
                  ) : (
                    <Input
                      lable={t('profile.role').toString()}
                      placeholder={t('profile.role').toString()}
                      disabled={true}
                      value={
                        user?.role === 'user'
                          ? t('profile.user').toString()
                          : t('profile.admin').toString()
                      }
                      classLable="text-extra fw-600"
                      classBox="mb-4"
                      name="firstname"
                    />
                  )}

                  <div className="form-group">
                    <label className="text-extra fw-600 mb-1">
                      {t('profile.idCard')}
                    </label>
                    <div className="profile__form__cni__container position-relative w-100">
                      <label
                        htmlFor={`${editForm ? 'profile-id-card' : ''}`}
                        className={`profile__form__cni__box ${
                          editForm ? 'upload cursor-pointer' : 'card'
                        }`}
                      >
                        {user.identity && (
                          <img src={user.identity} alt="Thumb" />
                        )}
                      </label>
                    </div>
                    <input
                      type="file"
                      id="profile-id-card"
                      className="d-none"
                      onChange={(e) => handleImageUpload(e.target.files)}
                      ref={inputFile}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <Input
                    lable={t('profile.lastname').toString()}
                    placeholder={t('profile.lastname').toString()}
                    disabled={!editForm}
                    value={user?.firstname}
                    classLable="text-extra fw-600"
                    classBox="mb-4"
                    name="firstname"
                    onChange={handleFormChange}
                  />
                  <Input
                    lable={t('profile.email').toString()}
                    placeholder={t('profile.email').toString()}
                    type="email"
                    disabled={!editForm}
                    value={user?.email}
                    classLable="text-extra fw-600"
                    classBox="mb-4"
                    name="email"
                    onChange={handleFormChange}
                  />
                  <Input
                    required
                    lable={t('profile.phone').toString()}
                    placeholder={t('profile.phone').toString()}
                    type="tel"
                    disabled={!editForm}
                    value={user?.telephone}
                    classLable="text-extra fw-600"
                    classBox="mb-4"
                    name="telephone"
                    onChange={handleFormChange}
                  />

                  {editForm && (
                    <div className="profile__form__password-box mb-4">
                      <div className="profile__form__change-password-link d-flex justify-content-end">
                        <Button
                          variant="text-extra fw-600 first-letter-upper"
                          className="mt-4"
                          onClick={handleShow}
                          id="btn-modal"
                        >
                          {t('profile.buttons._modifyPassword')}
                        </Button>
                      </div>
                    </div>
                  )}
                </div>

                <div className="col-md-12 mt-4">
                  <div className="profile__form__buttons row">
                    <div className="col-md-6">
                      {/* <Tooltip content="Ici seront transmis des complements d'informations pour vous aider à réaliser cette étape.">
                        <button
                          type="button"
                          className="btn btn-accent btn-icon"
                        >
                          <Icon path={mdiHelp} size={"1.3rem"} color={WHITE} />
                        </button>
                      </Tooltip> */}
                    </div>

                    <div className="col-md-6 d-flex justify-content-between">
                      <div></div>

                      <div className="profile__form__button__valider">
                        {editForm && (
                          <button type="submit" className="btn btn-primary">
                            {t('profile.buttons._validate')}
                          </button>
                        )}
                      </div>

                      <button
                        type="button"
                        className="btn btn-accent"
                        onClick={() => setEditForm(!editForm)}
                      >
                        {editForm
                          ? t('profile.buttons._cancel').toString()
                          : t('profile.buttons._modify')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Box>
        </div>
      </div>

      <UpdatePasswordModal
        show={show}
        handleClose={handleClose}
        user={user}
        userID={userID}
      />
    </>
  );
};

export default Profile;
