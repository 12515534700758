import { FormEvent, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Icon from '@mdi/react';
import { mdiLock } from '@mdi/js';
import { useSearchParams, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import Input from '@components/Input';
import { EXTRA } from '@constants/colors';
import { AuthService } from '@services';
import localstorage from '@utils/localstorage';
import './index.scss';

const ResetPassword = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [passwordForm, setPasswordForm] = useState({
    confirm: '',
    password: ''
  });

  const [passwordError, setPasswordError] = useState({
    state: false,
    message: ''
  });

  const [confirmPasswordError, setConfirmPasswordError] = useState({
    state: false,
    message: ''
  });

  const handelPasswordReset = async (event: FormEvent) => {
    event.preventDefault();

    if (passwordForm.confirm !== passwordForm.password) {
      setConfirmPasswordError({
        state: true,
        message: t('toast.resetValidation').toString()
      });
      return;
    }

    if (!searchParams.has('token')) {
      toast.error(t('toast.notTokenProvided'));
      return;
    }

    let id = localstorage.get('reset-user-id');

    if (!id) {
      id = searchParams.has('uid') ? `usr-${searchParams.get('uid')}` : '';
    }

    try {
      await AuthService.resetPassword(
        id,
        passwordForm.password,
        searchParams.get('token') || ''
      );
      navigate('/auth/login', { replace: true });
    } catch (error) {
      setPasswordError({
        state: true,
        message: ''
      });
    }
  };

  const handleFormChange = (key: string, value: string) => {
    setPasswordForm((prevState) => {
      return {
        ...prevState,
        [key]: value
      };
    });
  };

  return (
    <div className="forgot-password">
      <Helmet>
        <title>{t('auth.resetPassword')}</title>
      </Helmet>

      <div className="forgot-password__form container px-4">
        <h2 className="forgot-password__form__title text-center mb-4 fs-3">
          {t('auth.resetPassword')}
        </h2>

        <p className="forgot-password__form__text text-center fw-300">
          {t('auth.newPassword')}
        </p>
        <form onSubmit={handelPasswordReset}>
          <Input
            required
            type="password"
            error={passwordError}
            password
            icon={<Icon path={mdiLock} size={'1.2rem'} color={EXTRA} />}
            classInput="bg-white text-extra border-none"
            onChange={(e) => handleFormChange('password', e.target.value)}
            placeholder={t('auth.newPasswordph').toString()}
          />

          <Input
            required
            type="password"
            error={confirmPasswordError}
            password
            icon={<Icon path={mdiLock} size={'1.2rem'} color={EXTRA} />}
            classInput="bg-white text-extra border-none"
            onChange={(e) => handleFormChange('confirm', e.target.value)}
            placeholder={t('auth.confirmPassword').toString()}
            classBox="mb-2"
          />

          <div className="forgot-password__form__button d-flex justify-content-center mt-5">
            <button type="submit" className="btn btn-primary text-uppercase">
              {t('auth.changePassword')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
