export const signataireInit = {
  firstname: '',
  lastname: '',
  email: '',
  telephone: '',
  title: ''
};

export const moralLegalRepresentativeInit = {
  firstname: '',
  lastname: '',
  email: '',
  telephone: '',
  rep_name: '',
  rep_title: '',
  legal_form: '',
  identifier: '',
  country: '',
  address: ''
};

export const physiqueLegalRepresentativeInit = {
  date_birth: '',
  telephone: '',
  email: '',
  rep_name: '',
  rep_title: ''
};
