import Title from '@components/Title';
import { WHITE } from '@constants/colors';
import { Box } from '@containers';
import Investor from '@models/Investor';
import EntiteListTable from '@pages/Space/Entite/List/EntiteListTable';
import { InvestorService } from '@services';
import { useAppSelector } from '@store';
import { getInvestors } from '@store/investor/selectors';
import { getUserProfile } from '@store/user/selectors';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { BiRefresh } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import CountryNames from 'i18n-iso-countries';
import i18n from '@/i18n';
import './List.scss';

const EntiteList = () => {
  const { t } = useTranslation();
  const language = i18n.language;
  const [entites, setEntites] = useState<Investor[]>([]);
  const [refreshing, setRefreshing] = useState(false);

  const user = useAppSelector(getUserProfile);
  const allEntites = useAppSelector(getInvestors);

  const refresh = async () => {
    setRefreshing(true);
    await InvestorService.refresh();
    setRefreshing(false);
  };

  useEffect(() => {
    const arrEntites = allEntites.map((investor) => {
      return {
        ...investor,
        country:
          CountryNames.getName(investor.country, language) || investor.country
      };
    });
    setEntites(arrEntites);
  }, [allEntites]);

  return (
    <div className="entites-list">
      <Helmet>
        <title>{t('titles.entites', { investor: user?.investor?.name })}</title>
      </Helmet>

      <div className="entites-list__container container px-3">
        <Title
          className="title"
          title={t('entites.belonging', {
            investor: user?.investor?.name
          }).toString()}
        >
          <span
            onClick={refresh}
            style={{ width: 40, height: 40 }}
            className={`refresh__btn block ${refreshing ? 'load--rote' : ''}`}
          >
            <BiRefresh size={30} color={WHITE} />
          </span>
        </Title>

        <Box>
          <div className="p-1 px-2 h-100 d-flex flex-column justify-content-between">
            <div className="entite-details__data-count mb-1">
              <p className="fw-500">
                {t('entites.xFound', { x: entites?.length })}
              </p>
            </div>

            <EntiteListTable entites={entites} />

            <div className="entite-details__form__buttons d-flex justify-content-between mt-2">
              <div className="entite-details__form__buttons__left"></div>

              <div className="entite-details__form__buttons__right">
                <Link to={`/space/entites/nouveau`} className="btn btn-accent">
                  {t('entites.ajouter').toString().toUpperCase()}
                </Link>
              </div>
            </div>
          </div>
        </Box>
      </div>
    </div>
  );
};

export default EntiteList;
