export const initialUser = {
  lastname: '',
  firstname: '',
  email: '',
  telephone: '',
  identity: '',
  identity_filename: 'passport',
  role: '',
  password: ''
};
