import { BASIC } from '@constants/colors';
import KycFolderDocument from '@containers/KycFolderDocument';
import KycFolderDocumentCategoryTitle from '@containers/KycFolderDocumentCategoryTitle';
import KycFolderDocumentGrid from '@containers/KycFolderDocumentGrid';
import { mdiDotsGrid, mdiFormatListBulletedSquare } from '@mdi/js';
import Icon from '@mdi/react';
import { Document } from '@models/Souscription';
import {
  filterByStatus,
  filterBySuggested,
  handleDownloadDocument
} from '@pages/Space/Souscription/Stepper/Dossier/helpers';
import {
  Displays,
  ImportFileType,
  UBOUserType
} from '@pages/Space/Souscription/Stepper/Dossier/types';
import { useTranslation } from 'react-i18next';
import {
  BsCheckCircleFill,
  BsClockFill,
  BsFillPlusCircleFill,
  BsFillXCircleFill
} from 'react-icons/bs';
import { FaTimesCircle } from 'react-icons/fa';
import { MdDownloadForOffline } from 'react-icons/md';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

interface BODocumentTypeProps {
  display: Displays;
  uploadedDocuments: Document[];
  toUploadDocuments: Document[];
  uboUserForm: UBOUserType;
  setShowBODocumentType: (value: boolean) => void;
  setDisplay: (type: Displays) => void;
  handleSubmitDocumentType: (file: ImportFileType, doc: Document) => void;
  setShowDocumentType: (value: boolean) => void;
  setShowNewDocumentTypeNoIDCard: (value: boolean) => void;
  setShowNewDocumentTypeIDCard: (value: boolean) => void;
  handleSubmitDeleteDocument: (id: string) => void;
  handleSubmitConfirmDocument: (source: string, destination: string) => void;
  resetForms: () => void;
}

const BODocumentType = ({
  display,
  uploadedDocuments,
  toUploadDocuments,
  uboUserForm,
  setShowBODocumentType,
  setDisplay,
  handleSubmitDocumentType,
  setShowDocumentType,
  setShowNewDocumentTypeNoIDCard,
  setShowNewDocumentTypeIDCard,
  handleSubmitDeleteDocument,
  resetForms
}: BODocumentTypeProps) => {
  const { t } = useTranslation();

  const toUpload = toUploadDocuments.filter(
    ({ type }) => !uploadedDocuments.find(({ doctype }) => doctype === type)
  );

  const openBODocumentNewType = () => {
    setShowBODocumentType(false);
    setShowDocumentType(true);
    setShowNewDocumentTypeNoIDCard(false);
    setShowNewDocumentTypeIDCard(true);
  };

  const mapDocsGrid = (docs: Document[] = []) =>
    docs.map((document: Document) => (
      <KycFolderDocumentGrid
        key={document.id}
        document={document}
        onDownloadDoc={() => handleDownloadDocument(document.id || '')}
        onUploadDoc={(value: any) => handleSubmitDocumentType(value, document)}
        onDeleteDoc={() => handleSubmitDeleteDocument(document?.id || '')}
      />
    ));

  const mapDocs = (docs: Document[] = []) =>
    docs.map((document: Document) => {
      return (
        <KycFolderDocument
          key={document.id}
          document={document}
          onDeleteDoc={() => handleSubmitDeleteDocument(document?.id || '')}
          onDownloadDoc={() => handleDownloadDocument(document?.id || '')}
          onUploadDoc={(value: any) =>
            handleSubmitDocumentType(value, document)
          }
        />
      );
    });

  return (
    <div className="add-document">
      <div className="add-document__header mb-3 d-flex justify-content-between align-items-center flex-row">
        {`Documents of ${uboUserForm.firstname} ${uboUserForm.lastname}`}
        <FaTimesCircle size={20} onClick={resetForms} />
      </div>

      <div className="souscriptions-stepper-dossier__header d-flex align-items-center justify-content-between">
        <div
          onClick={openBODocumentNewType}
          className="cursor-pointer fw-600 d-flex align-items-center gap-2"
        >
          <BsFillPlusCircleFill size={20} color="#1a468e" />
          <span className=" pl-2">
            {t('souscriptions.stepper.button.addDocument')}
          </span>
        </div>

        <div className="souscriptions-stepper-dossier__header__actions">
          <span className="badge bg-secondary rounded-pill d-flex align-items-center toggle-view me-2">
            <span
              className={`${
                display === 'LIST' ? 'active' : ''
              } p-1 cursor-pointer`}
              onClick={() => setDisplay('LIST')}
            >
              <Icon
                path={mdiFormatListBulletedSquare}
                size={'.9rem'}
                color={BASIC}
              />
            </span>
            <span
              className={`${
                display === 'GRID' ? 'active' : ''
              } p-1 cursor-pointer`}
              onClick={() => setDisplay('GRID')}
            >
              <Icon path={mdiDotsGrid} size={'.9rem'} color={BASIC} />
            </span>
          </span>
        </div>
      </div>

      <SimpleBar style={{ height: '50vh' }} className="pb-4">
        {display === 'LIST' && (
          <>
            {toUpload.length > 0 && (
              <>
                <KycFolderDocumentCategoryTitle
                  title={
                    t(
                      'souscriptions.stepper.step.kyc.form.display.document.fournir'
                    ) || ''
                  }
                  icon={
                    <MdDownloadForOffline
                      size={25}
                      className="absolute-full"
                      color="#19468E88"
                    />
                  }
                />

                {toUpload.map((document, index) => {
                  return (
                    (document.suggested_document === null ||
                      !document.suggested_document) &&
                    !document.status && (
                      <KycFolderDocument
                        key={index}
                        document={document}
                        onUploadDoc={(value) => {
                          handleSubmitDocumentType(value, document);
                        }}
                        onDeleteDoc={() =>
                          handleSubmitDeleteDocument(document?.id || '')
                        }
                      />
                    )
                  );
                })}
              </>
            )}

            {filterByStatus(uploadedDocuments, 0).length > 0 && (
              <>
                <KycFolderDocumentCategoryTitle
                  title={
                    t(
                      'souscriptions.stepper.step.kyc.form.display.document.validation'
                    ) || ''
                  }
                  icon={<BsClockFill size={15} color="#8a39adbb" />}
                />
                {mapDocs(filterByStatus(uploadedDocuments, 0))}
              </>
            )}

            {filterBySuggested(uploadedDocuments).length > 0 && (
              <>
                <KycFolderDocumentCategoryTitle
                  title={
                    t(
                      'souscriptions.stepper.step.kyc.form.display.document.confirmer'
                    ) || ''
                  }
                  icon={<BsClockFill size={15} color="rgb(52, 191, 119,.8)" />}
                />

                {filterBySuggested(uploadedDocuments).map(
                  (document: Document, index: number) =>
                    (document.suggested_document || null)?.filename && (
                      <KycFolderDocument
                        confirm
                        key={index}
                        document={document}
                        onDownloadDoc={() =>
                          handleDownloadDocument(
                            document.suggested_document?.id || ''
                          )
                        }
                        onDeleteDoc={() =>
                          handleSubmitDeleteDocument(
                            document.suggested_document?.id || ''
                          )
                        }
                      />
                    )
                )}
              </>
            )}

            {filterByStatus(uploadedDocuments, -1).length > 0 && (
              <>
                <KycFolderDocumentCategoryTitle
                  title={
                    t(
                      'souscriptions.stepper.step.kyc.form.display.document.refuser'
                    ) || ''
                  }
                  icon={
                    <BsFillXCircleFill size={15} color="rgb(255, 18, 75,.6)" />
                  }
                />

                {mapDocs(filterByStatus(uploadedDocuments, -1))}
              </>
            )}

            {filterByStatus(uploadedDocuments, 1).length > 0 && (
              <>
                <KycFolderDocumentCategoryTitle
                  title={
                    t(
                      'souscriptions.stepper.step.kyc.form.display.document.valider'
                    ) || ''
                  }
                  icon={<BsCheckCircleFill size={15} color="#269999" />}
                />

                {filterByStatus(uploadedDocuments, 1).map(
                  (document: Document, index: number) => (
                    <KycFolderDocument
                      key={index}
                      document={document}
                      onUploadDoc={(value) =>
                        handleSubmitDocumentType(value, document)
                      }
                      onDownloadDoc={() =>
                        handleDownloadDocument(document?.id || '')
                      }
                    />
                  )
                )}
              </>
            )}
          </>
        )}

        {display === 'GRID' && (
          <div className="souscriptions-stepper-dossier__grid my-2">
            {mapDocsGrid(toUpload)}

            {mapDocsGrid(uploadedDocuments)}
          </div>
        )}
      </SimpleBar>
    </div>
  );
};

export default BODocumentType;
