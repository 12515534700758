import Select from '@components/Select';
import { pays } from '@data/pays';
import { useTranslation } from 'react-i18next';
import CountryNames from 'i18n-iso-countries';
import i18n from '@/i18n';
interface Step1Props {
  entite: Record<string, string>;
  showStepSerinNumber?: boolean;
  handleFormChange: (key: string, value: string) => void;
  setShowAllForm: (value: boolean) => void;
  setEntite: (entite: any) => void;
  setShowStepSerinNumber: (value: boolean) => void;
}

const Step1 = ({
  setShowAllForm,
  handleFormChange,
  setShowStepSerinNumber,
  entite,
  showStepSerinNumber
}: Step1Props) => {
  const { t } = useTranslation();
  const language = i18n.language;
  // const [loadDataSiren, setLoadDataSiren] = useState(false);

  // const validateSerinNumber = async (evt: FormEvent) => {
  //   evt.preventDefault();

  //   setLoadDataSiren(true);
  //   try {
  //     const data = await SiretService.check({
  //       siret: entite?.fiscal_identifier
  //     });

  //     let activity: any = '';
  //     let legal_form: any = '';

  //     let convert: any;

  //     if (data) {
  //       if (data.siret) {
  //         // si ce n'est pas un bon siret
  //       } else {
  //         if (typeof data === 'string') {
  //           convert = JSON.parse(data.replace('NaN', `"NaN"`));
  //         } else {
  //           convert = data;
  //         }

  //         for (const [key, value] of Object.entries(convert)) {
  //           if (key === 'EZ activity map') {
  //             if (value == null) {
  //               activity = 'null';
  //             } else {
  //               activity = value || '';
  //             }
  //           }
  //           if (key === 'Legal form') {
  //             if (value == null) {
  //               legal_form = 'null';
  //             } else {
  //               legal_form = value || '';
  //             }
  //           }
  //         }
  //       }
  //     }

  //     setShowAllForm(true);

  //     setEntite({
  //       ...entite,
  //       name: convert?.Name || '',
  //       activity: activity,
  //       address: convert.address,
  //       legal_entity_type: legal_form
  //     });
  //   } finally {
  //     setLoadDataSiren(false);
  //   }
  // };

  return (
    <form className="row step1">
      <Select
        required
        lable={t('entites.form.country').toString()}
        placeholder={t('entites.form.pickCountry').toString()}
        value={entite?.country || ''}
        classSelect="text-extra"
        classLable="text-extra fw-600 mb-2"
        classBox="mb-4 col-md-6"
        name="country"
        onChange={(e) => handleFormChange('country', e.target.value)}
      >
        {pays.map((country: any, index: number) => (
          <option key={index} value={country.alpha2Code}>
            {CountryNames.getName(country.alpha2Code, language) || ''}
          </option>
        ))}
      </Select>

      {showStepSerinNumber && (
        <>
          {/* <Input
            required
            lable={t('entites.form.siret.label').toString()}
            placeholder={t('entites.form.siret.placeholder').toString()}
            classLable="text-extra fw-600 mb-2"
            classBox="mb-4 col-md-6"
            value={entite?.fiscal_identifier || ''}
            name="fiscal_identifier"
            onChange={(e) =>
              handleFormChange('fiscal_identifier', e.target.value)
            }
          /> */}

          <div className="col-md-12 d-flex justify-content-center align-items-center gap-4 pt-4">
            {/* <button type="submit" className="btn btn-basic text-white">
              {loadDataSiren
                ? t('entites.form.siret.checking')
                : t('entites.form.siret.check')}
            </button> */}

            <button
              type="button"
              onClick={() => {
                setShowStepSerinNumber(false);
                setShowAllForm(true);
              }}
              className="btn btn-basic text-white"
            >
              {t('action.next')}
            </button>
          </div>
        </>
      )}
    </form>
  );
};

export default Step1;
