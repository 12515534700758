import MoneyFormat from '@components/MoneyFormat';
import Souscription from '@models/Souscription';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './Souscription.scss';

interface SouscriptionItemProps {
  data: Souscription;
}

interface Step {
  title: string;
  percentage: number;
  route: string;
}

const SouscriptionItem = ({ data }: SouscriptionItemProps) => {
  const { t } = useTranslation();
  const [step, setStep] = useState<Step | null>(null);

  useEffect(() => {
    let config = {
      title: '_1',
      percentage: 25,
      route: `configuration`
    };

    if (data.onboarding_started) {
      config = {
        title: '_2',
        percentage: 50,
        route: `dossiers`
      };
    }

    if (data.docsent) {
      config = {
        title: '_3',
        percentage: 75,
        route: `signature`
      };
    }

    setStep(config);
  }, [data]);

  return (
    <div className="souscription-item shadow text-extra bg-white px-4 w-100">
      <div className="row gy-3 gy-lg-0">
        <div className="col-lg-3 d-flex justify-content-center justify-content-lg-start align-items-center">
          <span className="souscription-item__title fw-600">
            {data.fund.name}
          </span>
        </div>

        <div className="col-lg-3 d-flex flex-column justify-content-center align-items-center align-items-lg-start">
          <div className="souscription-item__details">
            <span>{data.investor?.name || 'Not specified'}</span> <br />
            <span>
              <MoneyFormat data={+data.amount} />
            </span>{' '}
            <br />
            {/* <span>Signataire: {`${entite?.signer.lastname} ${entite?.signer.firstname}`}</span> */}
          </div>
        </div>

        <div className="col-lg-3 col-xl-4 d-flex flex-column justify-content-center text-center">
          <span className="text-muted mb-1 fs-10">
            {t(`souscriptions.steps.${step?.title}`)}
          </span>
          <div className="souscription-item__progress progress rounded-pill">
            <div
              className="progress-bar bg-basic rounded-pill"
              role="progressbar"
              style={{ width: `${step?.percentage}%` }}
            ></div>
          </div>
        </div>

        <div className="col-lg-3 col-xl-2 d-flex justify-content-center align-items-center">
          <Link
            to={`/space/souscriptions/${data.id}/stepper/${
              step?.route ?? 'configuration'
            }`}
            className="btn btn-sm btn-primary"
          >
            {t('souscriptions.poursuivre')}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SouscriptionItem;
