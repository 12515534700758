import { useTranslation } from 'react-i18next';
import { AiFillEye, AiOutlineFileText } from 'react-icons/ai';
import { BsPencilFill } from 'react-icons/bs';
import './index.scss';

interface KycFolderFormulaProps {
  title: string;
  needsAnswers?: boolean;
  isEditable?: boolean;
  onOpenForm?: (readonly?: boolean) => void;
}

const KycFolderFormula = ({
  title,
  needsAnswers = false,
  isEditable = false,
  onOpenForm = () => {}
}: KycFolderFormulaProps) => {
  const { t } = useTranslation();

  return (
    <div className="kyc-folder-document-item gap-5 d-flex align-items-center justify-content-between w-100 mb-3">
      <div className={`kyc-folder-document-item__title fw-500 half-w fs-7`}>
        {title}
      </div>

      {!needsAnswers ? (
        <div
          className={`kyc-folder-document-item overflow-hidden rounded ${
            !isEditable && 'justify-content-end'
          }`}
        >
          {isEditable && (
            <div
              onClick={() => onOpenForm()}
              className=" cursor-pointer kyc-folder-document-item__filename form fw-500 fs-7 d-flex align-items-center gap-2 overflow-hidden"
              title={
                t(
                  'souscriptions.stepper.step.kyc.form.display.formulaire.etat.valide.editHover',
                  { title }
                ) || ''
              }
            >
              <span>
                <BsPencilFill size={10} className="text-extra" />
              </span>
              <span className="text-truncate w-75">
                {t(
                  'souscriptions.stepper.step.kyc.form.display.formulaire.etat.valide.text'
                )}
              </span>
            </div>
          )}
          <div className="kyc-folder-document-item__actions d-flex align-items-center justify-content-between">
            <span className="others-icons d-flex gap-1">
              <span
                className="p-1 cursor-pointer"
                onClick={() => onOpenForm(true)}
                title={
                  t(
                    'souscriptions.stepper.step.kyc.form.display.formulaire.etat.valide.hover',
                    {
                      title
                    }
                  ) || ''
                }
              >
                <AiFillEye size={15} className="text-extra" />
              </span>
            </span>
          </div>
        </div>
      ) : (
        <div className="kyc-folder-document-item__button">
          <label
            title={
              t(
                'souscriptions.stepper.step.kyc.form.display.formulaire.etat.complete.hover',
                {
                  title
                }
              ) || ''
            }
            className="upload-document-btn rounded btn-outline-extra fw-500 fs-7 d-flex align-items-center gap-2"
            onClick={() => onOpenForm()}
          >
            <AiOutlineFileText size={10} />{' '}
            <span>
              {t(
                'souscriptions.stepper.step.kyc.form.display.formulaire.etat.complete.text'
              )}
            </span>
          </label>
        </div>
      )}
    </div>
  );
};

export default KycFolderFormula;
