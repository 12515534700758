/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import {
  ChangeEvent,
  DragEvent,
  InputHTMLAttributes,
  useRef,
  useState
} from 'react';
import { AiOutlineFileAdd } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';

import { checkFilesExist, convertBase64 } from '@utils/functions';
import { useDragAndDrop } from '@hooks';
import './index.scss';
import { File } from '@/core/types/file';

interface InputDocProps extends InputHTMLAttributes<HTMLInputElement> {
  onUploadDoc?: (file: any) => void;
  hasFile?: string;
}

const InputDocFormTwo = ({
  onUploadDoc = () => {},
  hasFile
}: InputDocProps) => {
  const { t } = useTranslation();

  const wrapperRef: any = useRef(null);

  const [stateDrop, setStateDrop] = useState(false);

  const updateFile = async (file: File) => {
    const data = await convertBase64(file);
    setStateDrop(true);
    const formData = new FormData();
    formData.append('file', file);

    const dataSend: any = {
      file: data,
      data: null
    };
    onUploadDoc(dataSend);
  };

  const onHandleDrop = (e: DragEvent<HTMLLabelElement>) => {
    e.preventDefault();

    setDragOver(false);

    if (checkFilesExist(e)) {
      updateFile(e?.dataTransfer?.files[0] as unknown as File);
    }
  };

  const fileSelect = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e?.target?.files;

    if (files && files.length > 0) {
      updateFile(files[0] as unknown as File);
    }

    e.target.value = '';
  };

  const { dragOver, setDragOver, onDragOver, onDragLeave } = useDragAndDrop();

  return (
    <label
      htmlFor="new-doc-origine-input"
      className={`input-doc btn-outline-extra  ${stateDrop ? 'active' : ''}`}
      ref={wrapperRef}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onHandleDrop}
    >
      {}
      <input
        type="file"
        id="new-doc-origine-input"
        hidden={true}
        onChange={fileSelect}
      />
      <span className="input-doc__icon">
        <AiOutlineFileAdd size={23} />{' '}
      </span>
      <span className="input-doc__label text-white">
        {!dragOver ? (
          <span className="text-extra">
            {hasFile
              ? hasFile
              : t(
                  'souscriptions.stepper.step.kyc.form.file.placeholder.default'
                )}
          </span>
        ) : (
          t('souscriptions.stepper.step.kyc.form.file.placeholder.hover')
        )}
      </span>
    </label>
  );
};

export default InputDocFormTwo;
