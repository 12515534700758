import { QUESTION_TYPES } from '@constants/question';
import { Box } from '@containers';
import { CountryType } from '@models/Country';
import Signataire from '@models/Signataire';
import { Question, QuestionForm, Section } from '@models/Souscription';
import ActionButtons from '@pages/Space/Souscription/Stepper/Configuration/ActionButtons';
import Step0 from '@pages/Space/Souscription/Stepper/Configuration/Step0';
import Step1 from '@pages/Space/Souscription/Stepper/Configuration/Step1';
import Step2long from '@pages/Space/Souscription/Stepper/Configuration/Step2long';
import Step2short from '@pages/Space/Souscription/Stepper/Configuration/Step2short';
import ThanksMessage from '@pages/Space/Souscription/Stepper/Configuration/ThanksMessage';
import Title from '@pages/Space/Souscription/Stepper/Configuration/Title';
import {
  montantInit,
  qesToExclude
} from '@pages/Space/Souscription/Stepper/Configuration/consts';
import { SouscriptionStepperConfigurationProps } from '@pages/Space/Souscription/Stepper/Configuration/types';
import { InvestorService, SouscriptionService } from '@services';
import countriesService from '@services/countries.service';
import { filterVisibleQuestions, mapVisibleQuestions } from '@utils/functions';
import { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import './Configuration.scss';
import { extractFirstQuestionGroup, groupQuestions } from './helpers';

const SouscriptionStepperConfiguration = ({
  updateStepProgressState,
  investor,
  setNewInvestor
}: SouscriptionStepperConfigurationProps) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const [section, setSection] = useState<Section | null>(null);
  const [step0QuestionValues, setStep0QuestionValues] = useState<
    QuestionForm[]
  >([]);
  const [step0Questions, setStep0Questions] = useState<Question[]>([]);
  const [step2QuestionValues, setStep2QuestionValues] = useState<
    QuestionForm[]
  >([]);
  const [step2Questions, setStep2Questions] = useState<Question[]>([]);
  const [step2VisibleQuestions, setStep2VisibleQuestions] = useState<
    Question[]
  >([]);
  const [investorForm, setInvestorForm] = useState<any>(null);
  const [step, setStep] = useState(0);
  const [next, setNext] = useState('');
  const [isNextActive, setIsNextActive] = useState(false);
  const [montantQuestion, setMontantQuestion] = useState<Question | null>(null);
  const [questionMontantValues, setQuestionMontantValues] =
    useState<QuestionForm>(montantInit);
  const [signers, setSigners] = useState<Signataire[]>([]);
  const [countries, setCountries] = useState<CountryType[]>([]);

  const step2QuestionGroups = groupQuestions(step2VisibleQuestions);

  const loadSection = async (): Promise<Question[]> => {
    const data = await SouscriptionService.preOnboarding(id || '');
    const sectionData = data.data.sections[0];
    const { questions } = sectionData.data;
    setSection(sectionData);
    return questions || [];
  };

  const loadCountries = async (): Promise<CountryType[]> => {
    const dataResponse: CountryType[] = await countriesService.all();
    setCountries(dataResponse);
    return dataResponse || [];
  };

  const getQuestionMontant = (questions: Question[]) => {
    const montant = (questions || []).find(({ label }) =>
      (label || '')
        .toLowerCase()
        .includes("Montant de l'engagement".toLowerCase())
    );

    if (montant) {
      const mValue = montant.answer || montant.suggestion || '0';

      setQuestionMontantValues({
        id: montant.id || '',
        value: `${parseInt(mValue)}`,
        currency: '€',
        other_value: ''
      });
      return montant;
    }

    return null;
  };

  const postData = async (): Promise<void> => {
    const questions = [
      ...step0QuestionValues,
      ...step2QuestionValues,
      questionMontantValues
    ];
    const data = {
      id_section: section?.id || '',
      questions: questions
    };

    const { signers, ...restIv } = investorForm;

    const investorData = {
      ...restIv,
      signer: {
        firstname: '',
        lastname: ''
      }
    };

    await Promise.all([
      InvestorService.update(investorData, investor.id),
      SouscriptionService.store(data, id)
    ]);

    setNewInvestor(investorData);
  };

  const postAnswers = async (withPack?: boolean): Promise<void> => {
    const questions = [
      ...step0QuestionValues,
      ...step2QuestionValues,
      questionMontantValues
    ];
    const data = {
      id_section: section?.id || '',
      questions: questions
    };

    if (withPack) {
      await SouscriptionService.store({ task: 'kycPack' }, id);
    } else {
      await SouscriptionService.store(data, id);
    }
  };

  const goToNext = async (): Promise<void> => {
    await postAnswers(true);

    navigate(next);
  };

  const goToPrevious = (): void => {
    if (step <= 0) {
      navigate('/space/souscriptions', { replace: true });
    } else {
      setStep(step - 1);
    }
  };

  useEffect(() => {
    if (step > step2QuestionGroups.length + 1) {
      updateStepProgressState('100');
      setIsNextActive(true);
      setNext(`/space/souscriptions/${id}/stepper/dossiers`);
    } else {
      updateStepProgressState('50');
      setIsNextActive(false);
      setNext('');
    }
  }, [step]);

  useEffect(() => {
    if (investor) {
      setInvestorForm({
        ...investor,
        taxable_year_end: investor.taxable_year_end || '',
        incorporation_city: investor.incorporation_city || '',
        country: investor.country.toLowerCase() || ''
      });
    }
  }, [investor]);

  const loadSigners = async (id: any) => {
    if (id) {
      const rs = await InvestorService.show(id);
      setSigners(rs.signers || []);
    }
  };

  useEffect(() => {
    loadSigners(investor.id || '');
  }, [id]);

  useEffect(() => {
    const loadData = async () => {
      const [questions] = await Promise.all([loadSection(), loadCountries()]);
      const filteredQuestions = (questions || [])
        .filter(
          (qes) =>
            !qesToExclude.includes(qes.type || -1) &&
            !(qes.label || '')
              .toLowerCase()
              .includes("Montant de l'engagement".toLowerCase())
        )
        .filter(
          (qes, index, { length }) =>
            !(
              qes.type === QUESTION_TYPES.SEPARATOR &&
              (index === 0 || index === length - 1)
            )
        );

      const [step0Questions, step2Questions] =
        extractFirstQuestionGroup(filteredQuestions);
      const step2VisibleQuestions = filterVisibleQuestions(
        mapVisibleQuestions(step2Questions)
      );

      setStep0Questions(step0Questions);
      setStep2Questions(step2Questions);
      setStep2VisibleQuestions(step2VisibleQuestions);
      setMontantQuestion(getQuestionMontant(questions));
    };

    loadData();
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>{t('souscriptions.stepper.step.configuration.title')}</title>
      </Helmet>

      <Box>
        <div className="souscriptions-stepper-configuration card rounded border-0 overflow-hidden">
          <div className="card-body" id="card-body">
            {step > step2QuestionGroups.length + 2 ? (
              <ThanksMessage />
            ) : (
              <div className="souscriptions-stepper-configuration__wrapper">
                <Title step={step} name={investorForm?.name} />

                {step === 0 && (
                  <Step0
                    step={step}
                    investorForm={investorForm}
                    setStep={setStep}
                    setInvestorForm={setInvestorForm}
                    goToPrevious={goToPrevious}
                    questions={step0Questions}
                    setQuestionValues={setStep0QuestionValues}
                    postData={postData}
                    countries={countries}
                  />
                )}

                {step === 1 && (
                  <Step1
                    step={step}
                    setStep={setStep}
                    goToPrevious={goToPrevious}
                    signers={signers}
                    setSigners={setSigners}
                    id={investor.id}
                    loadSigners={loadSigners}
                    postData={postData}
                  />
                )}

                {step > 1 && step <= step2QuestionGroups.length + 1 && (
                  <Step2long
                    step={step}
                    id={id}
                    setStep={setStep}
                    goToPrevious={goToPrevious}
                    groupedVisibleQuestions={step2QuestionGroups}
                    setAllQuestions={setStep2Questions}
                    allQuestions={step2Questions}
                    setVisibleQuestions={setStep2VisibleQuestions}
                    setQuestionsValues={setStep2QuestionValues}
                    countries={countries}
                    postData={postAnswers}
                  />
                )}

                {step === step2QuestionGroups.length + 2 && (
                  <Step2short
                    step={step}
                    setStep={setStep}
                    goToPrevious={goToPrevious}
                    name={investor?.name}
                    montantQuestion={montantQuestion}
                    questionMontantValues={questionMontantValues}
                    setQuestionMontantValues={setQuestionMontantValues}
                    postData={postAnswers}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </Box>

      {step === step2QuestionGroups.length + 3 && (
        <ActionButtons
          goToNext={goToNext}
          isNextActive={isNextActive}
          goToPrevious={goToPrevious}
        />
      )}
    </Fragment>
  );
};

export default SouscriptionStepperConfiguration;
