import fr from 'react-phone-input-2/lang/fr.json';

export const getLocalization = (lang: string) => {
  switch (lang) {
    case 'en':
      return undefined;

    case 'fr':
    default:
      return fr;
  }
};
