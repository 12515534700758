import { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate, useParams } from 'react-router-dom';
// import Icon from "@mdi/react";
// import { mdiHelp } from "@mdi/js";
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { MdOutlineFileDownload } from 'react-icons/md';
import { HiOutlineCloudDownload } from 'react-icons/hi';
import { useTranslation } from 'react-i18next';
// import { FiCheck } from "react-icons/fi";

// import Tooltip from "@components/Tooltip";
// import pdf from '@assets/images/pdf.jpg';
import pdf2 from '@assets/images/pdf2.jpg';
import { SouscriptionService } from '@services';
import Contrat from '@models/Contrat';
import { base64toBlobV2 } from '@utils/functions';
// import { BASIC, WHITE } from "@constants/colors";
import './Signature.scss';

interface SouscriptionStepperSignatureProps {
  updateStepProgressState: (value: string) => void;
}

const SouscriptionStepperSignature = ({
  updateStepProgressState
}: SouscriptionStepperSignatureProps) => {
  // const [step, setStep] = useState("view");
  const [contractDoc, setContractDoc] = useState<Contrat[]>([]);
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  // const goToNext = (): void => {
  //   setStep("complete");
  // };

  // const goToPrevious = (): void => {
  //   if (step === "complete") {
  //     setStep("view");
  //   } else {
  //     navigate(`/space/souscriptions`);
  //   }
  // };

  const downloadDoc = async (contrat: Contrat) => {
    try {
      const data = await SouscriptionService.getContrat(
        id,
        contrat.contract_id
      );
      const blob = await base64toBlobV2(data.content);
      const blobURL = window.URL.createObjectURL(blob);

      const tempLink = document.createElement('a');
      tempLink.style.display = 'none';
      tempLink.href = blobURL;
      tempLink.download = `${contrat.name}`.split('.pdf')[0] + '.pdf';
      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);
    } catch (error) {
      console.error(error);
    }
  };

  // useEffect(() => {
  //   switch (step) {
  //     case "complete":
  //       updateStepProgressState("100");
  //       break;
  //     default:
  //       updateStepProgressState("50");
  //       break;
  //   }
  // }, [step]);

  useEffect(() => {
    const getContracts = async () => {
      const data = await SouscriptionService.getContratAll(id);
      setContractDoc(data);
    };

    getContracts();
  }, [id]);

  return (
    <Fragment>
      <Helmet>
        <title> {t('signature.title').toString()}</title>
      </Helmet>

      <div className="souscriptions-stepper-signature card shadow-lg rounded border-0">
        <div className="card-body souscriptions-stepper-signature__wrapper">
          <div className="souscriptions-stepper-signature__header">
            {/* {step === "complete" && (
              <Fragment>
                <div className="souscriptions-stepper-signature__check">
                  <FiCheck size={30} color={WHITE} />
                </div>

                <div className="souscriptions-stepper-signature__title mt-3">
                  Vous avez terminé le processus de souscription. <br />
                  Vous pouvez maintenant télécharger vos documents signés <br />{" "}
                  et revenir à la page principale
                </div>
              </Fragment>
            )} */}

            {/* {step === "view" && ( */}
            <div className="souscriptions-stepper-signature__title">
              {t('signature.info._1').toString()}
              <br />
              {t('signature.info._2').toString()}
            </div>
            {/* )} */}
          </div>

          <div className="souscriptions-stepper-signature__content">
            {/* {step === "complete" && (
              <div className="souscriptions-stepper-signature__downloads">
                <span className="souscriptions-stepper-signature__downloads__link mb-4">
                  Bulletin de souscription
                  <button className="btn btn-icon btn-accent souscriptions-stepper-signature__downloads__link__icon">
                    <svg
                      width="15pt"
                      height="15pt"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <path
                        style={{ fill: "#fff" }}
                        d="M 11 2 L 11 4 L 13 4 L 13 2 Z M 11 6 L 11 14.585938 L 7.707031 11.292969 L 6.292969 12.707031 L 12 18.414063 L 17.707031 12.707031 L 16.292969 11.292969 L 13 14.585938 L 13 6 Z M 2 17 L 2 20 C 2 21.09375 2.90625 22 4 22 L 20 22 C 21.09375 22 22 21.09375 22 20 L 22 17 L 20 17 L 20 20 L 4 20 L 4 17 Z "
                      />
                    </svg>
                  </button>
                </span>
                <span className="souscriptions-stepper-signature__downloads__link">
                  FACTA & CRS
                  <button className="btn btn-icon btn-accent souscriptions-stepper-signature__downloads__link__icon">
                    <svg
                      width="15pt"
                      height="15pt"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <path
                        style={{ fill: "#fff" }}
                        d="M 11 2 L 11 4 L 13 4 L 13 2 Z M 11 6 L 11 14.585938 L 7.707031 11.292969 L 6.292969 12.707031 L 12 18.414063 L 17.707031 12.707031 L 16.292969 11.292969 L 13 14.585938 L 13 6 Z M 2 17 L 2 20 C 2 21.09375 2.90625 22 4 22 L 20 22 C 21.09375 22 22 21.09375 22 20 L 22 17 L 20 17 L 20 20 L 4 20 L 4 17 Z "
                      />
                    </svg>
                  </button>
                </span>
              </div>
            )} */}

            {/* {step === "view" && ( */}
            <div className="content-grid">
              {contractDoc.map((contrat, i) => (
                <div
                  onClick={() => downloadDoc(contrat)}
                  className="souscriptions-stepper-signature__document"
                  key={`${contrat.id}-${i}`}
                >
                  <div className="souscriptions-stepper-signature__document__title">
                    {contrat.name}
                  </div>
                  <div className="souscriptions-stepper-signature__document__preview">
                    <img src={pdf2} alt="" />
                    <div className="souscriptions-stepper-signature__document__preview__icon">
                      <button className="btn btn-icon btn-accent">
                        <HiOutlineCloudDownload />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* )} */}
          </div>
        </div>
        {/* <div className="souscriptions-stepper-signature__footer text-extra">
          {step === "view" && (
            <Fragment>
              <span className="fw-500 fs-5 me-2">
                Signer, via <strong>Docusign</strong>
              </span>
              <button
                type="button"
                onClick={() => goToNext()}
                className="btn btn-basic text-white"
              >
                <svg
                  width="14pt"
                  height="14pt"
                  viewBox="0 0 26 26"
                  version="1.1"
                  className="me-2"
                >
                  <path
                    style={{ fill: "#001f31" }}
                    d="M 6 0 C 4.34375 0 3 1.34375 3 3 L 3 23 C 3 24.65625 4.34375 26 6 26 L 20 26 C 21.65625 26 23 24.65625 23 23 L 23 17.8125 L 21 19.5625 L 21 23 C 21 23.550781 20.550781 24 20 24 L 6 24 C 5.449219 24 5 23.550781 5 23 L 5 3 C 5 2.449219 5.449219 2 6 2 L 20 2 C 20.550781 2 21 2.449219 21 3 L 21 6.4375 L 23 8.1875 L 23 3 C 23 1.34375 21.65625 0 20 0 Z M 16.5 6.46875 C 16.25 6.542969 16 6.796875 16 7.46875 L 16 10 L 10 10 C 9.449219 10 9 10.449219 9 11 L 9 15 C 9 15.550781 9.449219 16 10 16 L 16 16 L 16 18.5 C 16 19.78125 17 19.5 17 19.5 L 24.5 13 L 17 6.5 C 17 6.5 16.75 6.394531 16.5 6.46875 Z "
                  />
                </svg>
                SIGNATURE
              </button>
            </Fragment>
          )}

          {step === "complete" && (
            <Fragment>
              <Link
                to={`/space/investissements`}
                className="btn btn-accent me-2"
              >
                investissements
              </Link>
              <Link to={`/space/souscriptions`} className="btn btn-primary">
                souscriptions
              </Link>
            </Fragment>
          )}
        </div> */}
      </div>

      <div className="d-flex justify-content-end align-items-center pt-3 px-2 w-100">
        {/* <button
          type="button"
          onClick={() => goToPrevious()}
          className="btn btn-extra border-2 border-white me-2"
        >
          {t("action.back")}
        </button>
        {step === "complete" && ( */}
        <Link to={'/space/souscriptions'} className="btn btn-primary">
          {t('action.backToHome').toString()}
        </Link>
        {/* // )} */}

        {/* {step !== "complete" && (
          <button type="button" className={`btn disabled--griser`}>
            Suivant
          </button>
        )} */}
      </div>
    </Fragment>
  );
};

export default SouscriptionStepperSignature;
