import { Routes, Route, Navigate } from 'react-router-dom';

import { HeaderSecondary } from '@containers';
import { useAppSelector } from '@store';
import Login from './Login';
import './Auth.scss';
import { getAuthUser, isConnected } from '@store/auth/selectors';
import { pages } from '@pages/Auth/pages';

const Auth = () => {
  const isLogging = useAppSelector(isConnected);
  const user = useAppSelector(getAuthUser);

  if (isLogging) {
    return <Navigate to="/space" replace state={null} />;
  }

  return (
    <div className="auth">
      <HeaderSecondary classNames="auth__header" />

      <div className="auth__content">
        <Routes>
          {pages.map(({ component, path, secure }) => {
            return (
              <Route
                key={path}
                element={
                  secure && !user ? (
                    <Navigate to="/auth/login" replace />
                  ) : (
                    component
                  )
                }
                path={path}
              />
            );
          })}
          <Route path="*" element={<Login />} />
        </Routes>
      </div>
    </div>
  );
};

export default Auth;
