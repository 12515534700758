import InputNumber from '@components/InputNumber';
import { Question, QuestionForm } from '@models/Souscription';
import { useTranslation } from 'react-i18next';
import './Configuration.scss';

export interface Step2shortProps {
  step: number;
  name: string;
  montantQuestion: Question | null;
  questionMontantValues: QuestionForm;
  setStep: (step: number) => void;
  goToPrevious: () => void;
  setQuestionMontantValues: (form: QuestionForm) => void;
  postData: () => void;
}

export default function Step2short({
  step,
  name,
  montantQuestion,
  questionMontantValues,
  setStep,
  goToPrevious,
  setQuestionMontantValues,
  postData
}: Step2shortProps) {
  const { t } = useTranslation();

  const handleQuestionUpdateMontant = (value: string, id: string) => {
    const data: QuestionForm = {
      id: id,
      value: value,
      currency: '€',
      other_value: ''
    };
    setQuestionMontantValues(data);
  };

  return (
    <div className="souscriptions-stepper-configuration__step2short">
      <div className="souscriptions-stepper-configuration__title">
        {t(
          'souscriptions.stepper.step.configuration.questions.engagementPrompt',
          { investor: name }
        )}
      </div>

      <div className="souscriptions-stepper-configuration__content">
        <fieldset className="souscriptions-stepper-configuration__fieldset">
          <legend className="souscriptions-stepper-configuration__fieldset__legend">
            {t(
              'souscriptions.stepper.step.configuration.questions.engagementLabel'
            )}
          </legend>
          <InputNumber
            type="text"
            classInput="text-center"
            required
            name={montantQuestion?.id}
            placeholder={t(
              'souscriptions.stepper.step.configuration.questions.engagementLabel'
            ).toString()}
            value={questionMontantValues.value as number}
            onChangeValue={(value) =>
              handleQuestionUpdateMontant(value, montantQuestion?.id || '')
            }
          />
        </fieldset>
      </div>
      {/* { setStep(step + 1) } */}
      <div className="souscriptions-stepper-configuration__buttons d-flex justify-content-center gap-4 pt-4">
        <button
          type="button"
          onClick={goToPrevious}
          className="btn btn-extra border-2 me-2"
        >
          {t('action.back')}
        </button>

        <button
          type="button"
          onClick={() => {
            setStep(step + 1);
            postData();
          }}
          className="btn btn-basic text-white"
        >
          {t('action.validate')}
        </button>
      </div>
    </div>
  );
}
