import { Helmet } from 'react-helmet-async';
import { Link, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { HeaderSecondary } from '@containers';
import { useAppSelector } from '@store';
import './Home.scss';
import { isConnected } from '@store/auth/selectors';

const Home = () => {
  const { t } = useTranslation();

  const isLogging = useAppSelector(isConnected);

  if (isLogging) {
    return <Navigate to="/space" replace state={null} />;
  }

  return (
    <div className="home">
      <Helmet>
        <title>{t('titles.welcome_message')}</title>
      </Helmet>

      <HeaderSecondary classNames="home__header" />

      <div className="home__content">
        <div className="d-flex flex-column flex-wrap align-items-center">
          <h1 className="home__content__title text-center lh-base">
            {t('app.welcome_message._1')} <br /> {t('app.welcome_message._2')}
          </h1>
          <div className="home__content__buttons d-flex flex-column align-items-center flex-wrap pt-5">
            <Link
              to="/auth/login"
              className="home__content__buttons__connexion btn btn-primary text-uppercase"
            >
              {t('app.welcome_message._connexion')}
            </Link>
            {/* <button
              type="button"
              className="home__content__buttons__register btn btn-accent text-white text-uppercase mt-5"
            >
              {t('app.welcome_message._accès')}
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
