import { Buffer } from 'buffer';
import toast from 'react-hot-toast';

import {
  QUESTION_STATUS,
  QUESTION_TYPES,
  noAnswerQuestionTypes
} from '@constants/question';
import { CountryType } from '@models/Country';
import { MultiSelectValuesType } from '@models/SelectType';
import { Document, Question, Section } from '@models/Souscription';
import i18next from '@/i18n';
import { File } from '@/core/types/file';
import { DragEvent } from 'react';

const signatures = [
  {
    extract: 'JVBERi0',
    type: 'application/pdf'
  },
  {
    extract: '/9j/',
    type: 'image/jpg'
  },
  {
    extract: 'R0lGODdh',
    type: 'image/gif'
  },
  {
    extract: 'R0lGODlh',
    type: 'image/gif'
  },
  {
    extract: 'iVBORw0KGgo',
    type: 'image/png'
  }
];

const detectMimeType = (base64: string) => {
  return (
    signatures.find((s) => base64.includes(s.extract))?.type ||
    'application/octet-stream'
  );
};

export const base64toBlob = (data: string, type: string) => {
  const base64WithoutPrefix = data.substring(
    `data:${type};base64,`.length,
    data.length
  );

  const bytes = atob(base64WithoutPrefix);
  let length = bytes.length;
  const out = new Uint8Array(length);

  while (length--) {
    out[length] = bytes.charCodeAt(length);
  }

  return new Blob([out], { type });
};

export const base64toBlobV2 = async (data: string) => {
  const bytes = atob(data);
  let length = bytes.length;
  const out = new Uint8Array(length);

  while (length--) {
    out[length] = bytes.charCodeAt(length);
  }

  return new Blob([out], { type: detectMimeType(data) });
};

export const convertBase64 = async (file: File) => {
  const { type, name } = file;
  const content = await new Blob([file as unknown as Blob], {
    type
  }).arrayBuffer();
  const base64 = `data:${type};base64,${Buffer.from(content).toString(
    'base64'
  )}`;
  return {
    type,
    base64,
    name
  } as File;
};

export const checkFileSize = (file: File, maxSize: number) => {
  const { size } = file;
  return size <= maxSize;
};

export const checkFileType = (file: File, allowedFileTypes: string[]) => {
  const { type } = file;
  return allowedFileTypes.includes(type);
};

export const validateUploadedFile = (uploadedFile: File) => {
  const validTypes = [
    'image/png',
    'image/jpeg',
    'image/jpg',
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword',
    'text/csv'
  ];

  const isTypeValid = checkFileType(uploadedFile, validTypes);
  if (!isTypeValid) {
    toast.error(i18next.t('toast.fileFormatIsInvalid'));
    return false;
  } else {
    const maxFileSize = 30 * 1024 * 1024;
    const isSizeValid = checkFileSize(uploadedFile, maxFileSize);
    if (!isSizeValid) {
      toast.error(i18next.t('toast.fileZizeShouldNotExceed'));
      return false;
    }
  }
  return true;
};

export const isDossierComplete = (sections: Section[]) => {
  let result = true;

  if ((sections || []).length < 1) {
    return false;
  }

  for (const section of sections) {
    if (!isDocumentsComplete(section.data.documents || [])) {
      result = false;
      break;
    }

    if (!isAllFormulaComplete(section || [])) {
      result = false;
      break;
    }
  }

  return result;
};

export const transformQuestionValues = (
  question: Question,
  countries: CountryType[] = []
) => {
  if (question) {
    const data: any = {
      id: question.id,
      value: '',
      currency: '',
      other_value: ''
    };

    if (question.type) {
      switch (true) {
        case question.type === QUESTION_TYPES.CURRENCY:
          data.value = `${parseInt(question.answer || '0')}`;
          data.currency = '';
          break;

        case question.type === QUESTION_TYPES.CHECKBOX:
          data.value =
            typeof question.answer === 'string'
              ? (question.answer || 'non').toLowerCase() === 'oui'
              : !!question.answer;
          break;

        case question.type === QUESTION_TYPES.MULTI_SELECT: {
          if (question.answer) {
            if (Array.isArray(question.answer)) {
              data.value = question.answer.map((ans) => ans.value);
            } else {
              data.value = (question.answer || '').split(' | ');
            }
          }

          break;
        }

        case question.type === QUESTION_TYPES.COUNTRY_SELECT: {
          if (question.answer) {
            const country = countries.find(
              (country) =>
                country.name === question.answer ||
                country.id === +question.answer
            );

            data.value = country?.id || '';
          }

          break;
        }

        case question.type === QUESTION_TYPES.MULTI_CHECKBOX:
          if (question.answer) {
            const values = (question.answer || '').split(' | ');

            let refractAnswer: any[] = [];

            if (values.length > 1) {
              refractAnswer = values.map((value: any) => ({
                label: value,
                value: value
              }));

              data.selected_values = refractAnswer;

              data.value = values;
            }
          }
          break;

        case question.type === QUESTION_TYPES.COUNTRY_MULTI_SELECT: {
          if (question.answer) {
            if (Array.isArray(question.answer)) {
              data.value = question.answer.map((ans) => ans.value);
            } else {
              data.value = (question.answer || '')
                .split(' | ')
                .map((countryName: string) => {
                  const ctr = countries.find(
                    (country) =>
                      country.name === countryName ||
                      country.id === +countryName
                  );
                  return ctr?.id || '';
                });
            }
          }

          break;
        }

        default: {
          data.value = question.answer;
          break;
        }
      }
    }

    return data;
  }
};

export const canShowQuestion = (
  id_condition: string | null,
  condition_answer: any,
  questions: Question[],
  isVisible?: boolean
) => {
  if (!id_condition && !condition_answer) return true;

  const dependentQuestion = questions.find(
    (question) => question.id === id_condition
  );

  if (!dependentQuestion) return isVisible;

  // if (dependentQuestion.id === "qst-1130") console.log(dependentQuestion);

  switch (true) {
    case [
      QUESTION_TYPES.MULTI_CHECKBOX,
      QUESTION_TYPES.MULTI_SELECT,
      QUESTION_TYPES.COUNTRY_MULTI_SELECT
    ].includes(dependentQuestion.type || -1): {
      return (dependentQuestion.answer || '')
        .split(' | ')
        .every((ans: any) => (condition_answer || '').includes(ans));
    }

    case dependentQuestion?.type === QUESTION_TYPES.CHECKBOX: // Question OUI / NON
      return condition_answer === dependentQuestion?.answer;

    default: {
      return (condition_answer || []).includes(dependentQuestion.answer || '');
    }
  }
};

const questionsToHide = [QUESTION_TYPES.COMPOSE, QUESTION_TYPES.ADMIN];

export const filterVisibleQuestions = (questions: Question[] = []) =>
  questions.filter((qes) => qes.canSee && !questionsToHide.includes(qes.type));

export const mapVisibleQuestions = (questions: Question[] = []) =>
  questions.map((qes) => ({
    ...qes,
    canSee: qes.visible
  }));

export const hasValueInQuestion = (values: any[], value: string) => {
  if (values.length < 1) return false;
  return values.includes(value);
};

export const getMultitipleValues = (values: any[]): MultiSelectValuesType[] => {
  return values.map((value) => ({
    label: value,
    value: value
  }));
};

export const isFormulaStatus = (formula: Section) => {
  if (
    !formula ||
    !formula.type.includes('question') ||
    (formula.data.questions || []).length < 1
  ) {
    return 'TO_COMPLETE';
  }

  let questions = formula.data?.questions?.map((qes) => {
    return {
      ...qes,
      canSee: canShowQuestion(
        qes.id_condition || null,
        qes.condition_answer || null,
        formula.data?.questions || [],
        qes.visible
      )
    };
  });

  questions = questions?.filter((question) => question.canSee) || [];

  if (
    questions?.every(
      ({ type, status }) =>
        noAnswerQuestionTypes.includes(type) ||
        status === QUESTION_STATUS.VALIDATED
    )
  ) {
    return 'VALIDATED';
  } else if (
    questions?.some(({ status }) => status === QUESTION_STATUS.REJECTED)
  ) {
    return 'REJECTED';
  } else if (!questions?.some(({ answer }) => !!answer?.replace(/\s/g, ''))) {
    // sometimes an answer can be a string with the space right after creating a form
    return 'TO_COMPLETE';
  } else {
    return 'PENDING';
  }
};

export const isDocumentsComplete = (docs: Document[]) => {
  let result = true;

  if (docs.length < 1) {
    return false;
  }

  result = docs?.every((doc) => doc.status === 1) || false;

  return result;
};

export const isAllFormulaComplete = (docs: Section) => {
  let result = true;

  if (!docs) {
    return false;
  }

  if (!docs.type.includes('document')) {
    return false;
  }

  if ((docs.data.formulaires || []).length < 1) {
    return false;
  }

  result =
    docs.data.formulaires?.every(
      (form) => isFormulaStatus(form) === 'VALIDATED'
    ) || false;

  return result;
};

export const containsString = (checkString = '', includingString = '') =>
  checkString?.toLowerCase().includes(includingString);

export const checkFilesExist = (e: DragEvent<HTMLLabelElement>) =>
  e?.dataTransfer?.files && e?.dataTransfer?.files.length > 0;

export const formatTableQuestions = (questions: Question[] = []) => {
  let isTable = false;
  const questionsWithTables = [...questions];

  for (let i = 0; i < questionsWithTables.length - 1; i++) {
    if (questionsWithTables[i].type === QUESTION_TYPES.TABLE_START) {
      isTable = true;
      questionsWithTables[i].children = [];
    } else if (
      isTable &&
      questionsWithTables[i].type !== QUESTION_TYPES.TABLE_END
    ) {
      questionsWithTables[i - 1]?.children?.push(questionsWithTables[i]);
      questionsWithTables.splice(i, 1);
      i--;
    } else if (
      isTable &&
      questionsWithTables[i].type === QUESTION_TYPES.TABLE_END
    ) {
      isTable = false;
    }
  }

  return questionsWithTables;
};
