import DateFormat from '@components/DateFormat';
import { EXTRA, WHITE } from '@constants/colors';
import { defaultDateFormat } from '@constants/format';
import { mdiEye, mdiPencil, mdiTrashCan } from '@mdi/js';
import Icon from '@mdi/react';
import { Document } from '@models/Souscription';
import { DragEvent, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineCloudUpload } from 'react-icons/hi';
import { statusColor } from './constants';

interface DocumentItemGridProps {
  variant: string;
  document: Document;
  onDownloadDoc?: () => void;
  dragOver?: boolean;
  onDragOver?: (e: SyntheticEvent) => void;
  onDragLeave?: () => void;
  onHandleDrop?: (e: DragEvent<HTMLLabelElement>) => void;
}

const DocumentItemGrid = ({
  variant,
  document,
  onDownloadDoc,
  dragOver,
  onDragOver,
  onDragLeave,
  onHandleDrop
}: DocumentItemGridProps) => {
  const { t } = useTranslation();
  const isValider = variant === 'valider';
  const isRefuser = variant === 'refuser';
  const isAttente = variant === 'attente';

  return (
    <div className="souscriptions-stepper-dossier__item">
      {variant === 'telecharger' ? (
        <>
          <label
            htmlFor={`file-input-grid-${document.type}-status-${document.status}-id-${document.id}`}
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onDrop={onHandleDrop}
            className={`souscriptions-stepper-dossier__item__content is-dash`}
          >
            <div
              className="souscriptions-stepper-dossier__item__badge"
              style={{ backgroundColor: statusColor.telecharger }}
            >
              {t(
                'souscriptions.stepper.step.kyc.form.display.document.etat.telecharger'
              )}
            </div>

            <HiOutlineCloudUpload size={15} color={EXTRA} />

            {!dragOver
              ? t(
                  'souscriptions.stepper.step.kyc.form.file.placeholder.default'
                )
              : t('souscriptions.stepper.step.kyc.form.file.placeholder.hover')}
          </label>
          <div className="souscriptions-stepper-dossier__item__title">
            {document.label}
          </div>
        </>
      ) : (
        <>
          <div
            className={`souscriptions-stepper-dossier__item__content`}
            style={isRefuser ? { borderColor: statusColor.refuser } : {}}
          >
            <div className="souscriptions-stepper-dossier__item__maj maj-show ">
              <div className="d-flex align-items-center gap-3 mb-2">
                {(isValider || isRefuser) && (
                  <label
                    htmlFor={`file-input-grid-${document.type}-status-${document.status}-id-${document.id}`}
                    className="cursor-pointer"
                  >
                    <Icon path={mdiPencil} size={'1.5rem'} color={WHITE} />
                  </label>
                )}
                <span onClick={onDownloadDoc} className="cursor-pointer">
                  <Icon path={mdiEye} size={'1.5rem'} color={WHITE} />
                </span>
                {isAttente && (
                  <span onClick={onDownloadDoc} className="cursor-pointer">
                    <Icon path={mdiTrashCan} size={'1.5rem'} color={WHITE} />
                  </span>
                )}
              </div>
              {isRefuser ? (
                <p className="mb-1">{document.refusal_message}</p>
              ) : (
                // eslint-disable-next-line prettier/prettier
                <>{t('souscriptions.stepper.step.kyc.form.file.lastUpdate')} </>
              )}
              <DateFormat
                unix
                format={defaultDateFormat}
                date={document.updated}
              />
            </div>
            <div
              className="souscriptions-stepper-dossier__item__badge"
              style={{ backgroundColor: `statusColor.[variant]` }}
            >
              {t(
                'souscriptions.stepper.step.kyc.form.display.document.etat.[variant]'
              )}
            </div>
            <div className="souscriptions-stepper-dossier__item__thumb">
              {document.thumbnail && (
                <img src={document.thumbnail} alt={document.label} />
              )}
            </div>
          </div>
          <div className="souscriptions-stepper-dossier__item__title">
            {document.label}
          </div>
        </>
      )}
    </div>
  );
};

export default DocumentItemGrid;
