import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Icon from '@mdi/react';
import { useTranslation } from 'react-i18next';
import { mdiPlusCircleOutline } from '@mdi/js';

import Title from '@components/Title';
import { useAppSelector } from '@store';
import Investor from '@models/Investor';
import { InvestorService, SouscriptionService } from '@services';
import Souscription, { SouscriptionSplit } from '@models/Souscription';
import { Box } from '@containers';
import { PRIMARY } from '@constants/colors';
import './Initial.scss';
import { getInitialInvestors } from '@store/investor/selectors';
import { setPreparedStarted } from '@store/souscription/reducer';
import { useDispatch } from 'react-redux';

const SouscriptionInitial = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [selectedEntities, setSelectedEntities] = useState<SouscriptionSplit[]>(
    []
  );
  const [originSubscription, setOriginSubscription] =
    useState<Souscription | null>(null);

  const entites = useAppSelector(getInitialInvestors);

  useEffect(() => {
    const loadSubscription = async () => {
      try {
        InvestorService.reload();
        const data = await SouscriptionService.prepared(id || '');
        setOriginSubscription(data);
      } catch (error) {
        console.error(error);
      }
    };

    loadSubscription();
  }, []);

  const addEntity = (entite: Investor): void => {
    const data: SouscriptionSplit = {
      id_investor: entite.id || '',
      amount: originSubscription?.amount || 0
    };

    if (toggleActive(entite)) {
      setSelectedEntities(
        selectedEntities.filter((elt) => elt.id_investor !== entite.id)
      );
    } else {
      setSelectedEntities([...selectedEntities, data]);
    }
  };

  const toggleActive = (entite: Investor): boolean => {
    return selectedEntities.some((elem) => elem.id_investor === entite.id);
  };

  const saveStartedSouscription = () => {
    const startedSouscription = `/space/souscriptions/${id}/initialisation`;
    dispatch(setPreparedStarted(startedSouscription));
  };

  const isNextActive = (): boolean => {
    return selectedEntities.length > 0;
  };

  const goToNext = async (): Promise<void> => {
    const data = await SouscriptionService.initialize(
      selectedEntities,
      id || ''
    );
    if (data.length <= 1) {
      navigate(`/space/souscriptions/${data[0]}/stepper/configuration`, {
        replace: true
      });
    } else if (selectedEntities.length > 1) {
      navigate('/space/souscriptions', { replace: true });
    }
  };

  return (
    <div className="souscriptions-initial">
      <Helmet>
        <title>
          {t('souscriptions.toInitiate._1')}
          {` ${originSubscription?.fund.name}`}
        </title>
      </Helmet>
      <div className="souscriptions-initial__content px-3">
        <Title
          title={
            t('souscriptions.toInitiate._1').toString() +
            ` ${originSubscription?.fund.name}`
          }
        />

        <Box>
          <div className="  rounded">
            <div className=" souscriptions-initial__box">
              <div className="souscriptions-initial__title">
                {t('souscriptions.toInitiate._2')}{' '}
                {originSubscription?.fund.name}
              </div>
              <div className="souscriptions-initial__items">
                <div className="souscriptions-initial__items-grid">
                  {entites.map((entite, index) => (
                    <div className={`souscriptions-initial__item`} key={index}>
                      <input hidden type="checkbox" id={`box-${index}`} />
                      <label
                        htmlFor={`box-${index}`}
                        onClick={() => addEntity(entite)}
                        className={`souscriptions-initial__item__border text-truncate ${
                          toggleActive(entite) ? 'active' : ''
                        }`}
                      >
                        <span className="square"></span>
                        <span>{entite.name}</span>
                      </label>
                    </div>
                  ))}

                  <Link
                    style={{ textTransform: 'none', textDecoration: 'none' }}
                    to={`/space/entites/nouveau`}
                    className={`souscriptions-initial__item__btn btn-block`}
                    onClick={saveStartedSouscription}
                  >
                    <Icon
                      path={mdiPlusCircleOutline}
                      size={'1.3rem'}
                      color={PRIMARY}
                    />
                    <span
                      className="ms-2 fs-6 fw-600"
                      style={{
                        textTransform: 'none',
                        textDecoration: 'none',
                        color: 'black'
                      }}
                    >
                      {t('souscriptions.toInitiate._3')}
                    </span>
                  </Link>
                </div>
              </div>
              <div className="souscriptions-initial__buttons">
                <div className="entite-details__form__buttons d-flex justify-content-between mt-5">
                  <div className="entite-details__form__buttons__left"></div>

                  {/* <div className="entite-details__form__buttons__right">
                                        <Link to={`/space/entites`} className="btn text-extra d-flex align-items-center">
                                            <Icon path={mdiPlusCircleOutline} size={'1.3rem'} color={PRIMARY} />
                                            <span className="ms-2 fs-6 fw-600" style={{ textTransform: 'none' }}>Ajouter une entité juridique</span>
                                        </Link>
                                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </Box>

        <div className="d-flex justify-content-end align-items-center py-3 px-2">
          <Link
            to={`/space/souscriptions`}
            className="btn btn-extra border-2 border-white me-2"
          >
            {t('souscriptions.buttons._retour')}
          </Link>
          <button
            type="button"
            onClick={goToNext}
            className={`btn  ${
              isNextActive() ? 'btn-primary ' : 'disabled--griser'
            }`}
          >
            {t('souscriptions.buttons._suivant')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SouscriptionInitial;
