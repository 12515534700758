import { BrowserRouter, Routes, Route } from 'react-router-dom';

import PageNotFound from '@pages/PageNotFound';
import Home from '@pages/Home';
import Auth from '@pages/Auth';
import Space from '@pages/Space';
import ScrollToTop from './ScrollToTop';
import RouteGuard from './RouteGuard';

const pages: any[] = [
  { path: '/', component: <Home />, secure: false },
  { path: '/auth/*', component: <Auth />, secure: false },
  { path: '/space/*', component: <Space />, secure: true }
];

const AppRouting = () => {
  return (
    <BrowserRouter basename="/">
      <ScrollToTop />
      <Routes>
        {pages.map(({ component, path, secure }) => {
          return (
            <Route
              key={path}
              element={
                secure ? <RouteGuard component={component} /> : component
              }
              path={path}
            />
          );
        })}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouting;
