import Title from '@components/Title';
import { Box } from '@containers';
import Signataire from '@models/Signataire';
import { maxDate } from '@pages/Space/constants';
import { signataireInit } from '@pages/Space/Entite/Form/constants';
import EditStep1 from '@pages/Space/Entite/Form/EditStep1';
import EditStep2 from '@pages/Space/Entite/Form/EditStep2';
import FormHeader from '@pages/Space/Entite/Form/FormHeader';
import Step1 from '@pages/Space/Entite/Form/Step1';
import Step1ShowAll from '@pages/Space/Entite/Form/Step1ShowAll';
import Step2 from '@pages/Space/Entite/Form/Step2';
import { SignataireForType } from '@pages/Space/Entite/Form/types';
import { InvestorService, SignataireService } from '@services';
import { useAppSelector } from '@store/index';
import { setPreparedStarted } from '@store/souscription/reducer';
import { getStartedPrepared } from '@store/souscription/selectors';
import '@utils/api-http-client';
import { FormEvent, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import 'react-phone-input-2/lib/style.css';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import './Form.scss';

const EntiteForm = () => {
  const { t } = useTranslation();
  const [editForm, setEditForm] = useState(false);
  const [showAllForm, setShowAllForm] = useState(false);
  const [step, setStep] = useState(1);
  const [showStepSerinNumber, setShowStepSerinNumber] = useState(false);
  const [entite, setEntite] = useState<any>(null);
  const [signers, setSigners] = useState<Signataire[]>([]);
  const [addSigner, setAddSigner] = useState(false);
  const [signer, setSigner] = useState<SignataireForType>(signataireInit);
  const [newSigners, setNewSigners] = useState<SignataireForType[]>([]);

  const newSignerFormRef = useRef<HTMLFormElement>(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const startedSouscription = useAppSelector(getStartedPrepared);
  const linkAfterSubmission = startedSouscription || '/space/entites';
  const clearStartedSubscription = () => {
    dispatch(setPreparedStarted(''));
  };

  const LoadData = async () => {
    setEditForm(true);
    const rs = await InvestorService.show(id || '');
    setEntite(rs);
    // if rs.taxable_year_end is null, set it to maxDate
    setEntite({
      ...rs,
      taxable_year_end: rs.taxable_year_end || maxDate,
      incorporation_city: rs.incorporation_city || ''
    });
    setSigners(rs.signers || []);
  };

  const LoadSigners = async () => {
    const rs = await InvestorService.show(id || '');
    setSigners(rs.signers || []);
  };

  useEffect(() => {
    if (id) {
      LoadData();
    } else {
      setEntite({
        ...entite,
        taxable_year_end: maxDate
      });
    }
  }, [id]);

  const handleFormChange = (key: string, value: string) => {
    setEntite({
      ...entite,
      [key]: value
    });

    setSigners(entite?.signers || []);

    if (value === 'FR' && !showAllForm) {
      setShowStepSerinNumber(true);
      setShowAllForm(false);
    } else if (key !== 'fiscal_identifier') {
      setShowStepSerinNumber(false);
      setShowAllForm(true);
    }
  };

  const handleFormSigneChange = (key: string, value: string) => {
    setSigner({
      ...signer,
      [key]: value
    });
  };

  const handleFormSubmit = async (evt: FormEvent) => {
    evt.preventDefault();

    const investor: any = {
      ...entite,
      signer: {
        lastname: '',
        firstname: ''
      }
    };

    if (editForm) {
      await InvestorService.update(investor, id || '');
      setStep(step + 1);
    } else {
      const rs = await InvestorService.store(investor);
      await SignataireService.storeMultiple(
        newSigners,
        rs.data?.id_investor || ''
      );
      navigate(linkAfterSubmission, { replace: true });
      clearStartedSubscription();
    }
  };

  return (
    <div className={`entite-form ${showAllForm ? 'entite-form--pt-sm' : ''}`}>
      <Helmet>
        <title>
          {editForm
            ? `${t('titles.entitesEdit', {
                entityName: entite?.name
              }).toString()}`
            : `${t('titles.entitesAdd', { step: step }).toString()}`}
        </title>
      </Helmet>

      <div className="entite-form__container container px-3">
        <Title
          title={
            editForm
              ? `${t('titles.entitesEdit', {
                  entityName: entite?.name
                }).toString()}`
              : `${t('titles.entitesAdd', { step: step }).toString()}`
          }
        />

        {editForm ? (
          <Box>
            <div className="entite-form__form">
              <FormHeader
                step={step}
                name={entite?.name}
                clearStartedSubscription={clearStartedSubscription}
                isEdit={editForm}
              />

              <div className="content">
                {step === 1 && (
                  <EditStep1
                    entite={entite}
                    handleFormSubmit={handleFormSubmit}
                    handleFormChange={handleFormChange}
                  />
                )}

                {step === 2 && (
                  <EditStep2
                    addSigner={addSigner}
                    newSignerFormRef={newSignerFormRef}
                    signer={signer}
                    signers={signers}
                    step={step}
                    handleFormSubmit={handleFormSubmit}
                    handleFormSigneChange={handleFormSigneChange}
                    setAddSigner={setAddSigner}
                    LoadSigners={LoadSigners}
                    setSigner={setSigner}
                    setSigners={setSigners}
                    setStep={setStep}
                  />
                )}
              </div>
            </div>
          </Box>
        ) : (
          <Box>
            <div className="entite-form__form">
              <FormHeader
                step={step}
                name={entite?.name}
                clearStartedSubscription={clearStartedSubscription}
              />

              <div className="content">
                {step === 1 && (
                  <>
                    {!showAllForm && (
                      <Step1
                        entite={entite}
                        showStepSerinNumber={showStepSerinNumber}
                        handleFormChange={handleFormChange}
                        setShowAllForm={setShowAllForm}
                        setEntite={setEntite}
                        setShowStepSerinNumber={setShowStepSerinNumber}
                      />
                    )}

                    {showAllForm && (
                      <Step1ShowAll
                        step={step}
                        entite={entite}
                        handleFormChange={handleFormChange}
                        setStep={setStep}
                      />
                    )}
                  </>
                )}

                {step === 2 && (
                  <Step2
                    handleFormSubmit={handleFormSubmit}
                    setNewSigners={setNewSigners}
                    newSigners={newSigners}
                    newSignerFormRef={newSignerFormRef}
                    signer={signer}
                    addSigner={addSigner}
                    step={step}
                    setAddSigner={setAddSigner}
                    setSigner={setSigner}
                    handleFormSigneChange={handleFormSigneChange}
                    setStep={setStep}
                  />
                )}
              </div>
            </div>
          </Box>
        )}
      </div>
    </div>
  );
};

export default EntiteForm;
