import { File } from '@/core/types/file';
import Tooltip from '@components/Tooltip';
import useDragAndDrop from '@hooks/useDragAndDrop';
import { Document } from '@models/Souscription';
import {
  base64toBlobV2,
  checkFilesExist,
  convertBase64,
  validateUploadedFile
} from '@utils/functions';
import { ChangeEvent, DragEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { BsInfoCircleFill } from 'react-icons/bs';
import { TbCloudUpload } from 'react-icons/tb';
import DocumentItem from './DocumentItem';
import './index.scss';

interface KycFolderDocumentProps {
  document: Document;
  onUploadDoc?: (file: File) => void;
  onDownloadDoc?: () => void;
  onDeleteDoc?: () => void;
  confirm?: boolean;
}

const KycFolderDocument = ({
  document,
  onUploadDoc = (file) => {},
  onDownloadDoc = () => {},
  onDeleteDoc = () => {},
  confirm = false
}: KycFolderDocumentProps) => {
  const { t } = useTranslation();

  const onHandleDrop = async (e: DragEvent<HTMLLabelElement>) => {
    e.preventDefault();

    setDragOver(false);

    if (checkFilesExist(e)) {
      const uploadedFile = e?.dataTransfer?.files[0] as unknown as File;
      const isFileValid = validateUploadedFile(uploadedFile);
      if (isFileValid) {
        const data = await convertBase64(uploadedFile);
        onUploadDoc(data);
      }
    }
  };

  const fileSelect = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files && e?.target?.files.length > 0) {
      const uploadedFile = e?.target?.files[0] as unknown as File;
      const isFileValid = validateUploadedFile(uploadedFile);

      if (isFileValid) {
        const data = await convertBase64(uploadedFile);
        onUploadDoc(data);
      }

      e.target.value = '';
    }
  };

  const dowloadTemplate = async (e: any, doc: any) => {
    e.preventDefault();
    try {
      const blob = await base64toBlobV2(doc.templatecontent);
      const blobURL = window.URL.createObjectURL(blob);

      // const tempLink = window.document.createElement("a");
      // tempLink.style.display = "none";
      // tempLink.href = blobURL;
      // tempLink.setAttribute("download", doc.templatename);

      // if (typeof tempLink.download === "undefined") {
      //   tempLink.setAttribute("target", "_blank");
      // }

      // window.document.body.appendChild(tempLink);
      // tempLink.click();
      // window.document.body.removeChild(tempLink);

      window.open(blobURL, '_blank');

      setTimeout(() => {
        window.URL.revokeObjectURL(blobURL);
      }, 100);
    } catch (error) {
      console.error(error);
    }
  };

  const { dragOver, setDragOver, onDragOver, onDragLeave } = useDragAndDrop();

  return (
    <div className="kyc-folder-document-item gap-5 d-flex align-items-center justify-content-between w-100 mb-3">
      <input
        type="file"
        className="d-none"
        id={`file-input-${document.type}-status-${document.status}-id-${document.id}`}
        onChange={fileSelect}
      />

      <div
        className={`kyc-folder-document-item__title fw-500 fs-7 half-w d-flex flex-column`}
      >
        <div className="d-flex">
          {document.label}
          {document.help && (
            <Tooltip content={document.help} className="ms-2">
              <BsInfoCircleFill size={16} />
            </Tooltip>
          )}
          {document.templatecontent && (
            <small
              title="Télécharger le modèle de ce document"
              onClick={(e) => dowloadTemplate(e, document)}
              className="ms-2 text-decoration-underline fw-600 text-extra cursor-pointer"
            >
              <em>Template</em>
            </small>
          )}
        </div>
        {document.tip && (
          <div className={`tip fst-italic fs-8 mt-1`}>{document.tip}</div>
        )}
      </div>

      {!document?.status && document?.status !== 0 && !confirm ? (
        <div className="kyc-folder-document-item__button">
          <label
            htmlFor={`file-input-${document.type}-status-${document.status}-id-${document.id}`}
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onDrop={onHandleDrop}
            title={
              t('souscriptions.stepper.step.kyc.form.file.upload', {
                document: document.label
              }) || ''
            }
            className="upload-document-btn rounded btn-outline-extra fw-500 fs-7 d-flex align-items-center gap-2"
          >
            <TbCloudUpload size={10} />
            <span>
              {' '}
              {!dragOver
                ? t(
                    'souscriptions.stepper.step.kyc.form.file.placeholder.default'
                  )
                : t(
                    'souscriptions.stepper.step.kyc.form.file.placeholder.hover'
                  )}
            </span>
          </label>
          <div className="kyc-folder-document-item__others"></div>
        </div>
      ) : (
        ''
      )}

      {!document?.status && document?.status === 0 ? (
        <DocumentItem
          variant="normal"
          document={document}
          onDownloadDoc={onDownloadDoc}
          onDeleteDoc={onDeleteDoc}
        />
      ) : (
        ''
      )}

      {confirm ? (
        <DocumentItem
          variant="confirm"
          document={document}
          onDownloadDoc={onDownloadDoc}
          onDeleteDoc={onDeleteDoc}
        />
      ) : (
        ''
      )}

      {document?.status && document?.status === -1 ? (
        <DocumentItem
          variant="cancel"
          document={document}
          onDownloadDoc={onDownloadDoc}
          onDeleteDoc={onDeleteDoc}
        />
      ) : (
        ''
      )}

      {document?.status && document?.status === 1 ? (
        <DocumentItem
          variant="valid"
          document={document}
          onDownloadDoc={onDownloadDoc}
          onDeleteDoc={onDeleteDoc}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default KycFolderDocument;
