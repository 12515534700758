import { Routes, Route, Navigate } from 'react-router-dom';

import SouscriptionList from './List';
import SouscriptionInitial from './Initial';
import SouscriptionStepper from './Stepper';

const pages: any[] = [
  { path: '/', component: <SouscriptionList /> },
  { path: '/:id/initialisation', component: <SouscriptionInitial /> },
  { path: '/:id/stepper/:step', component: <SouscriptionStepper /> }
];

const Souscriptions = () => {
  return (
    <Routes>
      {pages.map(({ component, path }) => {
        return <Route key={path} element={component} path={path} />;
      })}
      <Route
        path="*"
        element={<Navigate to={'/space/souscriptions'} replace />}
      />
    </Routes>
  );
};

export default Souscriptions;
