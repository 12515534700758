import { EXTRA } from '@constants/colors';
import { OriginSectionFormGrid } from '@containers';
import KycFolderDocumentGrid from '@containers/KycFolderDocumentGrid';
import { Document, Section } from '@models/Souscription';
import { handleDownloadDocument } from '@pages/Space/Souscription/Stepper/Dossier/helpers';
import { ImportFileType } from '@pages/Space/Souscription/Stepper/Dossier/types';
import { SouscriptionService } from '@services';
import { containsString, isFormulaStatus } from '@utils/functions';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiEdit } from 'react-icons/bi';
import { HiOutlineDocumentAdd } from 'react-icons/hi';
import { useParams } from 'react-router';

interface DossierGridProps {
  selectedSection: Section | null;
  docType: string;
  handleSubmitDocumentType: (file: ImportFileType, doc: Document) => void;
  setDocType: (type: string) => void;
  setShowDocumentType: (value: boolean) => void;
  loadKYC: () => void;
  handleFormulaQuestion: (formuliare: Section, readonly?: boolean) => void;
}

const DossierGrid = ({
  selectedSection,
  docType,
  handleSubmitDocumentType,
  setDocType,
  setShowDocumentType,
  loadKYC,
  handleFormulaQuestion
}: DossierGridProps) => {
  const { t } = useTranslation();
  const { id } = useParams();

  const [showAddDocumentFondGrid, setShowAddDocumentFondGrid] = useState(false);

  const { id: ssid = '', type, data = {}, name } = selectedSection || {};
  const { documents = [], formulaires = [] } = data;

  const handleUploadNewOrigineDocumentGrid = async (file: ImportFileType) => {
    if (!docType) {
      return;
    }

    const data = {
      id_section: ssid,
      id_document: 0,
      name: docType,
      filename: file.name,
      // filetype: selectedImage.type,
      file: file.base64
    };

    await SouscriptionService.store(data, id || '');
    setShowAddDocumentFondGrid(false);
    setDocType('');
    loadKYC();
  };

  return (
    <>
      {containsString(type, 'document') && (
        <div className="souscriptions-stepper-dossier__grid my-2">
          {documents.map((document: Document, index: number) => (
            <KycFolderDocumentGrid
              key={index}
              onDownloadDoc={() => handleDownloadDocument(document.id || '')}
              document={document}
              onUploadDoc={(value) => handleSubmitDocumentType(value, document)}
            />
          ))}

          {containsString(name, 'ubo') && (
            <div
              className="souscriptions-stepper-dossier__item"
              onClick={() => setShowDocumentType(true)}
            >
              <div
                className={`souscriptions-stepper-dossier__item__content is-dash`}
              >
                {t('souscriptions.stepper.button.addDocument')}
              </div>
            </div>
          )}

          {containsString(name, 'origine') && !showAddDocumentFondGrid && (
            <div
              className="souscriptions-stepper-dossier__item"
              onClick={() => setShowAddDocumentFondGrid(true)}
            >
              <div
                className={`souscriptions-stepper-dossier__item__content is-dash`}
              >
                {t('souscriptions.stepper.button.addDocument')}
              </div>
            </div>
          )}

          {showAddDocumentFondGrid && (
            <OriginSectionFormGrid
              type={docType}
              onUploadDoc={handleUploadNewOrigineDocumentGrid}
              onTypeChange={setDocType}
            />
          )}
        </div>
      )}

      {containsString(type, 'formulaire') && (
        <div className="souscriptions-stepper-dossier__grid my-2">
          {formulaires.map((formulaire, index) => (
            <div
              className="souscriptions-stepper-dossier__item"
              key={index}
              onClick={() => handleFormulaQuestion(formulaire)}
            >
              <div
                className={`souscriptions-stepper-dossier__item__content ${
                  isFormulaStatus(formulaire) === 'VALIDATED' ? '' : 'is-dash'
                }`}
              >
                <svg
                  xmlns="https://www.w3.org/2000/svg"
                  version="1.1"
                  height="0"
                >
                  <filter id="myblurfilter" width="110%" height="100%">
                    <feGaussianBlur stdDeviation="2" result="blur" />
                  </filter>
                </svg>

                {isFormulaStatus(formulaire) === 'VALIDATED' && (
                  <>
                    <div
                      className="souscriptions-stepper-dossier__item__badge"
                      style={{
                        backgroundColor: '#269999'
                      }}
                    >
                      {t(
                        'souscriptions.stepper.step.kyc.form.display.formulaire.etat.valide.title'
                      )}
                    </div>
                    <BiEdit size={15} color={EXTRA} />
                    {t(
                      'souscriptions.stepper.step.kyc.form.display.formulaire.etat.valide.text'
                    )}
                  </>
                )}

                {isFormulaStatus(formulaire) === 'TO_COMPLETE' && (
                  <>
                    <div
                      className="souscriptions-stepper-dossier__item__badge"
                      style={{
                        backgroundColor: '#19468E88'
                      }}
                    >
                      {t(
                        'souscriptions.stepper.step.kyc.form.display.formulaire.etat.complete.title'
                      )}
                    </div>
                    <HiOutlineDocumentAdd size={15} color={EXTRA} />
                    {t(
                      'souscriptions.stepper.step.kyc.form.display.formulaire.etat.complete.text'
                    )}
                  </>
                )}

                {isFormulaStatus(formulaire) === 'PENDING' && (
                  <>
                    <div
                      className="souscriptions-stepper-dossier__item__badge"
                      style={{
                        backgroundColor: '#8a39adbb'
                      }}
                    >
                      {t(
                        'souscriptions.stepper.step.kyc.form.display.formulaire.etat.attente.title'
                      )}
                    </div>
                    <BiEdit size={15} color={EXTRA} />
                    {t(
                      'souscriptions.stepper.step.kyc.form.display.formulaire.etat.attente.text'
                    )}
                  </>
                )}
              </div>
              <div className="souscriptions-stepper-dossier__item__title">
                {formulaire.name}
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default DossierGrid;
