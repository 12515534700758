import Investissement from './Investissement';
import Profile from './User/Profile';
import Entite from './Entite';
import Users from './User';
import Souscription from './Souscription';
import { Navigate } from 'react-router';

export const pages: any[] = [
  { path: '/', component: <Navigate replace to="/space/souscriptions" /> },
  { path: '/souscriptions/*', component: <Souscription /> },
  { path: '/users/*', component: <Users /> },
  { path: '/entites/*', component: <Entite /> },
  { path: '/profil', component: <Profile /> }
];
