import { Question } from '@models/Souscription';

export interface FormKYCCheckboxProps {
  question: Question;
  handleQuestionUpdate: (answer: any, id: string, type?: string) => void;
  readonly?: boolean;
}

const FormKYCCheckbox = ({
  question: { id, answer, label },
  handleQuestionUpdate,
  readonly
}: FormKYCCheckboxProps) => {
  return (
    <div
      key={`question-value-${id}`}
      className="checkbox-field text-extra fw-600"
    >
      <label
        htmlFor={`question-${id}`}
        className="d-flex gap-3 field mb-4 justify-content-start align-items-center"
      >
        <input
          checked={(answer || 'Non').toLowerCase() === 'oui'}
          type="checkbox"
          value={(answer || 'Non').toLowerCase() === 'oui' ? 'Non' : 'Oui'}
          name={id}
          onChange={(e) => handleQuestionUpdate(e.target.value, id || '')}
          className={`input-box d-none`}
          id={`question-${id}`}
          disabled={readonly}
        />
        <div className="box" />
        {label}
      </label>
    </div>
  );
};

export default FormKYCCheckbox;
