import { SelectHTMLAttributes } from 'react';
import uuid from 'react-uuid';

import './Select.scss';

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  id?: string;
  lable?: string;
  icon?: any;
  classSelect?: string;
  classLable?: string;
  classBox?: string;
  classIcon?: string;
  placeholder?: string;
  error?: {
    state: boolean;
    message: string;
  };
}

const Select = ({
  id = uuid(),
  classLable = '',
  classSelect = '',
  classBox = 'mb-4',
  classIcon = '',
  lable,
  icon,
  placeholder = '',
  error = { state: false, message: '' },
  children,
  ...args
}: SelectProps) => {
  return (
    <div className={`form-select-custom ${classBox}`}>
      {lable && (
        <label
          htmlFor={id}
          className={`fw-500 form-select-custom__lable mb-1 ${classLable}`}
        >
          {lable}
        </label>
      )}
      <div className="form-select-custom__container position-relative">
        <select
          id={id}
          className={`form-select-custom__control form-control form-select ${
            error.state && 'is-invalid'
          } ${classSelect}`}
          {...args}
        >
          {placeholder && (
            <option value={''} disabled>
              {placeholder}
            </option>
          )}
          {children}
        </select>

        {icon && !error.state && (
          <span className={`form-select-custom__icon ${classIcon}`}>
            {icon}
          </span>
        )}
      </div>

      {error.message && (
        <small className="form-select-custom__error-text form-text text-muted text-danger">
          {error.message}
        </small>
      )}
    </div>
  );
};

export default Select;
