import { FormEvent, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Icon from '@mdi/react';
import { mdiCellphoneKey } from '@mdi/js';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Input from '@components/Input';
import { EXTRA } from '@constants/colors';
import { AuthService } from '@services';
import { useAppSelector } from '@store';
import './DoubleAuthenticate.scss';
import { getAuthUser } from '@store/auth/selectors';

const DoubleAuthenticate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [user2faCode, set2faCode] = useState('');
  const [redirectURL, setRedirectURL] = useState('');

  const user = useAppSelector(getAuthUser);

  useEffect(() => {
    let url = '/space';

    if (location.state && (location.state as any)?.path) {
      url = (location.state as any)?.path;
    }

    setRedirectURL(url);
  }, []);

  const handelValidateCode = async (event: FormEvent) => {
    event.preventDefault();
    await AuthService.validate2FA(user?.id || '', user2faCode);
    navigate(redirectURL, { replace: true, state: null });
  };

  return (
    <div className="double-authenticate">
      <Helmet>
        <title>{t('auth.doubleAuth')}</title>
      </Helmet>

      <div className="double-authenticate__form container px-4">
        <h2 className="double-authenticate__form__title text-center mb-4 fs-3">
          {t('auth.doubleAuth')}
        </h2>

        <p className="double-authenticate__form__text text-center fw-300">
          {t('auth.indication')}:{' '}
          <span className="link-m__mail">{user?.email}</span>
          <div className="mt-2"> {t('auth.spam')} </div>
        </p>

        <form onSubmit={handelValidateCode}>
          <Input
            required
            placeholder={t('auth.codeSecret').toString()}
            error={{ state: false, message: '' }}
            icon={<Icon path={mdiCellphoneKey} size={'1.2rem'} color={EXTRA} />}
            classInput="bg-white text-extra border-none"
            classBox="mb-2"
            onChange={(e) => set2faCode(e.target.value)}
            value={user2faCode}
          />
          <div className=" d-flex justify-content-end">
            <span className="text-white  link-m ">
              <span
                id="link-m__label"
                style={{ textDecoration: 'none !important' }}
              >
                {' '}
                {t('auth.aucunMail')}{' '}
              </span>{' '}
              <a
                className="link-m__mail text-white"
                href="clientservices.instit@eurazeo.com"
              >
                {' '}
                {t('auth.support')}
              </a>
            </span>
          </div>
          <div className="double-authenticate__form__button d-flex justify-content-between mt-5">
            <Link
              to="/auth/login"
              className="btn btn-outline-secondary text-uppercase"
            >
              {t('auth.annuler')}
            </Link>
            <button type="submit" className="btn btn-primary text-uppercase">
              {t('auth.valider')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DoubleAuthenticate;
