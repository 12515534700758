import { Document, Section } from '@models/Souscription';
import { DocumentService } from '@services';
import { base64toBlobV2 } from '@utils/functions';

export const isDocAlreadyImportedBo = (doc: Document, bo: string) => {
  if (!doc.label) return false;
  return doc.label?.includes(bo);
};

export const getBoDocumentsById = (
  bo: string,
  selectedSection: Section | null
) => {
  const importedDocs = (selectedSection?.data.documents || []).filter((doc) =>
    doc.label?.includes(bo)
  );
  const boSectionDocs = (selectedSection?.data.documents || []).filter(
    (doc) => !doc.status && doc.status !== 0 && !isDocAlreadyImportedBo(doc, bo)
  );

  return {
    total: boSectionDocs.length + importedDocs.length,
    imported: importedDocs.length
  };
};

export const downloadDocument = async (
  base64: string,
  filename: string,
  filetype: string
) => {
  try {
    const blob = await base64toBlobV2(base64);

    const downloadUrl = window.URL.createObjectURL(blob);

    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = downloadUrl;

    tempLink.download = `${filename.split('.' + filetype)[0]}.${filetype}`;
    // tempLink.target = "_blank";
    // tempLink.rel = "noopener";

    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
  } catch (error) {
    console.error(error);
  }
};

export const handleDownloadDocument = async (id: string) => {
  try {
    const doc = await DocumentService.download(id);
    const blob = await base64toBlobV2(doc.file);
    const blobURL = window.URL.createObjectURL(blob);

    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', doc.filename);

    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank');
      window.open(blobURL, '_blank');
    }

    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);

    setTimeout(() => {
      window.URL.revokeObjectURL(blobURL);
    }, 100);
  } catch (error) {
    console.error(error);
  }
};

export const filterByStatus = (docs: Document[] = [], status: number) =>
  docs.filter((doc) => doc.status === status);

export const filterBySuggested = (docs: Document[] = []) =>
  docs.filter((doc) => doc.suggested_document);
