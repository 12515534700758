import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Souscription from '@models/Souscription';

import './InitierSouscription.scss';

interface InitierSouscriptionProps {
  data: Souscription;
}

const InitierSouscription = ({ data }: InitierSouscriptionProps) => {
  const { t } = useTranslation();

  return (
    <div className="initier-souscription-item shadow text-extra bg-white px-3 w-100">
      <div className="row gy-3 gy-lg-0">
        <div className="col-lg-3 d-flex align-items-center justify-content-center justify-content-lg-start">
          <span className="initier-souscription-item__title fw-600">
            {data.fund.name}
          </span>
        </div>

        <div className="col-lg-6 col-xl-7 d-flex align-items-center justify-content-center justify-content-lg-start">
          <div className="initier-souscription-item__details">
            {t('souscriptions.startInvite', { fundName: data.fund.name })}
          </div>
        </div>

        <div className="col-lg-3 col-xl-2 d-flex justify-content-center align-items-center">
          <Link
            to={`/space/souscriptions/${data.id}/initialisation`}
            className="btn btn-sm btn-primary"
          >
            {t('souscriptions.start')}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default InitierSouscription;
