import { DANGER } from '@constants/colors';
import { BsCheckCircleFill, BsClockFill, BsXCircleFill } from 'react-icons/bs';
import { MdDownloadForOffline } from 'react-icons/md';

export const uboUserInit = {
  id: '',
  lastname: '',
  firstname: '',
  expire: '',
  email: '',
  telephone: '',
  citizenship: '',
  birthdate: ''
};

export const boUserInit = {
  lastname: '',
  firstname: '',
  citizenship: '',
  birthdate: '',
  role: 'bo'
};

export const importFileInit = {
  base64: '',
  type: '',
  name: ''
};

export const formsConfig = [
  {
    status: 'TO_COMPLETE',
    title: 'souscriptions.stepper.step.kyc.form.display.formulaire.completer',
    icon: (
      <MdDownloadForOffline
        size={25}
        className="absolute-full"
        color="#19468E88"
      />
    ),
    needsAnswers: true,
    isEditable: false
  },
  {
    status: 'REJECTED',
    title: 'souscriptions.stepper.step.kyc.form.display.formulaire.rejected',
    icon: <BsXCircleFill size={15} color={DANGER} className="" />,
    isEditable: true
  },
  {
    status: 'PENDING',
    title: 'souscriptions.stepper.step.kyc.form.display.formulaire.validation',
    icon: <BsClockFill size={15} color="#8a39adbb" />,
    isEditable: true
  },
  {
    status: 'VALIDATED',
    title: 'souscriptions.stepper.step.kyc.form.display.formulaire.valider',
    icon: <BsCheckCircleFill size={15} color="#269999" className="" />,
    isEditable: false
  }
];
