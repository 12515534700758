import Input from '@components/Input';
import PhoneNumberInput from '@components/PhoneNumberInput';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import SimpleBar from 'simplebar-react';
import Select from '@components/Select';
import { adjustDate } from '@pages/Space/helpers';
import { maxDate, minDate } from '@pages/Space/constants';
import './Configuration.scss';
import { Question, QuestionForm } from '@models/Souscription';
import { transformQuestionValues } from '@utils/functions';
import { CountryType } from '@models/Country';
import { useAppSelector } from '@store/index';
import { getUserProfile } from '@store/user/selectors';

export interface Step0Props {
  step: number;
  investorForm: any;
  questions: Question[];
  setStep: (step: number) => void;
  setInvestorForm: (form: any) => void;
  setQuestionValues: (form: QuestionForm[]) => void;
  goToPrevious: () => void;
  postData: () => void;
  countries: CountryType[];
}

export default function Step0({
  step,
  investorForm,
  questions,
  setStep,
  setInvestorForm,
  setQuestionValues,
  goToPrevious,
  postData,
  countries
}: Step0Props) {
  const { t } = useTranslation();
  const [isTaxExempt, setIsTaxExempt] = useState(false);

  const { activities } = useAppSelector(getUserProfile) ?? {};

  const baseTranslationKey =
    'souscriptions.stepper.step.configuration.form.entite';

  const handleGoToNextStep = () => {
    if (step === 0) {
      const relevantKeys = [
        'name',
        'email',
        'telephone',
        'activity',
        'fiscal_identifier',
        'legal_entity_type',
        'country',
        'address',
        'city',
        'zipcode',
        'incorporation_city'
      ];

      const allNotEmpty = relevantKeys.every(
        (key) => investorForm[key] && String(investorForm[key]).length > 0
      );

      if (!allNotEmpty) {
        toast.error(
          t(
            'souscriptions.stepper.step.configuration.form.error.emptyFieldMessage'
          ) || ''
        );
      } else {
        setStep(step + 1);

        postData();
      }
    } else {
      setStep(step + 1);
    }
  };

  const handleUpdateInvestorForm = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const key = e.target.name || '';

    setInvestorForm({ ...investorForm, [key]: e.target.value });
  };

  const handleFormChange = (key: string, value: string) => {
    setInvestorForm({
      ...investorForm,
      [key]: value
    });
  };

  const getQuestionByLabel = (value: string) =>
    questions.find(({ label }) => value === label);

  const investorFormKeyToQuestionMap: Record<string, Question | undefined> =
    useMemo(
      () => ({
        name: getQuestionByLabel('Company name'),
        email: getQuestionByLabel('Email'),
        telephone: getQuestionByLabel('Phone number'),
        activity: getQuestionByLabel('Investor type'),
        fiscal_identifier: getQuestionByLabel('Registry number'),
        legal_entity_type: getQuestionByLabel('Legal form'),
        country: getQuestionByLabel('Country of tax residence'),
        city: getQuestionByLabel('City'),
        zipcode: getQuestionByLabel('Postal code'),
        address: getQuestionByLabel('Business registered address'),
        incorporation_city: getQuestionByLabel('Place of company registration'),
        taxable_year_end: getQuestionByLabel('End date of the tax year')
      }),
      [questions]
    );
  const taxExemptQuestion = useMemo(
    () => getQuestionByLabel('Tax exempt'),
    [questions]
  );

  const checkInvestorFormQuestionVisibility = (key: string) =>
    !!investorFormKeyToQuestionMap[key];

  useEffect(() => {
    const updateQuestionValues = () => {
      Object.entries(investorFormKeyToQuestionMap).forEach(
        ([investorFormKey, question]) => {
          if (!question) return;
          question.answer = investorForm[investorFormKey];
        }
      );
      if (investorFormKeyToQuestionMap.country) {
        const country = countries.find(
          (country) =>
            country.code.toLowerCase() === investorForm.country.toLowerCase()
        );
        investorFormKeyToQuestionMap.country.answer = country?.id;
      }
      if (taxExemptQuestion) {
        taxExemptQuestion.answer = isTaxExempt;
      }

      const refractQuestionsValues = questions.map((qes) =>
        transformQuestionValues(qes, countries)
      );
      setQuestionValues(refractQuestionsValues);
    };

    updateQuestionValues();
  }, [investorFormKeyToQuestionMap, investorForm, isTaxExempt, countries]);

  return (
    <form style={{ width: '100%' }} id="step0" onSubmit={handleGoToNextStep}>
      <SimpleBar className="w-100" style={{ height: '48vh' }}>
        <div className="row mt-4">
          {checkInvestorFormQuestionVisibility('name') && (
            <Input
              required
              lable={t(`${baseTranslationKey}.denomination.label`) || ''}
              placeholder={
                t(`${baseTranslationKey}.denomination.placeholder`) || ''
              }
              classLable="text-extra fw-600 mb-2"
              classBox="mb-4 col-md-12"
              value={investorForm?.name || ''}
              name="name"
              onChange={handleUpdateInvestorForm}
              disabled
            />
          )}

          {checkInvestorFormQuestionVisibility('email') && (
            <Input
              required
              type="email"
              lable={t(`${baseTranslationKey}.email.label`) || ''}
              placeholder={t(`${baseTranslationKey}.email.placeholder`) || ''}
              classLable="text-extra fw-600 mb-2"
              classBox="mb-4 col-md-6"
              value={investorForm?.email || ''}
              name="email"
              onChange={handleUpdateInvestorForm}
            />
          )}

          {checkInvestorFormQuestionVisibility('telephone') && (
            <PhoneNumberInput
              required
              lable={t(`${baseTranslationKey}.telephone.label`) || ''}
              placeholder={
                t(`${baseTranslationKey}.telephone.placeholder`) || ''
              }
              classLable="text-extra fw-600 mb-2"
              classBox="mb-4 col-md-6"
              value={investorForm?.telephone || ''}
              onChangeValue={(value) => handleFormChange('telephone', value)}
            />
          )}

          {checkInvestorFormQuestionVisibility('activity') && (
            <Select
              required
              lable={t(`${baseTranslationKey}.activitePrincipale.label`) || ''}
              placeholder={
                t(`${baseTranslationKey}.activitePrincipale.placeholder`) || ''
              }
              value={investorForm?.activity || ''}
              classSelect="text-extra"
              classLable="text-extra fw-600 mb-2"
              classBox="mb-4 col-md-4"
              name="activity"
              onChange={handleUpdateInvestorForm}
            >
              {activities?.map((activity: string) => (
                <option key={`activity-${activity}`} value={activity}>
                  {activity}
                </option>
              ))}
            </Select>
          )}

          {checkInvestorFormQuestionVisibility('fiscal_identifier') && (
            <Input
              required
              lable={t(`${baseTranslationKey}.idFiscal.label`) || ''}
              placeholder={
                t(`${baseTranslationKey}.idFiscal.placeholder`) || ''
              }
              classLable="text-extra fw-600 mb-2"
              classBox="mb-4 col-md-4"
              value={investorForm?.fiscal_identifier || ''}
              name="fiscal_identifier"
              onChange={handleUpdateInvestorForm}
            />
          )}

          {checkInvestorFormQuestionVisibility('legal_entity_type') && (
            <Input
              required
              lable={t(`${baseTranslationKey}.formeLegale.label`) || ''}
              placeholder={
                t(`${baseTranslationKey}.formeLegale.placeholder`) || ''
              }
              classLable="text-extra fw-600 mb-2"
              classBox="mb-4 col-md-4"
              value={investorForm?.legal_entity_type || ''}
              name="legal_entity_type"
              onChange={handleUpdateInvestorForm}
            />
          )}

          {checkInvestorFormQuestionVisibility('country') && (
            <Select
              required
              lable={t(`${baseTranslationKey}.paysDomiciliation.label`) || ''}
              placeholder={
                t(`${baseTranslationKey}.paysDomiciliation.placeholder`) || ''
              }
              value={investorForm?.country || ''}
              classSelect="text-extra"
              classLable="text-extra fw-600 mb-2"
              classBox="mb-4 col-md-4"
              name="country"
              onChange={handleUpdateInvestorForm}
              disabled
            >
              {countries.map((country) => (
                <option key={country.id} value={country.code}>
                  {country.name || ''}
                </option>
              ))}
            </Select>
          )}

          {checkInvestorFormQuestionVisibility('city') && (
            <Input
              required
              lable={t(`${baseTranslationKey}.ville.label`) || ''}
              placeholder={t(`${baseTranslationKey}.ville.placeholder`) || ''}
              classLable="text-extra fw-600 mb-2"
              classBox="mb-4 col-md-8"
              value={investorForm?.city || ''}
              name="city"
              onChange={handleUpdateInvestorForm}
            />
          )}

          {checkInvestorFormQuestionVisibility('zipcode') && (
            <Input
              required
              lable={t(`${baseTranslationKey}.codePostal.label`) || ''}
              placeholder={
                t(`${baseTranslationKey}.codePostal.placeholder`) || ''
              }
              classLable="text-extra fw-600 mb-2"
              classBox="mb-4 col-md-4"
              value={investorForm?.zipcode || ''}
              name="zipcode"
              onChange={handleUpdateInvestorForm}
            />
          )}

          {checkInvestorFormQuestionVisibility('address') && (
            <Input
              required
              lable={
                t(`${baseTranslationKey}.adresseDomiciliation.label`) || ''
              }
              placeholder={
                t(`${baseTranslationKey}.adresseDomiciliation.placeholder`) ||
                ''
              }
              classLable="text-extra fw-600 mb-2"
              classBox="mb-4 col-md-8"
              value={investorForm?.address || ''}
              name="address"
              onChange={handleUpdateInvestorForm}
            />
          )}

          {checkInvestorFormQuestionVisibility('incorporation_city') && (
            <Input
              required
              lable={t(`${baseTranslationKey}.lieuEnregistrement.label`) || ''}
              placeholder={
                t(`${baseTranslationKey}.lieuEnregistrement.placeholder`) || ''
              }
              classLable="text-extra fw-600 mb-2"
              classBox="mb-4 col-md-4"
              value={investorForm?.incorporation_city || ''}
              name="incorporation_city"
              onChange={handleUpdateInvestorForm}
            />
          )}

          {checkInvestorFormQuestionVisibility('taxable_year_end') && (
            <div className="col-md-8 d-flex align-items-center">
              <Input
                required={!isTaxExempt}
                type="date"
                lable={t(`${baseTranslationKey}.dateFinExercice.label`) || ''}
                placeholder={
                  t(`${baseTranslationKey}.dateFinExercice.placeholder`) || ''
                }
                classLable="text-extra fw-600"
                classBox="mb-4 col-md-6"
                value={adjustDate(investorForm?.taxable_year_end || '') || ''}
                onChange={(e) =>
                  handleFormChange('taxable_year_end', e.target.value)
                }
                min={minDate}
                max={maxDate}
                disabled={isTaxExempt}
              />
              {!!taxExemptQuestion && (
                <label className="d-flex gap-3 field justify-content-start align-items-center px-4">
                  <input
                    checked={isTaxExempt}
                    type="checkbox"
                    name="taxStatus"
                    id="taxStatus"
                    onChange={(e) => setIsTaxExempt(e.target.checked)}
                    className={`input-box d-none`}
                  />
                  <div className="box" />
                  {t(`${baseTranslationKey}.taxStatus.label`) || ''}
                </label>
              )}
            </div>
          )}
        </div>
      </SimpleBar>

      <div className="d-flex justify-content-center gap-4 pt-4">
        <button
          type="button"
          onClick={goToPrevious}
          className="btn btn-extra border-2 me-2"
        >
          {t('souscriptions.stepper.button.retour')}
        </button>

        <button type="submit" form="step0" className="btn btn-basic text-white">
          {t('souscriptions.stepper.button.valider')}
        </button>
      </div>
    </form>
  );
}
