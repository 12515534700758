import { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import Icon from '@mdi/react';
import {
  mdiTimerSand,
  mdiShieldCheckOutline,
  mdiShieldOutline,
  mdiEye,
  mdiClose
} from '@mdi/js';
import SimpleBar from 'simplebar-react';
import { useTranslation } from 'react-i18next';
import { BiRefresh } from 'react-icons/bi';
import { MdEdit } from 'react-icons/md';
import 'simplebar/dist/simplebar.min.css';

import { useAppSelector } from '@store';
import Title from '@components/Title';
import { UserService } from '@services';
import { BASIC, EXTRA, WHITE, DANGER } from '@constants/colors';
import { Box } from '@containers';
import './List.scss';
import { getUserProfile } from '@store/user/selectors';
import { getRole } from '@pages/Space/User/List/helpers';

const UserList = () => {
  const { t } = useTranslation();
  const [refreshing, setRefreshing] = useState(false);

  const users = useAppSelector((state) =>
    state?.user.data.filter((user, i) => user.role !== 'bo')
  );
  const currentuser = useAppSelector(getUserProfile);

  const refresh = async () => {
    setRefreshing(true);
    await UserService.refresh();
    setRefreshing(false);
  };

  useEffect(() => {
    if (!currentuser?.role) UserService.loadProfile();
    UserService.all();
  }, []);

  return (
    <div className="users-list">
      <Helmet>
        <title>{t('titles.users')}</title>
      </Helmet>

      <div className="users-list__container container px-3">
        <Title title={t('titles.users').toString()}>
          <span
            onClick={refresh}
            style={{ width: 40, height: 40 }}
            className={`refresh__btn block ${refreshing ? 'load--rote' : ''}`}
          >
            <BiRefresh size={30} color={WHITE} />
          </span>
        </Title>

        <Box>
          <div className="p-1 px-2 h-100 d-flex flex-column justify-content-between">
            <div className="users-details__data-count mb-1">
              <p className="fw-500">
                {users?.length} {t('users.add')}
              </p>
            </div>

            <div className="table-responsive">
              <table className="users-list__table table table-hover align-middle text-extra mb-0 border-bottom border-2 border-extra">
                <thead>
                  <tr>
                    <th scope="col" className="table__td">
                      {t('users.email')}
                    </th>
                    <th scope="col" className="table__td--2">
                      {t('users.lastname')}
                    </th>
                    <th scope="col" className="table__td--2">
                      {t('users.firstname')}
                    </th>
                    <th scope="col" className="table__td--2">
                      {t('users.role')}
                    </th>
                    <th scope="col">{t('users.idCard')}</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
              </table>
              <SimpleBar style={{ maxHeight: 320 }}>
                <table className="users-list__table table table-hover align-middle text-extra">
                  <tbody>
                    {(users || []).map((user) => (
                      <tr key={user?.id} className="tr-ligne">
                        <th
                          scope="row"
                          className="table__td  text-truncate"
                          style={{ maxWidth: 340 }}
                        >
                          {user?.email}
                        </th>
                        <td
                          className="fw-500 table__td--2 text-truncate"
                          style={{ maxWidth: 130 }}
                        >
                          {user?.firstname || ''}
                        </td>
                        <td
                          className="fw-500 table__td--2 text-truncate"
                          style={{ maxWidth: 130 }}
                        >
                          {user?.lastname || ''}
                        </td>
                        <td className="fw-500 text-capitalize table__td--2 text-truncate">
                          {t(getRole(user?.role || '')).toString()}
                        </td>
                        <td className="fw-500">
                          {user?.status === 1 ? (
                            <Fragment>
                              <Icon
                                path={mdiShieldCheckOutline}
                                size={'1rem'}
                                color={BASIC}
                              />
                              <span className="ms-2 text-basic">
                                {t('users.idStatus._validated')}
                              </span>
                            </Fragment>
                          ) : user?.status === 0 ? (
                            <Fragment>
                              <Icon
                                path={mdiShieldOutline}
                                size={'1rem'}
                                color={EXTRA}
                              />
                              <span className="ms-2">
                                {t('users.idStatus._progress')}
                              </span>
                            </Fragment>
                          ) : user?.status === -1 ? (
                            <Fragment>
                              <Icon
                                path={mdiClose}
                                size={'1rem'}
                                color={DANGER}
                              />
                              <span className="ms-2">
                                {t('users.idStatus._rejected')}
                              </span>
                            </Fragment>
                          ) : (
                            <Fragment>
                              <Icon
                                path={mdiTimerSand}
                                size={'1rem'}
                                color={EXTRA}
                              />
                              <span className="ms-2">
                                {t('users.idStatus._pending')}
                              </span>
                            </Fragment>
                          )}
                        </td>
                        <td>
                          <div className="d-flex tr-ligne__icon  justify-content-center align-items-center">
                            <Link
                              to={`/space/users/${user?.id}`}
                              className="btn btn-accent btn-icon btn-icon-sm me-2"
                            >
                              <Icon path={mdiEye} size={'1rem'} color={WHITE} />
                            </Link>
                            {currentuser?.role === 'manager' && (
                              <Link
                                to={`/space/users/${user?.id}/modifier`}
                                className="btn btn-accent btn-icon btn-icon-sm"
                              >
                                <MdEdit size={'1rem'} color={WHITE} />
                              </Link>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}

                    {(users || [])?.length <= 0 && (
                      <tr>
                        <th className="text-center py-5 fs-3" colSpan={6}>
                          {t('users.nothing')}
                        </th>
                      </tr>
                    )}
                  </tbody>
                </table>
              </SimpleBar>
            </div>

            <div className="user-details__form__buttons d-flex justify-content-between mt-2">
              <div className="user-details__form__buttons__left">
                {/* <Tooltip
                                        content="Ici seront transmis des complements d'informations pour vous aider à réaliser cette étape."
                                    >
                                        <button
                                            type="button"
                                            className="btn btn-accent btn-icon"
                                        >
                                            <Icon path={mdiHelp} size={'1.3rem'} color={WHITE} />
                                        </button>
                                    </Tooltip> */}
              </div>

              {currentuser?.role === 'manager' && (
                <div className="user-details__form__buttons__right">
                  <Link to={`/space/users/nouveau`} className="btn btn-accent">
                    {t('users.buttons._add')}
                  </Link>
                </div>
              )}
            </div>
          </div>
        </Box>
      </div>
    </div>
  );
};

export default UserList;
