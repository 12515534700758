import Input from '@components/Input';
import PhoneNumberInput from '@components/PhoneNumberInput';
import { DANGER } from '@constants/colors';
import Signataire from '@models/Signataire';
import { SignataireForType } from '@pages/Space/Entite/Form/types';
import { SignataireService } from '@services';
import { FormEvent, RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { BsFillPlusCircleFill } from 'react-icons/bs';
import { FaTimesCircle, FaTrashAlt } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

interface EditStep2Props {
  signers: Signataire[];
  addSigner?: boolean;
  newSignerFormRef: RefObject<HTMLFormElement>;
  signer: SignataireForType;
  step: number;
  handleFormSigneChange: (key: string, value: string) => void;
  handleFormSubmit: (evt: FormEvent) => void;
  setAddSigner: (value: boolean) => void;
  LoadSigners: () => void;
  setSigner: (signer: SignataireForType) => void;
  setSigners: (signers: Signataire[]) => void;
  setStep: (step: number) => void;
}

const EditStep2 = ({
  signers,
  addSigner,
  newSignerFormRef,
  signer,
  step,
  handleFormSubmit,
  handleFormSigneChange,
  setAddSigner,
  LoadSigners,
  setSigner,
  setSigners,
  setStep
}: EditStep2Props) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const handleTerminer = () => {
    (signers || []).forEach((signer: { id: string }) => {
      updateSigner(signer.id);
    });
    navigate(`/space/entites`, { replace: true });
  };

  const saveSigner = async () => {
    if (!newSignerFormRef.current?.checkValidity()) {
      newSignerFormRef.current?.reportValidity();
      return;
    }

    await SignataireService.store(signer, id || '');
    setAddSigner(false);
    setSigner({
      firstname: '',
      lastname: '',
      email: '',
      telephone: '',
      title: ''
    });
    LoadSigners();
  };

  const removeSigner = async (idSigner: string) => {
    await SignataireService.delete(idSigner);
    LoadSigners();
  };

  const handleFormSigneArrayChange = (
    key: string,
    value: string,
    id: string
  ) => {
    setSigners(
      signers.map((signer) => {
        if (signer.id === id) {
          return { ...signer, [key]: value };
        }
        return signer;
      })
    );
  };

  const updateSigner = async (idSigner: string) => {
    const data = signers.find((signer) => signer.id === idSigner);
    await SignataireService.update(data, idSigner);
    LoadSigners();
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <SimpleBar className="px-3" style={{ height: '50vh' }}>
        {addSigner && (
          <form ref={newSignerFormRef} className="col-md-12">
            <div className="my-3">
              <div className="d-flex justify-content-between align-items-center w-100 mb-1">
                <div className="lh-sm fw-600 fs-5">
                  {t('entites.form.signatory.new')}
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => setAddSigner(false)}
                >
                  <FaTimesCircle size={20} />
                </div>
              </div>
              <hr className="m-0" />
            </div>
            <div className="row g-md-4">
              <Input
                required
                classBox="col-md-4"
                lable={t('entites.form.signatory.name.label').toString()}
                placeholder={t(
                  'entites.form.signatory.name.placeholder'
                ).toString()}
                value={signer.lastname || ''}
                classInput="text-extra"
                classLable="text-extra fw-600"
                onChange={(e) =>
                  handleFormSigneChange('lastname', e.target.value)
                }
              />

              <Input
                required
                classBox="col-md-4"
                lable={t('entites.form.signatory.firstName.label').toString()}
                placeholder={t(
                  'entites.form.signatory.firstName.placeholder'
                ).toString()}
                value={signer.firstname || ''}
                classInput="text-extra"
                classLable="text-extra fw-600"
                onChange={(e) =>
                  handleFormSigneChange('firstname', e.target.value)
                }
              />

              <Input
                required
                classBox="col-md-4"
                placeholder={t('entites.form.signatory.title').toString()}
                lable={t('entites.form.signatory.title').toString()}
                value={signer.title || ''}
                classInput="text-extra"
                classLable="text-extra fw-600"
                onChange={(e) => handleFormSigneChange('title', e.target.value)}
              />

              <Input
                required
                classBox="col-md-4"
                placeholder={t('entites.form.signatory.email').toString()}
                type={t('entites.form.signatory.email').toString()}
                lable="Adresse email"
                value={signer.email || ''}
                classInput="text-extra"
                classLable="text-extra fw-600"
                onChange={(e) => handleFormSigneChange('email', e.target.value)}
              />

              <div className="col-md-4">
                <PhoneNumberInput
                  required
                  lable={t('entites.form.signatory.phone').toString()}
                  placeholder={t('entites.form.signatory.phone').toString()}
                  value={signer?.telephone || ''}
                  classInput="text-extra"
                  classLable="text-extra fw-600"
                  onChangeValue={(value) =>
                    handleFormSigneChange('telephone', value)
                  }
                />
              </div>

              <div className="col-md-4">
                <button
                  type="button"
                  className="btn btn-basic text-white mt-4 me-2"
                  onClick={saveSigner}
                >
                  {t('souscriptions.stepper.button.valider')}
                </button>
              </div>
            </div>
          </form>
        )}

        {(signers || []).map((signe, index) => (
          <div className="col-md-12 my-5" key={index}>
            <div className="my-3">
              <div className="d-flex justify-content-between align-items-center w-100 mb-1">
                <div className="lh-sm fw-600 fs-5">
                  {t('entites.form.signatory.label')} n°
                  {index + 1}
                </div>
                <div className="d-flex align-items-center">
                  <div
                    className="cursor-pointer"
                    onClick={() => removeSigner(signe.id)}
                  >
                    <FaTrashAlt size={20} color={DANGER} />
                  </div>
                </div>
              </div>
              <hr className="m-0" />
            </div>
            <div className="row g-md-4">
              <Input
                required
                classBox="col-md-4"
                lable={t('entites.form.signatory.name.label').toString()}
                placeholder={t(
                  'entites.form.signatory.name.placeholder'
                ).toString()}
                value={signe.lastname || ''}
                classInput="text-extra"
                classLable="text-extra fw-600"
                onChange={(e) =>
                  handleFormSigneArrayChange(
                    'lastname',
                    e.target.value,
                    signe.id
                  )
                }
              />

              <Input
                required
                classBox="col-md-4"
                lable={t('entites.form.signatory.firstName.label').toString()}
                placeholder={t(
                  'entites.form.signatory.firstName.placeholder'
                ).toString()}
                value={signe.firstname || ''}
                classInput="text-extra"
                classLable="text-extra fw-600"
                onChange={(e) =>
                  handleFormSigneArrayChange(
                    'firstname',
                    e.target.value,
                    signe.id
                  )
                }
              />

              <Input
                required
                classBox="col-md-4"
                placeholder={t('entites.form.signatory.title').toString()}
                lable={t('entites.form.signatory.title').toString()}
                value={signe.title || ''}
                classInput="text-extra"
                classLable="text-extra fw-600"
                onChange={(e) =>
                  handleFormSigneArrayChange('title', e.target.value, signe.id)
                }
              />

              <Input
                required
                classBox="col-md-4"
                placeholder={t('entites.form.signatory.email').toString()}
                lable={t('entites.form.signatory.email').toString()}
                type="email"
                value={signe.email || ''}
                classInput="text-extra"
                classLable="text-extra fw-600"
                onChange={(e) =>
                  handleFormSigneArrayChange('email', e.target.value, signe.id)
                }
              />

              <div className="col-md-4">
                <PhoneNumberInput
                  required
                  lable={t('entites.form.signatory.phone').toString()}
                  placeholder={t('entites.form.signatory.phone').toString()}
                  value={signe?.telephone || ''}
                  classInput="text-extra"
                  classLable="text-extra fw-600"
                  onChangeValue={(value) =>
                    handleFormSigneArrayChange('telephone', value, signe.id)
                  }
                />
              </div>

              <div className="col-md-4">
                {/* <button
                          type="button"
                          className="btn btn-basic text-white mt-4 me-2"
                          onClick={() => updateSigner(signe.id)}
                        >
                          MODIFIER
                        </button> */}
              </div>
            </div>
          </div>
        ))}

        {!addSigner && (
          <div className="col-md-12 d-flex align-items-center">
            <button
              className="btn text-extra d-flex align-items-center add-signataire-btn"
              onClick={() => setAddSigner(true)}
            >
              <BsFillPlusCircleFill size={20} color="#1a468e" />
              <span
                className="ms-2 fs-6 fw-600"
                style={{ textTransform: 'none' }}
              >
                {t('entites.form.signatory.add.question')}
              </span>
            </button>
          </div>
        )}
      </SimpleBar>

      <div className="w-100 d-flex justify-content-center gap-4 pt-4">
        <button
          type="button"
          onClick={() => setStep(step - 1)}
          className="btn btn-accent text-white btn-outline-extra me-2"
        >
          {t('souscriptions.stepper.button.retour')}
        </button>
        {!addSigner && (
          <button
            type="submit"
            className="btn btn-basic text-white"
            onClick={handleTerminer}
          >
            {t('souscriptions.stepper.button.terminer')}
          </button>
        )}
      </div>
    </form>
  );
};

export default EditStep2;
