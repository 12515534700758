import Select from '@components/Select';
import { CountryType } from '@models/Country';
import { UserForm } from '@models/User';
import { boUserInit } from '@pages/Space/Souscription/Stepper/Dossier/constants';
import { UserService } from '@services';
import { FormEvent } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface BOFormModalProps {
  showNewBOFormModal?: boolean;
  boForm: UserForm;
  investor: string;
  countries: CountryType[];
  setBoForm: (form: UserForm) => void;
  loadBOs: () => void;
  setShowNewBOFormModal: (value: boolean) => void;
}

const BOFormModal = ({
  showNewBOFormModal,
  boForm,
  investor,
  countries,
  setBoForm,
  loadBOs,
  setShowNewBOFormModal
}: BOFormModalProps) => {
  const { t } = useTranslation();

  const handleSubmitNewBOForm = async (event: FormEvent) => {
    event.preventDefault();
    await UserService.storeInInvestor(investor, boForm);
    setBoForm(boUserInit);
    setShowNewBOFormModal(false);
    loadBOs();
  };

  return (
    <Modal
      show={showNewBOFormModal}
      onHide={() => setShowNewBOFormModal(false)}
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title className="d-flex justify-content-center align-content-center text-black text-center fw-600">
          {t('souscriptions.stepper.step.kyc.bo.add')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmitNewBOForm}>
          <div className="row">
            <div className="form-input mb-4 col-md-6">
              <label className="form-input__lable mb-1 text-extra fw-600">
                {t('souscriptions.stepper.step.kyc.form.bo.nom.label')}
              </label>
              <div className="position-relative">
                <input
                  value={boForm.firstname || ''}
                  placeholder={
                    t(
                      'souscriptions.stepper.step.kyc.form.bo.nom.placeholder'
                    ) || ''
                  }
                  onChange={(e) =>
                    setBoForm({ ...boForm, firstname: e.target.value })
                  }
                  name="firstname"
                  type="text"
                  className="text-extra"
                  required
                />
              </div>
            </div>

            <div className="form-input mb-4 col-md-6">
              <label className="form-input__lable mb-1 text-extra fw-600">
                {t('souscriptions.stepper.step.kyc.form.bo.prenom.label')}
              </label>
              <div className=" position-relative">
                <input
                  value={boForm.lastname || ''}
                  placeholder={
                    t(
                      'souscriptions.stepper.step.kyc.form.bo.prenom.placeholder'
                    ) || ''
                  }
                  onChange={(e) =>
                    setBoForm({ ...boForm, lastname: e.target.value })
                  }
                  name="lastname"
                  type="text"
                  className="text-extra"
                  required
                />
              </div>
            </div>

            <div className="form-group col-md-6">
              <Select
                lable={
                  t(
                    'souscriptions.stepper.step.kyc.form.bo.nationalite.label'
                  ) || ''
                }
                placeholder={
                  t(
                    'souscriptions.stepper.step.kyc.form.bo.nationalite.placeholder'
                  ) || ''
                }
                classLable="text-extra fw-600"
                classBox=""
                value={boForm.citizenship || ''}
                onChange={(e) =>
                  setBoForm({ ...boForm, citizenship: e.target.value })
                }
                name="citizenship"
                required
              >
                {countries.map((country: any, i) => (
                  <option value={(country?.code || '').toUpperCase()} key={i}>
                    {country?.name}
                  </option>
                ))}
              </Select>
            </div>

            <div className="form-input mb-4 col-md-6">
              <label className="form-input__lable mb-1 text-extra fw-600">
                {t('souscriptions.stepper.step.kyc.form.bo.naissanace.label')}
              </label>
              <div className="position-relative">
                <input
                  type="date"
                  value={boForm.birthdate || ''}
                  onChange={(e) =>
                    setBoForm({ ...boForm, birthdate: e.target.value })
                  }
                  name="birthdate"
                  className="text-extra"
                  required
                />
              </div>
            </div>
            <div className="form-input col-md-12 d-flex justify-content-center align-items-center gap-2">
              <button type="submit" className="btn btn-sm btn-basic text-white">
                {t('souscriptions.stepper.button.enregistrer')}
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default BOFormModal;
