import { Fragment, HTMLAttributes } from 'react';
import NumberFormat from 'react-number-format';

interface MoneyFormatProps extends HTMLAttributes<HTMLDivElement> {
  data: number;
  format?: string;
  suffix?: string;
}

const MoneyFormat = ({ data, suffix = ' €' }: MoneyFormatProps) => {
  return (
    <Fragment>
      {data ? (
        <NumberFormat
          displayType="text"
          thousandSeparator={' '}
          value={data}
          suffix={suffix}
        />
      ) : (
        ''
      )}
    </Fragment>
  );
};

export default MoneyFormat;
