import { ChangeEvent } from 'react';
import uuid from 'react-uuid';
import PhoneInput, { CountryData, PhoneInputProps } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import i18next from '@/i18n';
import { getLocalization } from './helpers';
import './PhoneNumberInput.scss';

interface PhoneNumberInputProps extends PhoneInputProps {
  id?: string;
  lable?: string;
  icon?: any;
  password?: boolean;
  classInput?: string;
  classLable?: string;
  classBox?: string;
  classIcon?: string;
  type?: string;
  error?: {
    state: boolean;
    message: string;
  };
  required?: boolean;
  onChangeValue?: (value: string) => void;
}

const PhoneNumberInput = ({
  id = uuid(),
  classLable = '',
  classInput = '',
  classBox = '',
  classIcon = '',
  password = false,
  lable,
  icon,
  placeholder = '',
  required = false,
  error = { state: false, message: '' },
  onChangeValue = () => {},
  value
}: PhoneNumberInputProps) => {
  const language = i18next.language;
  const localization = getLocalization(language);

  const reformatValue = (
    value: string,
    data: CountryData | Record<string, unknown>,
    event: ChangeEvent<HTMLInputElement>,
    formattedValue: string
  ) => {
    onChangeValue(formattedValue);
  };

  const requiedProps = required
    ? {
        required: required,
        pattern: '\\+\\d+\\s[\\d\\s\\(\\)\\-]+',
        title: 'required field'
      }
    : {};

  return (
    <div className={`form-input ${classBox}`}>
      {lable && (
        <label htmlFor={id} className={`form-input__lable mb-1 ${classLable}`}>
          {lable}
        </label>
      )}
      <div className="form-input__container position-relative">
        <PhoneInput
          key={language}
          localization={localization}
          country={'fr'}
          value={value || ''}
          countryCodeEditable={false}
          placeholder={placeholder}
          onChange={reformatValue}
          inputProps={requiedProps}
          dropdownClass="shadow rounded bg-light"
          inputClass={`${
            icon ? 'form-input__control' : ''
          } form-control ${classInput} ${error.state ? 'is-invalid' : ''}`}
        />

        {icon && !password && !error.state && (
          <span className={`form-input__icon ${classIcon}`}>{icon}</span>
        )}
      </div>

      {error.message && (
        <small className="form-input__error-text form-text text-danger">
          {error.message}
        </small>
      )}
    </div>
  );
};

export default PhoneNumberInput;
