import { File } from '@/core/types/file';
import DocumentItem from '@containers/KycFolderDocument/DocumentItem';
import useDragAndDrop from '@hooks/useDragAndDrop';
import { Document } from '@models/Souscription';
import {
  checkFilesExist,
  convertBase64,
  validateUploadedFile
} from '@utils/functions';
import { ChangeEvent, DragEvent, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import DocumentItemGrid from './DocumentItemGrid';
import './index.scss';

interface KycFolderDocumentGridProps {
  document: Document;
  onUploadDoc?: (file: File) => void;
  onDownloadDoc?: () => void;
  onDeleteDoc?: () => void;
  confirm?: boolean;
}

const KycFolderDocumentGrid = ({
  document,
  onUploadDoc = () => {},
  onDownloadDoc = () => {},
  onDeleteDoc = () => {},
  confirm = false
}: KycFolderDocumentGridProps) => {
  const { t } = useTranslation();

  const onHandleDrop = async (e: DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    setDragOver(false);

    if (checkFilesExist(e)) {
      const uploadedFile = e?.dataTransfer?.files[0] as unknown as File;
      const isFileValid = validateUploadedFile(uploadedFile);
      if (isFileValid) {
        const data = await convertBase64(uploadedFile);
        onUploadDoc(data);
      }
    }
  };

  const fileSelect = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files && e?.target?.files.length > 0) {
      const selectedFile = e?.target?.files[0] as unknown as File;
      const isFileValid = validateUploadedFile(selectedFile);
      if (isFileValid) {
        const data = await convertBase64(selectedFile);
        onUploadDoc(data);
      }

      e.target.value = '';
    }
  };

  const { dragOver, setDragOver, onDragOver, onDragLeave } = useDragAndDrop();

  return (
    <Fragment>
      <input
        type="file"
        className="d-none"
        id={`file-input-grid-${document.type}-status-${document.status}-id-${document.id}`}
        onChange={fileSelect}
      />

      {document.status === 1 && (
        <DocumentItemGrid
          variant="valider"
          document={document}
          onDownloadDoc={onDownloadDoc}
        />
      )}

      {!document.status && document.status !== 0 && (
        <DocumentItemGrid
          variant="telecharger"
          document={document}
          dragOver={dragOver}
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          onHandleDrop={onHandleDrop}
        />
      )}

      {document.status === 0 && (
        <DocumentItemGrid
          variant="attente"
          document={document}
          onDownloadDoc={onDownloadDoc}
        />
      )}

      {document.status === -1 && (
        <DocumentItemGrid
          variant="refuser"
          document={document}
          onDownloadDoc={onDownloadDoc}
        />
      )}
    </Fragment>
  );
};

export default KycFolderDocumentGrid;
