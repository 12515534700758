import uuid from 'react-uuid';
import i18next from '@/i18n';
import { PropsWithChildren } from 'react';
import './index.scss';

export interface QuestionBoxProps {
  isRejected?: boolean;
  label?: string;
  labelFor?: string;
  additionalClassNames?: string;
}

const QuestionBox = ({
  isRejected = false,
  label = '',
  labelFor = uuid(),
  children,
  additionalClassNames = ''
}: PropsWithChildren<QuestionBoxProps>) => {
  return (
    <div className={`form-component__question-field ${additionalClassNames}`}>
      {label && (
        <label className="text-extra fw-600" htmlFor={labelFor}>
          {label}
        </label>
      )}
      {isRejected && (
        <small className="text-danger">
          {i18next.t('app.answer_rejected')}
        </small>
      )}
      <div className="mt-2">{children}</div>
    </div>
  );
};

export default QuestionBox;
