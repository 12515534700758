import { useTranslation } from 'react-i18next';
import { FaTimesCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';

interface FormHeaderProps {
  step: number;
  name: string;
  isEdit?: boolean;
  clearStartedSubscription: () => void;
}

const FormHeader = ({
  step,
  name,
  isEdit,
  clearStartedSubscription
}: FormHeaderProps) => {
  const { t } = useTranslation();

  const getText = () => {
    if (!isEdit) {
      return [1, 2].includes(step)
        ? t('entites.form.intro._1.add').toString()
        : null;
    }

    if (step === 1) {
      return t('entites.form.intro._1.edit');
    }

    if (step === 2) {
      return t('entites.form.intro._1.edit', { entityName: name });
    }
  };

  return (
    <div className="header-top fw-600 text-extra">
      {getText()}

      <Link
        to={'/space/entites'}
        className="btn_close"
        onClick={clearStartedSubscription}
      >
        <FaTimesCircle color="#012F54" size={20} />
      </Link>
    </div>
  );
};

export default FormHeader;
