import QuestionBox from '@components/Question';
import { getQuestionConfig } from '@containers/FormKYC/getQuestionConfig';
import { CountryType } from '@models/Country';
import { Question } from '@models/Souscription';

export interface QuestionItemProps {
  question: Question;
  countries: CountryType[];
  readonly?: boolean;
  handleQuestionUpdate: (answer: any, id: string, type?: string) => void;
}

const QuestionItem = ({
  question,
  countries,
  readonly,
  handleQuestionUpdate
}: QuestionItemProps) => {
  const { children, ...rest } = getQuestionConfig(
    question,
    countries,
    handleQuestionUpdate,
    readonly
  );

  return <QuestionBox {...rest}>{children}</QuestionBox>;
};

export default QuestionItem;
