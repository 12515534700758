import Investor from '@models/Investor';

export type SignataireForType = {
  firstname: string;
  lastname: string;
  email: string;
  telephone: string;
  title: string;
};

export const signataireInit = {
  firstname: '',
  lastname: '',
  email: '',
  telephone: '',
  title: ''
};

export interface SouscriptionStepperConfigurationProps {
  updateStepProgressState: (value: string) => void;
  investor: Investor;
  setNewInvestor: (investor: Investor) => void;
}
