import { EXTRA } from '@constants/colors';
import { mdiMenu, mdiMenuOpen } from '@mdi/js';
import Icon from '@mdi/react';
import { Fragment, RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import './Header.scss';

interface NavMenuProps {
  countSouscription: number;
  variant: string;
  handleToggleMenu?: () => void;
  toggleMenu?: boolean;
  menuRef?: RefObject<HTMLDivElement>;
}

const NavMenu = ({
  countSouscription = 0,
  variant = 'desktop',
  handleToggleMenu,
  toggleMenu,
  menuRef
}: NavMenuProps) => {
  const { t } = useTranslation();

  return variant === 'desktop' ? (
    <Fragment>
      <div className="header__nav__item">
        <NavLink
          className={(navData) =>
            `header__nav__item__link d-flex align-items-center ${
              navData.isActive ? 'active' : ''
            }`
          }
          to="/space/souscriptions"
        >
          {t('menu.souscription')}

          {countSouscription > 0 && (
            <span className="header__nav__item__badge rounded-circle bg-primary ms-2 fw-600">
              {countSouscription}
            </span>
          )}
        </NavLink>
      </div>
      <div className="header__nav__item">
        <NavLink
          className={(navData) =>
            `header__nav__item__link d-flex align-items-center ${
              navData.isActive ? 'active' : ''
            }`
          }
          to="/space/entites"
        >
          {t('menu.entitesJuridiques')}
        </NavLink>
      </div>
    </Fragment>
  ) : (
    <Fragment>
      <div className="header__nav__menu">
        <div
          className="header__nav__menu__icon cursor-pointer d-flex align-items-center justify-content-center"
          ref={menuRef}
          onClick={handleToggleMenu}
        >
          {toggleMenu ? (
            <Icon path={mdiMenuOpen} size={'2rem'} color={EXTRA} />
          ) : (
            <Icon path={mdiMenu} size={'2rem'} color={EXTRA} />
          )}
        </div>
        <div
          className={`header__nav__menu__container rounded shadow ${
            toggleMenu ? 'd-flex' : 'd-none'
          } flex-column`}
        >
          <NavLink
            className={(navData) =>
              `header__nav__menu__item d-flex align-items-center ${
                navData.isActive ? 'active' : ''
              }`
            }
            to="/space/souscriptions"
          >
            {t('menu.souscription')}
            {countSouscription > 0 && (
              <span className="header__nav__item__badge rounded-circle bg-primary ms-2 fw-600">
                {countSouscription}
              </span>
            )}
          </NavLink>

          <NavLink
            className={(navData) =>
              `header__nav__menu__item ${navData.isActive ? 'active' : ''}`
            }
            to="/space/entites"
          >
            {t('menu.entitesJuridiques')}
          </NavLink>
        </div>
      </div>
    </Fragment>
  );
};

export default NavMenu;
