import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate } from 'react-router-dom';
import { useParams, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AuthService } from '@services';
import { useAppSelector } from '@store';
import { isConnected } from '@store/auth/selectors';

const LoginToken = () => {
  const { t } = useTranslation();
  const { token } = useParams();
  const navigate = useNavigate();

  const [error, setError] = useState(false);

  const isLogging = useAppSelector(isConnected);
  // if (isLogging) return <Navigate to="/space" replace state={null} />;

  const auth = async (authToken: string) => {
    try {
      await AuthService.loginWithToken(authToken);
      navigate('/space', { replace: true, state: null });
    } catch (error) {
      setError(true);
    }
  };

  useEffect(() => {
    if (token) {
      auth(token);
    } else {
      setError(true);
    }
  }, [token]);

  return (
    <div className="h-100 w-100 d-flex align-items-center justify-content-center">
      <Helmet>
        <title>{t('auth.doubleAuth').toString()}</title>
      </Helmet>

      <div className="container px-4">
        <h2 className="text-center mb-4 fs-3">{t('auth.impersonate')} </h2>

        {error && (
          <>
            <p className="text-center fw-300">{t('auth.novalidToken')}</p>

            <div className="d-flex justify-content-center mt-5">
              <Link
                replace
                state={null}
                to="/auth/login"
                className="btn btn-primary"
              >
                {t('titles.logIn')}
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default LoginToken;
