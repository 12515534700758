import { Section } from '@models/Souscription';
import { useTranslation } from 'react-i18next';
import { FaTimesCircle } from 'react-icons/fa';
import Select from '@components/Select';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { ChangeEvent } from 'react';
import {
  ImportFileType,
  UBOUserType
} from '@pages/Space/Souscription/Stepper/Dossier/types';
import { importFileInit } from '@pages/Space/Souscription/Stepper/Dossier/constants';
import InputDocFormTwo from '@components/InputDocFormTwo';
import { CountryType } from '@models/Country';
import InputDoc from '@components/InputDocFormOne';

interface NewDocumentTypeProps {
  showNewDocumentTypeNoIDCard: boolean;
  docType: string;
  selectedImage: ImportFileType;
  uboUserForm: UBOUserType;
  countries: CountryType[];
  showNewDocumentTypeIDCard: boolean;
  setShowBODocumentType: (value: boolean) => void;
  setDocType: (type: string) => void;
  setSelectedImage: (image: ImportFileType) => void;
  setShowDocumentType: (value: boolean) => void;
  setShowNewDocumentTypeNoIDCard: (value: boolean) => void;
  setShowNewDocumentTypeIDCard: (value: boolean) => void;
  handleSubmitNewDocumentType: (event: any, type: string) => void;
  onFileChange: (data: any) => void;
  setUboUserForm: (data: UBOUserType) => void;
}

const NewDocumentTypeEntity = ({
  showNewDocumentTypeNoIDCard,
  docType,
  selectedImage,
  uboUserForm,
  countries,
  showNewDocumentTypeIDCard,
  setShowBODocumentType,
  setDocType,
  setSelectedImage,
  setShowDocumentType,
  setShowNewDocumentTypeNoIDCard,
  setShowNewDocumentTypeIDCard,
  handleSubmitNewDocumentType,
  onFileChange,
  setUboUserForm
}: NewDocumentTypeProps) => {
  const { t } = useTranslation();

  const closeBODocumentNewType = () => {
    setShowBODocumentType(true);
    setShowDocumentType(false);
    setShowNewDocumentTypeNoIDCard(false);
    setShowNewDocumentTypeIDCard(false);
  };

  const handleSelectTypeDocument = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedImage(importFileInit);

    if (e.target.value.toLowerCase() === 'autre') {
      setDocType('');
      setShowNewDocumentTypeNoIDCard(true);
      setShowNewDocumentTypeIDCard(false);
    } else if (e.target.value.toLowerCase() === 'id') {
      setDocType("Pièce d'identité");
      setShowNewDocumentTypeNoIDCard(false);
      setShowNewDocumentTypeIDCard(true);
    }
  };

  const handleUBOChange = (key: string, value: string) =>
    setUboUserForm({ ...uboUserForm, [key]: value });

  return (
    <SimpleBar style={{ height: '55vh' }}>
      <div className="add-document">
        <div className="add-document__header d-flex justify-content-between align-items-center flex-row">
          {t('souscriptions.stepper.step.kyc.form.bo.title')}
          <FaTimesCircle size={20} onClick={closeBODocumentNewType} />
        </div>

        <div className="my-3">
          <div className="row">
            <Select
              lable={t('souscriptions.stepper.step.kyc.form.type.label') || ''}
              classLable="text-extra fw-600"
              classBox="col-md-12"
              onChange={handleSelectTypeDocument}
              defaultValue="id"
            >
              <option value="id">
                {t(
                  'souscriptions.stepper.step.kyc.form.type.options.pieceIdentite'
                )}
              </option>
              <option value="autre">
                {t('souscriptions.stepper.step.kyc.form.type.options.autre')}
              </option>
            </Select>
          </div>
        </div>

        {showNewDocumentTypeNoIDCard && (
          <form onSubmit={(e) => handleSubmitNewDocumentType(e, 'FORM_TWO')}>
            <div className="form-input w-100">
              <label className="form-input__lable mb-1 text-extra fw-600">
                {t('souscriptions.stepper.step.kyc.form.type.options.autre')}{' '}
                <span style={{ opacity: 0.35 }}>
                  ({t('souscriptions.stepper.step.kyc.form.type.options.autre')}
                  )
                </span>
              </label>

              <div className="position-relative">
                <input
                  value={docType}
                  onChange={(e) => setDocType(e.target.value)}
                  style={{ width: '100% !important' }}
                  type="text"
                  className="text-extra"
                  required
                />
              </div>
            </div>

            <div className="w-100 mt-3">
              <label htmlFor="type" className="text-extra fw-600">
                {t('souscriptions.stepper.step.kyc.form.file.label._1')}
              </label>

              <InputDocFormTwo
                onUploadDoc={onFileChange}
                hasFile={selectedImage.name}
              />
            </div>

            {docType === "Pièce d'identité" && (
              <>
                <div className="row mt-3">
                  <div className="form-input mb-4 col-md-6">
                    <label className="form-input__lable mb-1 text-extra fw-600">
                      {t('souscriptions.stepper.step.kyc.form.bo.nom.label')}
                    </label>
                    <div className=" position-relative">
                      <input
                        value={uboUserForm.lastname || ''}
                        placeholder={
                          t(
                            'souscriptions.stepper.step.kyc.form.bo.nom.placeholder'
                          ) || ''
                        }
                        onChange={(e) =>
                          handleUBOChange('lastname', e.target.value)
                        }
                        name="lastname"
                        type="text"
                        className="text-extra"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-input mb-4 col-md-6">
                    <label className="form-input__lable mb-1 text-extra fw-600">
                      {t('souscriptions.stepper.step.kyc.form.bo.prenom.label')}
                    </label>
                    <div className=" position-relative">
                      <input
                        value={uboUserForm.firstname || ''}
                        placeholder={
                          t(
                            'souscriptions.stepper.step.kyc.form.bo.prenom.placeholder'
                          ) || ''
                        }
                        onChange={(e) =>
                          handleUBOChange('firstname', e.target.value)
                        }
                        name="firstname"
                        type="text"
                        className="text-extra"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Select
                      lable={
                        t(
                          'souscriptions.stepper.step.kyc.form.bo.nationalite.label'
                        ) || ''
                      }
                      placeholder={
                        t(
                          'souscriptions.stepper.step.kyc.form.bo.nationalite.placeholder'
                        ) || ''
                      }
                      classLable="text-extra fw-600"
                      classBox=""
                      value={uboUserForm.citizenship || ''}
                      onChange={(e) =>
                        handleUBOChange('citizenship', e.target.value)
                      }
                      name="citizenship"
                      required
                    >
                      {countries.map((country: any, i) => (
                        <option
                          value={(country?.code || '').toUpperCase()}
                          key={i}
                        >
                          {country?.name}
                        </option>
                      ))}
                    </Select>
                  </div>
                  <div className="form-input mb-4 col-md-6">
                    <label className="form-input__lable mb-1 text-extra fw-600">
                      {t(
                        'souscriptions.stepper.step.kyc.form.bo.naissanace.label'
                      )}
                    </label>
                    <div className=" position-relative">
                      <input
                        type="date"
                        placeholder=""
                        value={uboUserForm.birthdate || ''}
                        onChange={(e) =>
                          handleUBOChange('birthdate', e.target.value)
                        }
                        name="birthdate"
                        className="text-extra"
                        required
                      />
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className="d-flex justify-content-center mt-5 gap-3">
              <button type="submit" className="btn btn-sm btn-basic text-white">
                {t('souscriptions.stepper.button.valider')}
              </button>
            </div>
          </form>
        )}

        {showNewDocumentTypeIDCard && (
          <form onSubmit={(e) => handleSubmitNewDocumentType(e, 'FORM_ONE')}>
            <label htmlFor="type" className="text-extra fw-600">
              {t('souscriptions.stepper.step.kyc.form.file.label._2')}
            </label>

            <InputDoc onUploadDoc={onFileChange} hasFile={selectedImage.name} />

            <div className="row mt-3">
              <div className="form-input mb-4 col-md-6">
                <label className="form-input__lable mb-1 text-extra fw-600">
                  {t('souscriptions.stepper.step.kyc.form.bo.nom.label')}
                </label>
                <div className=" position-relative">
                  <input
                    value={uboUserForm.lastname || ''}
                    placeholder={
                      t(
                        'souscriptions.stepper.step.kyc.form.bo.nom.placeholder'
                      ) || ''
                    }
                    onChange={(e) =>
                      handleUBOChange('lastname', e.target.value)
                    }
                    name="lastname"
                    type="text"
                    className="text-extra"
                    required
                  />
                </div>
              </div>

              <div className="form-input mb-4 col-md-6">
                <label className="form-input__lable mb-1 text-extra fw-600">
                  {t('souscriptions.stepper.step.kyc.form.bo.prenom.label')}
                </label>
                <div className="position-relative">
                  <input
                    value={uboUserForm.firstname || ''}
                    placeholder={
                      t(
                        'souscriptions.stepper.step.kyc.form.bo.prenom.placeholder'
                      ) || ''
                    }
                    onChange={(e) =>
                      handleUBOChange('firstname', e.target.value)
                    }
                    name="firstname"
                    type="text"
                    className="text-extra"
                    required
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <Select
                lable={
                  t(
                    'souscriptions.stepper.step.kyc.form.bo.nationalite.label'
                  ) || ''
                }
                placeholder={
                  t(
                    'souscriptions.stepper.step.kyc.form.bo.nationalite.placeholder'
                  ) || ''
                }
                classLable="text-extra fw-600"
                classBox="col-md-6"
                value={uboUserForm.citizenship || ''}
                onChange={(e) => handleUBOChange('citizenship', e.target.value)}
                name="citizenship"
                required
              >
                {countries.map((country: any, i) => (
                  <option value={(country?.code || '').toUpperCase()} key={i}>
                    {country?.name}
                  </option>
                ))}
              </Select>

              <div className="form-input mb-4 col-md-6">
                <label className="form-input__lable mb-1 text-extra fw-600">
                  {t('souscriptions.stepper.step.kyc.form.bo.naissanace.label')}
                </label>
                <div className="position-relative">
                  <input
                    type="date"
                    value={uboUserForm.birthdate || ''}
                    onChange={(e) =>
                      handleUBOChange('birthdate', e.target.value)
                    }
                    name="birthdate"
                    className="text-extra"
                    required
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="form-input mb-4 col-md-6">
                <label className="form-input__lable mb-1 text-extra fw-600">
                  {t(
                    'souscriptions.stepper.step.kyc.form.bo.dateExpirationPièceIdentite.label'
                  )}
                </label>
                <div className="position-relative">
                  <input
                    type="date"
                    value={uboUserForm.expire || ''}
                    onChange={(e) => handleUBOChange('expire', e.target.value)}
                    className="text-extra"
                    required
                  />
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-center mt-5 gap-3">
              <button type="submit" className="btn btn-sm btn-basic text-white">
                {t('souscriptions.stepper.button.valider')}
              </button>
            </div>
          </form>
        )}
      </div>
    </SimpleBar>
  );
};

export default NewDocumentTypeEntity;
