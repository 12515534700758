import { HTMLAttributes } from 'react';

import './Box.scss';

const Box = ({ children, className }: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={`box ${className}`}>
      <div className="box__container card shadow-lg rounded">
        <div className="card-body" id="card-body">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Box;
