import { CountryType } from '@models/Country';
import { Question } from '@models/Souscription';

export const getQuestionPreviousAnswer = (
  key: string,
  questions: Question[]
) => {
  const qst = questions.find((question) => question.id === key);
  return !qst?.answer ? '' : qst.answer;
};

export const getCountry = (name: string, countries: CountryType[]) =>
  countries.find((country) => country.name === name || country.id === +name);

export const refractCountryMultiselectValues = (
  answer: string,
  countries: CountryType[]
) => {
  if (!answer) return [];

  if (typeof answer === 'string') {
    return answer.split(' | ').map((countryName: string) => {
      const country = countries.find((country) => country.name === countryName);
      return {
        label: country?.name || '',
        value: country?.id || ''
      };
    });
  } else {
    return answer;
  }
};

export const refractMultiselectValues = (answer: string) => {
  if (!answer) return [];

  if (typeof answer === 'string') {
    return answer.split(' | ').map((value: string) => {
      return {
        label: value,
        value
      };
    });
  } else {
    return answer;
  }
};
