import InputDocFormTwo from '@components/InputDocFormTwo';
import {
  ImportFileType,
  UBOUserType
} from '@pages/Space/Souscription/Stepper/Dossier/types';
import { useTranslation } from 'react-i18next';
import { FaTimesCircle } from 'react-icons/fa';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

interface NewDocumentTypeProps {
  docType: string;
  selectedImage: ImportFileType;
  uboUserForm: UBOUserType;
  setShowBODocumentType: (value: boolean) => void;
  setDocType: (type: string) => void;
  setShowDocumentType: (value: boolean) => void;
  handleSubmitNewDocumentType: (event: any, type: string) => void;
  onFileChange: (data: any) => void;
}

const NewDocumentTypeBO = ({
  docType,
  selectedImage,
  setShowBODocumentType,
  setDocType,
  setShowDocumentType,
  handleSubmitNewDocumentType,
  onFileChange,
  uboUserForm
}: NewDocumentTypeProps) => {
  const { t } = useTranslation();

  const closeBODocumentNewType = () => {
    setShowBODocumentType(true);
    setShowDocumentType(false);
  };

  return (
    <SimpleBar style={{ height: '55vh' }}>
      <div className="add-document">
        <div className="add-document__header d-flex justify-content-between align-items-center flex-row mb-3">
          {`${t('souscriptions.stepper.step.kyc.form.bo.titleWithBoName')}
           ${uboUserForm.firstname} ${uboUserForm.lastname}`}
          <FaTimesCircle size={20} onClick={closeBODocumentNewType} />
        </div>

        <form onSubmit={(e) => handleSubmitNewDocumentType(e, 'FORM_TWO')}>
          <div className="form-input w-100">
            <label className="form-input__lable mb-1 text-extra fw-600">
              {t('souscriptions.stepper.step.kyc.form.document.title')}
            </label>

            <div className="position-relative">
              <input
                value={docType}
                onChange={(e) => setDocType(e.target.value)}
                style={{ width: '100% !important' }}
                type="text"
                className="text-extra"
                required
              />
            </div>
          </div>

          <div className="w-100 mt-3">
            <label htmlFor="type" className="text-extra fw-600">
              {t('souscriptions.stepper.step.kyc.form.file.label._1')}
            </label>

            <InputDocFormTwo
              onUploadDoc={onFileChange}
              hasFile={selectedImage.name}
            />
          </div>

          {/* {docType === "Pièce d'identité" && (
            <>
              <div className="row mt-3">
                <div className="form-input mb-4 col-md-6">
                  <label className="form-input__lable mb-1 text-extra fw-600">
                    {t('souscriptions.stepper.step.kyc.form.bo.nom.label')}
                  </label>
                  <div className=" position-relative">
                    <input
                      value={uboUserForm.lastname || ''}
                      placeholder={
                        t(
                          'souscriptions.stepper.step.kyc.form.bo.nom.placeholder'
                        ) || ''
                      }
                      onChange={(e) =>
                        handleUBOChange('lastname', e.target.value)
                      }
                      name="lastname"
                      type="text"
                      className="text-extra"
                      required
                    />
                  </div>
                </div>
                <div className="form-input mb-4 col-md-6">
                  <label className="form-input__lable mb-1 text-extra fw-600">
                    {t('souscriptions.stepper.step.kyc.form.bo.prenom.label')}
                  </label>
                  <div className=" position-relative">
                    <input
                      value={uboUserForm.firstname || ''}
                      placeholder={
                        t(
                          'souscriptions.stepper.step.kyc.form.bo.prenom.placeholder'
                        ) || ''
                      }
                      onChange={(e) =>
                        handleUBOChange('firstname', e.target.value)
                      }
                      name="firstname"
                      type="text"
                      className="text-extra"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Select
                    lable={
                      t(
                        'souscriptions.stepper.step.kyc.form.bo.nationalite.label'
                      ) || ''
                    }
                    placeholder={
                      t(
                        'souscriptions.stepper.step.kyc.form.bo.nationalite.placeholder'
                      ) || ''
                    }
                    classLable="text-extra fw-600"
                    classBox=""
                    value={uboUserForm.citizenship || ''}
                    onChange={(e) =>
                      handleUBOChange('citizenship', e.target.value)
                    }
                    name="citizenship"
                    required
                  >
                    {countries.map((country: any, i) => (
                      <option
                        value={(country?.code || '').toUpperCase()}
                        key={i}
                      >
                        {country?.name}
                      </option>
                    ))}
                  </Select>
                </div>
                <div className="form-input mb-4 col-md-6">
                  <label className="form-input__lable mb-1 text-extra fw-600">
                    {t(
                      'souscriptions.stepper.step.kyc.form.bo.naissanace.label'
                    )}
                  </label>
                  <div className=" position-relative">
                    <input
                      type="date"
                      placeholder=""
                      value={uboUserForm.birthdate || ''}
                      onChange={(e) =>
                        handleUBOChange('birthdate', e.target.value)
                      }
                      name="birthdate"
                      className="text-extra"
                      required
                    />
                  </div>
                </div>
              </div>
            </>
          )} */}

          <div className="d-flex justify-content-center mt-5 gap-3">
            <button type="submit" className="btn btn-sm btn-basic text-white">
              {t('souscriptions.stepper.button.valider')}
            </button>
          </div>
        </form>
      </div>
    </SimpleBar>
  );
};

export default NewDocumentTypeBO;
