import { FaTimesCircle } from 'react-icons/fa';
import './index.scss';

type FormKycHeaderProps = {
  title: string;
  onClose: () => void;
};

const FormKycHeader = ({ title, onClose }: FormKycHeaderProps) => {
  return (
    <div className="form-component__header">
      <span className="form-component__header__title">{title}</span>
      <span className="form-component__header__icon">
        <FaTimesCircle size={20} onClick={onClose} />
      </span>
    </div>
  );
};

export default FormKycHeader;
