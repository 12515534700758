import { maxDate, year } from '@pages/Space/constants';

export const adjustDate = (date: string) => {
  if (!date || date.length === 0) return maxDate;
  const dateArray = date.split('-');
  return `${year}-${dateArray[1]}-${dateArray[2]}`;
};

export const togglePassWord = (element: any) => {
  if (element.current.type === 'text') {
    element.current.type = 'password';
  } else {
    element.current.type = 'text';
  }
};
