import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams, useNavigate } from 'react-router-dom';
import { BiRefresh } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';

import { InvestorService, SouscriptionService } from '@services';
import Souscription from '@models/Souscription';
import { useAppSelector } from '@store';
import { WHITE } from '@constants/colors';
import Title from '@components/Title';
import SouscriptionStepperConfiguration from './Configuration';
import SouscriptionStepperDossier from './Dossier';
import SouscriptionStepperValidated from './Validated';
import SouscriptionStepperSignature from './Signature';
import './Stepper.scss';
import {
  getLoadingOngoing,
  getOngoinSouscriptions
} from '@store/souscription/selectors';
import Investor from '@models/Investor';

const SouscriptionStepper = () => {
  const { id, step } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const loadingOngoing = useAppSelector(getLoadingOngoing);

  const [configurationState, setConfigurationState] = useState({
    show: false,
    active: false,
    evolition: '',
    pointed: false
  });
  const [dossierState, setDossierState] = useState({
    show: false,
    active: false,
    evolition: '',
    pointed: false
  });
  const [validateState, setValidateState] = useState(false);
  const [signatureState, setSignatureState] = useState({
    show: false,
    active: false,
    evolition: '',
    pointed: false
  });

  const [lineState, setLineState] = useState('');

  const [souscription, setSouscription] = useState<Souscription | null>(null);
  const [newInvestor, setNewInvestor] = useState<Investor | null>(null);

  useEffect(() => {
    switchStep(step || '');
  }, [step, souscription]);

  const switchStep = (step: string) => {
    console.log(souscription, step);

    if (souscription) {
      switch (step) {
        case 'configuration':
          setConfigurationState({
            show: true,
            active: true,
            evolition: 'active--50',
            pointed: true
          });
          setDossierState({
            show: false,
            active: false,
            evolition: '',
            pointed: false
          });
          setSignatureState({
            show: false,
            active: false,
            evolition: '',
            pointed: false
          });
          setValidateState(false);
          setLineState('progress--25');
          break;
        case 'dossiers':
          if (souscription.status.id.includes('5')) {
            setConfigurationState({
              show: false,
              active: true,
              evolition: 'active--100',
              pointed: true
            });
            setDossierState({
              show: true,
              active: true,
              evolition: 'active--100',
              pointed: true
            });
            setSignatureState({
              show: false,
              active: false,
              evolition: '',
              pointed: false
            });
            setValidateState(false);
            setLineState('progress--50');
            return;
          }
          setConfigurationState({
            show: false,
            active: true,
            evolition: 'active--100',
            pointed: true
          });
          setDossierState({
            show: true,
            active: true,
            evolition: 'active--25',
            pointed: true
          });
          setSignatureState({
            show: false,
            active: false,
            evolition: '',
            pointed: false
          });
          setValidateState(false);
          setLineState('progress--50');
          break;
        case 'signature':
          setConfigurationState({
            show: false,
            active: true,
            evolition: 'active--100',
            pointed: false
          });
          setDossierState({
            show: false,
            active: true,
            evolition: 'active--100',
            pointed: false
          });
          setSignatureState({
            show: true,
            active: true,
            evolition: 'active--50',
            pointed: true
          });
          break;
        default:
          break;
      }
    }
  };

  const loadSubscription = async () => {
    const subscription = (await SouscriptionService.ongoing(
      id ?? ''
    )) as Souscription;

    setSouscription(subscription);
  };

  useEffect(() => {
    if (id) {
      loadSubscription();
    }
  }, [id, dossierState]);

  const updateDossierProgressState = (value: string) => {
    setDossierState((prev) => {
      return {
        ...prev,
        evolition: `active--${value}`
      };
    });
  };

  const updateConfigurationProgressState = (value: string) => {
    setConfigurationState((prev) => {
      return {
        ...prev,
        evolition: `active--${value}`
      };
    });
  };

  const updateSignatureProgressState = (value: string) => {
    setSignatureState((prev) => {
      return {
        ...prev,
        evolition: `active--${value}`
      };
    });
  };

  const goToStep = (step: string, state: boolean): void => {
    if (state) {
      navigate(`/space/souscriptions/${id}/stepper/${step}`, { replace: true });
    }
  };

  return loadingOngoing ? (
    <div className="souscriptions-list__container loard__container">
      <div className="d-flex justify-content-center align-items-center">
        <span className="load load--primary load--rote">
          <BiRefresh size={40} color={WHITE} />
        </span>
      </div>
    </div>
  ) : (
    <div className="souscriptions-stepper">
      <Helmet>
        {souscription ? (
          <title>
            {t('souscriptions.stepper.title', {
              fund: souscription?.fund.name,
              investor: souscription?.investor.name
            })}
            {/* {`Souscription en cours : ${souscription?.fund.name} via ${souscription?.investor.name}`} */}
          </title>
        ) : (
          <title>{t('souscriptions.stepper.defaultTitle')}</title>
        )}
      </Helmet>

      {souscription ? (
        <div className="souscriptions-stepper__content container px-3">
          <Title
            className="souscriptions-stepper__header"
            title={
              t('souscriptions.stepper.title', {
                fund: souscription?.fund.name,
                investor: newInvestor?.name || souscription?.investor.name
              }) || ''
            }
          />

          <div className={`souscriptions-stepper__step-bar ${lineState}`}>
            <div className="souscriptions-stepper__step--dot">
              <div className="dot"></div>
            </div>
            <div
              onClick={() =>
                goToStep('configuration', configurationState.pointed)
              }
              className={`souscriptions-stepper__step ${
                configurationState.pointed ? 'pointer' : ''
              } ${configurationState.active ? 'active' : ''} ${
                configurationState.evolition
              }`}
            >
              <span>{t('souscriptions.stepper.step.configuration.tab')}</span>
            </div>
            <div
              onClick={() => goToStep('dossiers', dossierState.pointed)}
              className={`souscriptions-stepper__step ${
                dossierState.pointed ? 'pointer' : ''
              } ${dossierState.active ? 'active' : ''} ${
                dossierState.evolition
              }`}
            >
              <span>{t('souscriptions.stepper.step.kyc.tab')}</span>
            </div>
            <div
              onClick={() => goToStep('signature', signatureState.pointed)}
              className={`souscriptions-stepper__step ${
                signatureState.pointed ? 'pointer' : ''
              } ${signatureState.active ? 'active' : ''} ${
                signatureState.evolition
              }`}
            >
              <span>{t('souscriptions.stepper.step.signature.tab')}</span>
            </div>
          </div>

          {configurationState.show && (
            <SouscriptionStepperConfiguration
              investor={souscription?.investor}
              updateStepProgressState={updateConfigurationProgressState}
              setNewInvestor={setNewInvestor}
            />
          )}

          {dossierState.show && (
            <SouscriptionStepperDossier
              updateStepProgressState={updateDossierProgressState}
              investor={souscription?.investor.id || ''}
              fund={souscription?.fund.id || ''}
            />
          )}

          {validateState && (
            <SouscriptionStepperValidated
              fund_id={souscription?.fund.id || ''}
            />
          )}

          {signatureState.show && (
            <SouscriptionStepperSignature
              updateStepProgressState={updateSignatureProgressState}
            />
          )}
        </div>
      ) : (
        <div className="souscriptions-list__container__empty">
          <div className="text-center fs-3 fw-600 text-center">
            {t('souscriptions.stepper.notFound')}
          </div>
        </div>
      )}
    </div>
  );
};

export default SouscriptionStepper;
