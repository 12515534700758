import InputDocFormTwo from '@components/InputDocFormTwo';
import { ImportFileType } from '@pages/Space/Souscription/Stepper/Dossier/types';
import { useTranslation } from 'react-i18next';
import { FaTimesCircle } from 'react-icons/fa';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

interface AddEnterpriseDocumentProps {
  docType: string;
  selectedImage: ImportFileType;
  resetForms: () => void;
  setDocType: (type: string) => void;
  handleSubmitNewDocumentType: (event: any, type: string) => void;
  onFileChange: (data: any) => void;
}

const AddEnterpriseDocument = ({
  docType,
  selectedImage,
  resetForms,
  setDocType,
  handleSubmitNewDocumentType,
  onFileChange
}: AddEnterpriseDocumentProps) => {
  const { t } = useTranslation();

  return (
    <SimpleBar style={{ height: '52vh' }}>
      <div className="add-document">
        <div className="add-document__header mb-3 d-flex justify-content-between align-items-center flex-row">
          {t('souscriptions.stepper.step.kyc.form.entreprise.title')}
          <FaTimesCircle size={20} onClick={resetForms} />
        </div>

        <form
          onSubmit={(e) => handleSubmitNewDocumentType(e, 'ENTERPRISE_FORM')}
        >
          <div className="form-input w-100">
            <label className="form-input__lable mb-1 text-extra fw-600">
              {t('souscriptions.stepper.step.kyc.form.document.label')}
            </label>
            <div className="position-relative">
              <input
                value={docType}
                onChange={(e) => setDocType(e.target.value)}
                style={{ width: '100% !important' }}
                type="text"
                className="undefined text-extra false false"
                required
              />
            </div>
          </div>

          <div className="w-100 mt-3">
            <label htmlFor="type" className="text-extra fw-600">
              {t('souscriptions.stepper.step.kyc.form.file.label._1')}
            </label>
            <InputDocFormTwo
              onUploadDoc={onFileChange}
              hasFile={selectedImage.name}
            />
          </div>

          <div className="d-flex justify-content-center w-100 mt-4">
            <button type="submit" className="btn btn-sm btn-basic text-white">
              {t('souscriptions.stepper.button.valider')}
            </button>
          </div>
        </form>
      </div>
    </SimpleBar>
  );
};

export default AddEnterpriseDocument;
