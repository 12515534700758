import { File } from '@/core/types/file';
import Input from '@components/Input';
import PhoneNumberInput from '@components/PhoneNumberInput';
import Select from '@components/Select';
import Title from '@components/Title';
import { Box } from '@containers';
import { UserForm as UserFormType } from '@models/User';
import { togglePassWord } from '@pages/Space/helpers';
import { barColors } from '@pages/Space/User/constants';
import { initialUser } from '@pages/Space/User/Form/constants';
import { DocumentService, InvestorService, UserService } from '@services';
import { useAppSelector } from '@store';
import { getInitialInvestors } from '@store/investor/selectors';
import { convertBase64 } from '@utils/functions';
import { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import PasswordStrengthBar from 'react-password-strength-bar';
import { Link, useNavigate, useParams } from 'react-router-dom';
import './Form.scss';

const UserForm = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const [editForm, setEditForm] = useState(false);
  const [uploadFile, setUploadFile] = useState({
    base64: '',
    name: ''
  });
  const [entity, setEntity] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [user, setUser] = useState<UserFormType>(initialUser);

  const refPassword = useRef<HTMLInputElement>(null);
  const inputFile = useRef<any>(null);

  const entites = useAppSelector(getInitialInvestors);

  const handleImageUpload = async (files: FileList | null) => {
    if (files && files.length > 0) {
      const formData = new FormData();
      formData.append('file', files[0]);

      const data: any = await DocumentService.passport(formData);
      if (data.Country) {
        // s'il existe une cle country dans data
        setUser((step) => {
          return {
            ...step,
            firstname: data.nom,
            lastname: data.prenom
          };
        });
      }

      const { base64, name } = await convertBase64(files[0] as unknown as File);
      setUploadFile({
        base64,
        name
      });
      setUser({
        ...user,
        identity: base64,
        identity_filename: name
      });
    }
  };

  const handleFormChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setUser({
      ...user,
      [event.target.name]: event.target.value
    });
  };

  const handleChangeInvestor = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const iv = entites.find((e) => e.id === event.target.value);
    if (iv) {
      setEntity(iv.id);
      setUser({
        ...user,
        investor: {
          id: iv.id || '',
          name: iv.name || ''
        }
      });
    }
  };

  const handleFormChangePhoneNumber = (key: string, value: string) => {
    setUser((prev) => {
      return {
        ...prev,
        [key]: value
      };
    });
  };

  const handleFormSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (inputFile.current?.value || user.identity) {
      delete user.identity_thumbnail;
      const data = await (!editForm
        ? UserService.store(user, entity)
        : UserService.update(user, id || ''));

      if (data.status === 'success' || data.data.status === 'success') {
        if (editForm) {
          navigate(`/space/users`);
        } else {
          setUser(initialUser);
          navigate(`/space/users`, { replace: true });
        }
      }
    } else {
      const toastLoading = toast.loading(t('toast.loading'));
      toast.error(t('toast.addIDCard').toString(), {
        id: toastLoading
      });

      window.setTimeout(() => {
        toast.dismiss(toastLoading);
      }, 2000);
    }
  };

  useEffect(() => {
    if (entites.length <= 0) {
      InvestorService.load();
    }

    if (id) {
      const loadUser = async () => {
        setEditForm(true);
        const data = await UserService.show(id);
        setUser({
          ...user,
          lastname: data?.lastname || '',
          firstname: data?.firstname || '',
          email: data.email,
          telephone: data.telephone,
          role: data.role,
          identity: data.identity,
          identity_thumbnail: data.identity_thumbnail,
          investor: data.investor
        });
      };

      loadUser();
    } else {
      setEditForm(false);
    }
  }, [id]);

  return (
    <div className="user-form">
      <Helmet>
        <title>
          {editForm
            ? t('titles.modifyUser').toString() +
              `: ${user?.lastname} ${user?.firstname}`
            : t('titles.newUser').toString()}
        </title>
      </Helmet>

      <div className="user-form__container container px-3">
        <Title
          title={
            editForm
              ? t('titles.modifyUser').toString() +
                `: ${user?.lastname} ${user?.firstname}`
              : t('titles.newUser').toString()
          }
        />

        <Box>
          <form onSubmit={handleFormSubmit} className="user-form__form p-3">
            <div className="row gx-md-5">
              <div className="col-md-6">
                <Input
                  lable={t('users.firstname').toString()}
                  placeholder={t('users.placeholders._firstname').toString()}
                  required
                  value={user?.lastname}
                  classInput="text-extra"
                  classLable="text-extra fw-600"
                  classBox="mb-4"
                  name="lastname"
                  onChange={handleFormChange}
                />
                <Select
                  lable={t('users.role').toString()}
                  placeholder={t('users.placeholders._role').toString()}
                  required
                  value={user?.role}
                  classLable="text-extra fw-600"
                  classBox="mb-4"
                  name="role"
                  onChange={handleFormChange}
                  defaultValue="manager"
                >
                  <option value="user">{t('users.idStatus.role._user')}</option>
                  <option value="manager">
                    {t('users.idStatus.role._admin')}
                  </option>
                </Select>
                <div className="form-group">
                  <label className="text-extra fw-600 mb-1">
                    {t('users.idCard')}
                  </label>
                  <div className="user-form__form__cni__container position-relative w-100">
                    <label
                      htmlFor="user-form-id-card"
                      className={`user-form__form__cni__box card upload cursor-pointer`}
                    >
                      {uploadFile.base64 ? (
                        <img src={uploadFile.base64} alt="Thumb" />
                      ) : (
                        <img src={user.identity_thumbnail} alt="Thumb" />
                      )}
                    </label>
                  </div>
                  <input
                    ref={inputFile}
                    name="fille"
                    type="file"
                    id="user-form-id-card"
                    className="d-none"
                    onChange={(e) => handleImageUpload(e.target.files)}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <Input
                  lable={t('users.lastname').toString()}
                  placeholder={t('users.placeholders._lastname').toString()}
                  required
                  value={user?.firstname}
                  classLable="text-extra fw-600"
                  classBox="mb-4"
                  name="firstname"
                  onChange={handleFormChange}
                />
                <Input
                  lable={t('users.email').toString()}
                  placeholder={t('users.placeholders._email').toString()}
                  required
                  type="email"
                  value={user?.email}
                  classLable="text-extra fw-600"
                  classBox="mb-4"
                  name="email"
                  onChange={handleFormChange}
                />

                <div className="mb-4">
                  <PhoneNumberInput
                    required
                    lable={t('users.phone').toString()}
                    placeholder={t('users.placeholders._phone').toString()}
                    value={user?.telephone || ''}
                    classInput="text-extra"
                    classLable="text-extra fw-600"
                    onChangeValue={(value) =>
                      handleFormChangePhoneNumber('telephone', value)
                    }
                  />
                </div>

                <Select
                  lable={t('users.select._label').toString()}
                  value={user?.investor?.id || ''}
                  classLable="text-extra fw-600"
                  classBox="mb-4"
                  name="role"
                  onChange={handleChangeInvestor}
                >
                  <option>{t('users.placeholders._entity').toString()}</option>
                  {entites.map((element) => (
                    <option
                      key={`investor-item-${element.id}`}
                      value={element.id}
                    >
                      {element.name}
                    </option>
                  ))}
                </Select>

                {!editForm && (
                  <div className="user-form__form__password-box mb-4">
                    <label htmlFor="password" className="text-extra fw-600">
                      {t('users.placeholders._password').toString()}
                    </label>
                    <div className="form__field">
                      <input
                        ref={refPassword}
                        placeholder={t(
                          'users.placeholders._password'
                        ).toString()}
                        required
                        type="password"
                        value={user?.password}
                        className="mb-1"
                        minLength={8}
                        pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#_?!@$%^&*-]).{8,}"
                        title={t('users.placeholders._title').toString()}
                        name="password"
                        onChange={handleFormChange}
                      />
                      {showPassword ? (
                        <span
                          onClick={() => {
                            setShowPassword(!showPassword);
                            togglePassWord(refPassword);
                          }}
                          className="form__icon"
                        >
                          <FaEye />
                        </span>
                      ) : (
                        <span
                          onClick={() => {
                            setShowPassword(!showPassword);
                            togglePassWord(refPassword);
                          }}
                          className="form__icon"
                        >
                          <FaEyeSlash />
                        </span>
                      )}
                    </div>
                    <PasswordStrengthBar
                      password={user?.password}
                      minLength={8}
                      barColors={barColors}
                      shortScoreWord={t('users.securityLevel._veryShort')}
                      scoreWords={[
                        t('users.securityLevel._weak'),
                        t('users.securityLevel._medium'),
                        t('users.securityLevel._strong'),
                        t('users.securityLevel._veryStrong')
                      ]}
                      style={{ marginTop: '10px' }}
                      scoreWordStyle={{
                        color: '#496075',
                        fontSize: '12px',
                        fontWeight: 'bold'
                      }}
                    />
                  </div>
                )}
              </div>

              <div className="col-md-12 mt-4">
                <div className="user-form__form__buttons row justify-content-end">
                  <div className="col-md-6 d-flex justify-content-end">
                    <div></div>
                    <Link to="/space/users" className="btn btn-accent">
                      {t('users.buttons._cancel')}
                    </Link>
                    <span style={{ margin: '0 .4rem' }}></span>
                    <button type="submit" className="btn btn-primary-second">
                      {t('users.buttons._validate')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Box>
      </div>
    </div>
  );
};

export default UserForm;
