import { useTranslation } from 'react-i18next';
import './Configuration.scss';

export default function ThanksMessage() {
  const { t } = useTranslation();

  return (
    <div className="souscriptions-stepper-configuration__thanks d-flex align-items-center justify-content-center">
      <div className="souscriptions-stepper-configuration__done">
        <div className="souscriptions-stepper-configuration__done__title text-center">
          {t('souscriptions.stepper.step.configuration.done.thanks')}
        </div>
        <div className="souscriptions-stepper-configuration__done__lable text-center">
          {t('souscriptions.stepper.step.configuration.done.messages._1')}{' '}
          <br />{' '}
          {t('souscriptions.stepper.step.configuration.done.messages._2')}
        </div>
      </div>
    </div>
  );
}
