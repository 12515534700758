/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { InputHTMLAttributes } from 'react';
import uuid from 'react-uuid';
import NumberFormat, { NumberFormatValues } from 'react-number-format';

import './InputNumber.scss';

interface InputNumberProps extends InputHTMLAttributes<HTMLInputElement> {
  id?: string;
  lable?: string;
  icon?: any;
  value?: string | number;
  classInput?: string;
  classLable?: string;
  classBox?: string;
  classIcon?: string;
  numberSign?: string;
  type?: 'text' | 'password' | 'tel';
  error?: {
    state: boolean;
    message: string;
  };
  onChangeValue: (value: string) => void;
}

const InputNumber = ({
  id = uuid(),
  classLable = '',
  classInput = '',
  classBox = 'mb-4',
  classIcon = '',
  numberSign = '€',
  lable,
  icon,
  type = 'text',
  placeholder = '',
  error = { state: false, message: '' },
  value = '',
  required = false,
  onChangeValue = () => {}
}: InputNumberProps) => {
  const reformatValue = (values: NumberFormatValues) => {
    const { value } = values;
    onChangeValue(value);
  };

  return (
    <div className={`form-input ${classBox}`}>
      {lable && (
        <label
          htmlFor={id}
          className={`form-input__lable fw-500 mb-1 ${classLable}`}
        >
          {lable}
        </label>
      )}
      <div className="form-input__container position-relative">
        <NumberFormat
          value={value}
          decimalSeparator="."
          displayType="input"
          type={type}
          id={id}
          className={`${icon ? 'form-input__control' : ''} form-control ${
            error.state && 'is-invalid'
          } ${classInput}`}
          thousandSeparator={' '}
          allowNegative={true}
          onValueChange={reformatValue}
          required={required}
          placeholder={placeholder}
        />

        {icon && !error.state && (
          <span className={`form-input__icon ${classIcon}`}>{icon}</span>
        )}

        <div className="euro">{numberSign}</div>
      </div>

      {error.message && (
        <small className="form-input__error-text form-text text-muted text-danger">
          {error.message}
        </small>
      )}
    </div>
  );
};

export default InputNumber;
