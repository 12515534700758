import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
// import DocViewer from "react-doc-viewer";
import { BiRefresh } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import 'simplebar/dist/simplebar.min.css';

import { InitierSouscriptionItem, SouscriptionItem } from '@containers';
import Title from '@components/Title';
import { SouscriptionService } from '@services';
import { useAppSelector } from '@store';
import { WHITE } from '@constants/colors';
import './List.scss';
import {
  getOngoinSouscriptions,
  getPraparedSouscriptions
} from '@store/souscription/selectors';

const SouscriptionList = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);

  const preparedSouscriptions = useAppSelector(getPraparedSouscriptions);
  const ongoingSouscriptions = useAppSelector(getOngoinSouscriptions);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      setLoading(true);
      await SouscriptionService.reload();
    } finally {
      setLoading(false);
    }
  };

  const refreshData = async () => {
    try {
      setRefreshing(true);
      await SouscriptionService.refresh();
    } finally {
      setRefreshing(false);
    }
  };

  return loading ? (
    <div className="souscriptions-list__container loard__container">
      <div className="d-flex justify-content-center align-items-center">
        <span className="load load--primary load--rote">
          <BiRefresh size={40} color={WHITE} />
        </span>
      </div>
    </div>
  ) : (
    <div>
      <Helmet>
        <title>{t('titles.souscriptions')}</title>
      </Helmet>

      {preparedSouscriptions.length > 0 || ongoingSouscriptions.length > 0 ? (
        <div className="souscriptions-list__container px-3">
          {/* <DocViewer documents={docs} /> */}

          {preparedSouscriptions.length > 0 && (
            <Title title={t('souscriptions.newToInit').toString()} />
          )}

          <div className="souscriptions-list__init mt-2 mb-2 ">
            {preparedSouscriptions.length > 0 &&
              preparedSouscriptions.map((souscription, index) => (
                <InitierSouscriptionItem key={index} data={souscription} />
              ))}
          </div>

          {ongoingSouscriptions.length > 0 && (
            <div className="text-center mb-2 title d-flex align-items-center justify-content-end principalDiv">
              <Title
                className="mb-0 text-center"
                title={t('souscriptions.enCours').toString()}
              />

              <button
                onClick={refreshData}
                title="Refresh"
                type="button"
                className={`pl-3 block ${refreshing ? 'load--rote' : ''}`}
                style={{
                  padding: '1.2rem',
                  position: 'absolute',
                  right: 0,
                  outline: 'none',
                  backgroundColor: 'transparent',
                  border: 'none'
                }}
              >
                <BiRefresh size={30} color={WHITE} />
              </button>
            </div>
          )}

          <div className="souscriptions-list__items">
            {ongoingSouscriptions.length > 0 &&
              ongoingSouscriptions.map((souscription, index) => (
                <SouscriptionItem key={index} data={souscription} />
              ))}
          </div>
        </div>
      ) : (
        <div className="souscriptions-list__container__empty">
          <div className="text-center fs-3 fw-600 text-center">
            {t('souscriptions.none')}
          </div>
        </div>
      )}
    </div>
  );
};

export default SouscriptionList;
