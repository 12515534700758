import { InputHTMLAttributes, useEffect, useState } from 'react';
import Icon from '@mdi/react';
import { mdiEye, mdiEyeOff } from '@mdi/js';
import uuid from 'react-uuid';

import { DANGER, EXTRA } from '@constants/colors';
import './Input.scss';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  id?: string;
  lable?: string;
  icon?: any;
  password?: boolean;
  classInput?: string;
  classLable?: string;
  classBox?: string;
  classIcon?: string;
  error?: {
    state: boolean;
    message: string;
  };
}

const Input = ({
  id = uuid(),
  classLable = '',
  classInput = '',
  classBox = 'mb-4',
  classIcon = '',
  password = false,
  lable,
  icon,
  type = 'text',
  placeholder = '',
  error = { state: false, message: '' },
  ...args
}: InputProps) => {
  const [inputType, setInputType] = useState(type);

  useEffect(() => {
    setInputType(type);
  }, [type]);

  const togglePassWord = () => {
    if (inputType === 'text') {
      setInputType('password');
    } else {
      setInputType('text');
    }
  };

  return (
    <div className={`form-input ${classBox}`}>
      {lable && (
        <label htmlFor={id} className={`form-input__lable mb-1 ${classLable}`}>
          {lable}
        </label>
      )}
      <div className="form-input__container position-relative">
        <input
          type={inputType}
          id={id}
          placeholder={placeholder}
          className={`${
            icon ? 'form-input__control' : ''
          } form-control ${classInput} ${error.state ? 'is-invalid' : ''} ${
            password ? 'form-input__control__password' : ''
          }`}
          {...args}
        />

        {icon && !password && !error.state && (
          <span className={`form-input__icon ${classIcon}`}>{icon}</span>
        )}

        {password && (
          <span
            onClick={togglePassWord}
            className={`form-input__icon cursor-pointer ${classIcon}`}
          >
            {inputType === 'password' ? (
              <Icon
                path={mdiEye}
                size={'1.2rem'}
                color={error.state ? DANGER : EXTRA}
              />
            ) : (
              <Icon
                path={mdiEyeOff}
                size={'1.2rem'}
                color={error.state ? DANGER : EXTRA}
              />
            )}
          </span>
        )}
      </div>

      {error.message && (
        <small className="form-input__error-text form-text text-danger">
          {error.message}
        </small>
      )}
    </div>
  );
};

export default Input;
