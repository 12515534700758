import { useTranslation } from 'react-i18next';
import './Configuration.scss';

export interface TitleProps {
  step: number;
  name: string;
}

export default function Title({ step, name }: TitleProps) {
  const { t } = useTranslation();

  if (![0, 1].includes(step)) {
    return null;
  }

  return (
    <div className="header-top fw-600 text-extra">
      {step === 0 &&
        t('souscriptions.stepper.step.configuration.form.entite.title')}
      {step === 1 &&
        t('souscriptions.stepper.step.configuration.form.signer.title', {
          investor: name
        })}
    </div>
  );
}
