import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import CookieConsent from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';

import AppRouting from './routers';
import './App.scss';
import { useEffect } from 'react';
import CountryNames from 'i18n-iso-countries';
import en from 'i18n-iso-countries/langs/en.json';
import fr from 'i18n-iso-countries/langs/fr.json';
CountryNames.registerLocale(en);
CountryNames.registerLocale(fr);

const App = () => {
  const { t } = useTranslation();
  const { toasts } = useToasterStore();

  useEffect(() => {
    if (toasts?.length > 1) {
      if (toasts[0].type === 'loading') {
        toasts.filter((_, i) => i > 0).forEach((t) => toast.dismiss(t.id));
      }
    }
  }, [toasts.length]);

  return (
    <div className="app">
      <div className="app__content">
        <AppRouting />
      </div>

      <div className="footer text-center w-100">
        <div className="footer__background">
          <svg
            width="1000"
            id="svg_bottom"
            style={{ fill: '#269999' }}
            data-name="svg_bottom"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 617 66.6"
          >
            <title>pivotSVG</title>
            <path
              className="cls-1"
              d="M55.89,116.77c3-2.74,6.12-5.47,9-8.22,10.46-9.85,22.27-19.49,39.62-28.31,18.27-9.3,40.81-17.16,71.14-22.37A422.4,422.4,0,0,1,233,52.3c11.81-.4,23.65-.38,35.49-.38H470.21c24.08,0,47.46,1.14,69.68,4.29,33,4.66,57.73,12.4,77.65,22a156,156,0,0,1,38.67,25.68c4.84,4.42,9.65,8.84,14.5,13.26l.82.76c.68.69-1.16.63-1.41.64a12.07,12.07,0,0,0-1.07-1.08c-5.5-5-10.83-10.13-16.56-15.16-10.94-9.63-24.55-18.82-43.68-27.06-35.13-15.13-81.2-22.88-140-22.69-70.18.22-140.35,0-210.53,0-7.46,0-14.91,0-22.37.26-30.8.91-58.5,4.36-83,10.55C129,69.46,111.2,77.21,96.6,85.8,79,96.16,68.29,107.44,56.11,118.52c-2.16-.22-1.42-.55-.71-1.24"
              transform="translate(-54.68 -51.92)"
            />
          </svg>
        </div>
        <p className="text-white">{t('eurazeo.disclosure')}</p>
        <p className="text-white fs-7">Copyright Eurazeo @ 2022</p>
      </div>

      <CookieConsent
        cookieName="eurazeo.takeon.rgpd.cookie"
        disableStyles={true}
        location="bottom"
        buttonClasses="btn btn-primary"
        containerClasses="d-flex justify-content-between bg-duck p-4 w-100"
        style={{ zIndex: 5, position: 'absolute' }}
        buttonText={t('cookies.accept')}
        expires={999}
        overlay
      >
        <span className="fs-5">{t('cookies.use')}.</span>
        <br />
        <span className="fs-9">{t('cookies.navigation')}.</span>
      </CookieConsent>

      <Toaster
        position="bottom-right"
        reverseOrder={true}
        toastOptions={{
          success: {
            duration: 4000,
            style: {
              background: '#198754',
              color: '#fff'
            }
          },
          error: {
            duration: 10000,
            iconTheme: {
              primary: '#fff',
              secondary: '#9e1132'
            },
            style: {
              background: '#9e1132',
              color: '#fff'
            }
          }
        }}
      />
    </div>
  );
};

export default App;
