import QuestionItem from '@containers/FormKYC/QuestionItem';
import { CountryType } from '@models/Country';
import { Question, QuestionForm, Section } from '@models/Souscription';
import {
  canShowQuestion,
  filterVisibleQuestions,
  formatTableQuestions,
  hasValueInQuestion,
  mapVisibleQuestions,
  transformQuestionValues
} from '@utils/functions';
import { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsFillPlusCircleFill } from 'react-icons/bs';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import FormKycHeader from './FormKycHeader';
import { getCountry, getQuestionPreviousAnswer } from './helpers';
import './index.scss';

type FormKycProps = {
  countries?: CountryType[];
  formulaire: Section;
  setShowFormulaQuestions: (condition: boolean) => void;
  setShowNewBOFormModal?: (condition: boolean) => void;
  updateSectionQuestions: (e: FormEvent, data: any[]) => void;
  readonly?: boolean;
};

const FormKYC = ({
  countries = [],
  formulaire,
  setShowFormulaQuestions,
  updateSectionQuestions,
  setShowNewBOFormModal = () => {},
  readonly
}: FormKycProps) => {
  const { t } = useTranslation();

  const [questionsValues, setQuestionsValues] = useState<QuestionForm[]>([]);
  const [visibleQuestions, setVisibleQuestions] = useState<Question[]>([]);
  const [allQuestions, setAllQuestions] = useState<Question[]>([]);

  const updateVisibleQuestions = (refracted: any) => {
    const data: QuestionForm = refracted;

    let answers: Question[] = allQuestions.map((qes) => {
      if (qes.id_condition === data.id) qes.answer = null;
      return qes.id === data.id ? { ...qes, answer: data.value } : qes;
    });

    answers = answers.map((qes, index, questions) => {
      const { id_condition, condition_answer } = qes;
      qes.canSee = canShowQuestion(
        id_condition || null,
        condition_answer || null,
        questions || [],
        qes.visible
      );
      return qes;
    });

    answers = answers.map((qes) => {
      const { canSee } = qes;
      if (!canSee) qes.answer = null;
      return qes;
    });

    const visibleQuestions = filterVisibleQuestions(answers);
    const visibleQuestionsWithTables = formatTableQuestions(visibleQuestions);

    setAllQuestions(answers);
    setVisibleQuestions(visibleQuestionsWithTables);
  };

  const handleQuestionUpdate = (answer: any, id: string, type = 'ONE') => {
    let data = {
      id,
      value: '',
      currency: '',
      other_value: ''
    };

    switch (type) {
      case 'CHECKBOX': {
        const previousAnswer = getQuestionPreviousAnswer(
          id || '',
          allQuestions
        ) as string;
        let values = previousAnswer.split(' | ').filter((val: any) => val);
        if (hasValueInQuestion(values, answer)) {
          values = values.filter((value) => value !== answer);
        } else {
          values = [...values, answer];
        }
        data.value = (values || []).join(' | ');
        break;
      }
      case 'MULTISELECT': {
        data.value = answer
          .filter((ans: any) => ans.label)
          .map((ans: any) => ans.label);
        break;
      }
      case 'MULTICOUNTRY': {
        data.value = answer
          .filter((ans: any) => ans.label)
          .map((ans: any) => ans.label);
        break;
      }
      case 'COUNTRY': {
        const country = getCountry(answer, countries);
        data.value = country?.name || '';
        break;
      }
      case 'MONTANT': {
        data = {
          ...data,
          currency: '€',
          value: answer
        };
        break;
      }
      default:
        data.value = answer;
        break;
    }

    updateVisibleQuestions(data);
  };

  const closeFormQuestions = () => {
    setShowFormulaQuestions(false);
  };

  useEffect(() => {
    const questions = formulaire.data.questions;
    const filteredVisibleQuestions = filterVisibleQuestions(
      mapVisibleQuestions(questions)
    );
    const visibleQuestionsWithTables = formatTableQuestions(
      filteredVisibleQuestions
    );

    setAllQuestions(questions || []);
    setVisibleQuestions(visibleQuestionsWithTables);
  }, [formulaire]);

  useEffect(() => {
    const refractQuestionsValues: QuestionForm[] = allQuestions.map((qes) =>
      transformQuestionValues(qes, countries)
    );

    setQuestionsValues(refractQuestionsValues);
  }, [allQuestions, countries]);

  return (
    <SimpleBar style={{ height: '55vh' }}>
      <form
        onSubmit={(e) => updateSectionQuestions(e, questionsValues)}
        className="form-component"
      >
        <FormKycHeader title={formulaire.name} onClose={closeFormQuestions} />

        <div className="form-component__content">
          {(formulaire.name || '').includes(
            'Schedule 2 - Economic Beneficiary Declaration'
          ) && (
            <div className="pb-4 text-end">
              <div
                onClick={() => setShowNewBOFormModal(true)}
                className="cursor-pointer fw-600 "
              >
                <BsFillPlusCircleFill size={20} color="#1a468e" />
                <span style={{ width: 8, display: 'inline-block' }}></span>
                <span>{t('souscriptions.stepper.addBo')}</span>
              </div>
            </div>
          )}

          {visibleQuestions?.map((question, index) => (
            <QuestionItem
              key={`question-${question.id}-${index}`}
              question={question}
              countries={countries}
              readonly={readonly}
              handleQuestionUpdate={handleQuestionUpdate}
            />
          ))}
        </div>

        {!readonly && (
          <div className="form-component__footer mb-4">
            <button type="submit" className="btn btn-sm btn-basic text-white">
              {t('action.validate')}
            </button>
          </div>
        )}
      </form>
    </SimpleBar>
  );
};

export default FormKYC;
