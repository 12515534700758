import Select from '@components/Select';
import { WHITE } from '@constants/colors';
import { mdiFileDocument, mdiPencil } from '@mdi/js';
import Icon from '@mdi/react';
import { CountryType } from '@models/Country';
import { Document } from '@models/Souscription';
import User, { UserForm } from '@models/User';
import { uboUserInit } from '@pages/Space/Souscription/Stepper/Dossier/constants';
import { UBOUserType } from '@pages/Space/Souscription/Stepper/Dossier/types';
import { UserService } from '@services';
import { FormEvent, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { BsFillPlusCircleFill } from 'react-icons/bs';
import { RiDeleteBinLine } from 'react-icons/ri';
import CountryNames from 'i18n-iso-countries';
import i18next from '@/i18n';
import 'simplebar/dist/simplebar.min.css';

interface BOListBoxProps {
  beneficiareUsers: User[];
  boForm: UserForm;
  uploadedDocuments: Record<string, Document[]>;
  toUploadDocumentsLength: number;
  countries: CountryType[];
  setShowBODocumentType: (value: boolean) => void;
  setUboUserForm: (form: UBOUserType) => void;
  setBoForm: (form: UserForm) => void;
  loadBOs: () => void;
  setShowNewBOFormModal: (value: boolean) => void;
}

const BOListBox = ({
  beneficiareUsers,
  boForm,
  uploadedDocuments,
  toUploadDocumentsLength,
  countries,
  setShowBODocumentType,
  setUboUserForm,
  setBoForm,
  loadBOs,
  setShowNewBOFormModal
}: BOListBoxProps) => {
  const { t } = useTranslation();
  const [showUpdateBOFormModal, setShowUpdateBOFormModal] = useState(false);
  const [showDeleteBOModal, setShowDeleteBOModal] = useState(false);
  const [selectedBO, setSelectedBO] = useState<User | null>(null);
  const language = i18next.language;

  const loadBoDocuments = (bo: User) => {
    setShowBODocumentType(true);
    setUboUserForm({
      ...uboUserInit,
      id: bo.id,
      lastname: bo.lastname,
      firstname: bo.firstname,
      email: bo.email,
      telephone: bo.telephone,
      citizenship: bo.citizenship,
      birthdate: bo.birthdate
    });
  };

  const handleUpdateBO = (user: any) => {
    setShowUpdateBOFormModal(true);
    setSelectedBO(user);
    setBoForm(user);
  };

  const handleDeleteBO = (user: any) => {
    setShowDeleteBOModal(true);
    setSelectedBO(user);
  };

  const handleSubmitUpdateBOForm = async (event: FormEvent, id: string) => {
    event.preventDefault();
    await UserService.updateBO(boForm, id);
    setShowUpdateBOFormModal(false);
    loadBOs();
  };

  const handleSubmitDeleteBO = async (id: string) => {
    await UserService.deleteBO(id);
    setShowDeleteBOModal(false);
    setSelectedBO(null);
    loadBOs();
  };

  return (
    <div className="bo-list-box px-4">
      <div className="d-flex align-items-center justify-content-between py-2 pb-3">
        <div>
          {t('souscriptions.stepper.step.kyc.form.display.bo.compteur')}:{' '}
          {Object.entries(beneficiareUsers)?.length || 0}
        </div>

        <div
          onClick={() => setShowNewBOFormModal(true)}
          className="cursor-pointer fw-600 d-flex align-items-center gap-2"
        >
          <BsFillPlusCircleFill size={20} color="#1a468e" />
          <span>{t('souscriptions.stepper.button.addBo')}</span>
        </div>
      </div>

      <div className="users-list__table mt-2">
        <table className="table table-hover align-middle text-extra mb-0">
          <thead>
            <tr>
              <th
                className="text-left"
                scope="col"
                style={{ width: '30%', paddingLeft: '30px' }}
              >
                {t('souscriptions.stepper.step.kyc.form.display.bo.name')}
              </th>
              <th
                className="text-left"
                scope="col"
                style={{ width: '30%', paddingLeft: '0px' }}
              ></th>
              <th
                className="text-left"
                scope="col"
                style={{ width: '25%', textAlign: 'center' }}
              >
                {t(
                  'souscriptions.stepper.step.kyc.form.display.bo.nationalite'
                )}
              </th>
              <th scope="col" style={{ width: '10%' }}></th>
            </tr>
          </thead>
          <tbody>
            {(beneficiareUsers || []).map((user: User, index: number) => (
              <tr key={`user-bo-kyc-${index}`} className="tr-ligne">
                <td
                  scope="row"
                  className="text-truncate text-left"
                  style={{
                    width: '30%',
                    paddingLeft: '30px'
                  }}
                >
                  {`${user?.firstname || ''} ${user?.lastname || ''}`}
                </td>

                <td
                  className="text-left"
                  style={{
                    width: '30%',
                    paddingLeft: '0px'
                  }}
                >
                  <button
                    className="btn shadow-none text-extra"
                    onClick={() => loadBoDocuments(user)}
                  >
                    <span className="text-lowercase">
                      {`[${
                        (uploadedDocuments[user.id] ?? []).length
                      } document(s) / ${toUploadDocumentsLength}]`}
                    </span>
                  </button>
                </td>

                <td
                  className="fw-500 text-truncate text-center"
                  style={{ width: '25%' }}
                >
                  {user.citizenship
                    ? CountryNames.getName(user.citizenship, language)
                    : ''}
                </td>

                <td style={{ width: '10%' }}>
                  <div className="d-flex tr-ligne__icon  justify-content-center align-items-center">
                    <button
                      onClick={() => loadBoDocuments(user)}
                      className="btn btn-accent btn-icon btn-icon-sm me-2"
                    >
                      <Icon
                        path={mdiFileDocument}
                        size={'1rem'}
                        color={WHITE}
                      />
                    </button>

                    <button
                      onClick={() => handleUpdateBO(user)}
                      className="btn btn-accent btn-icon btn-icon-sm me-2"
                    >
                      <Icon path={mdiPencil} size={'1rem'} color={WHITE} />
                    </button>

                    <button
                      onClick={() => handleDeleteBO(user)}
                      className="btn btn-danger btn-icon btn-icon-sm"
                    >
                      <RiDeleteBinLine size={'1rem'} color={WHITE} />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Modal
        show={showUpdateBOFormModal}
        onHide={() => setShowUpdateBOFormModal(false)}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex justify-content-center align-content-center text-black text-center fw-600">
            {t('souscriptions.stepper.step.kyc.bo.update', {
              firstname: selectedBO?.firstname,
              lastname: selectedBO?.lastname
            })}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={(e) => handleSubmitUpdateBOForm(e, selectedBO?.id || '')}
            id="form-new-beneficiary-user"
          >
            <div className="row">
              <div className="form-input mb-4 col-md-6">
                <label className="form-input__lable mb-1 text-extra fw-600">
                  {t('souscriptions.stepper.step.kyc.form.bo.nom.label')}
                </label>
                <div className=" position-relative">
                  <input
                    value={boForm.firstname || ''}
                    placeholder={
                      t(
                        'souscriptions.stepper.step.kyc.form.bo.nom.placeholder'
                      ) || ''
                    }
                    onChange={(e) =>
                      setBoForm({ ...boForm, firstname: e.target.value })
                    }
                    name="firstname"
                    type="text"
                    className="text-extra"
                    required
                  />
                </div>
              </div>

              <div className="form-input mb-4 col-md-6">
                <label className="form-input__lable mb-1 text-extra fw-600">
                  {t('souscriptions.stepper.step.kyc.form.bo.prenom.label')}
                </label>
                <div className=" position-relative">
                  <input
                    value={boForm.lastname || ''}
                    placeholder={
                      t(
                        'souscriptions.stepper.step.kyc.form.bo.prenom.placeholder'
                      ) || ''
                    }
                    onChange={(e) =>
                      setBoForm({ ...boForm, lastname: e.target.value })
                    }
                    name="lastname"
                    type="text"
                    className="text-extra"
                    required
                  />
                </div>
              </div>

              <div className="form-group col-md-6">
                <Select
                  lable={
                    t(
                      'souscriptions.stepper.step.kyc.form.bo.nationalite.label'
                    ) || ''
                  }
                  placeholder={
                    t(
                      'souscriptions.stepper.step.kyc.form.bo.nationalite.placeholder'
                    ) || ''
                  }
                  classLable="text-extra fw-600"
                  classBox=""
                  value={boForm.citizenship || ''}
                  onChange={(e) =>
                    setBoForm({ ...boForm, citizenship: e.target.value })
                  }
                  name="citizenship"
                  required
                >
                  {countries.map((country: any, i) => (
                    <option value={(country?.code || '').toUpperCase()} key={i}>
                      {country?.name}
                    </option>
                  ))}
                </Select>
              </div>

              <div className="form-input mb-4 col-md-6">
                <label className="form-input__lable mb-1 text-extra fw-600">
                  {t('souscriptions.stepper.step.kyc.form.bo.naissanace.label')}
                </label>
                <div className=" position-relative">
                  <input
                    type="date"
                    value={boForm.birthdate || ''}
                    placeholder={
                      t(
                        'souscriptions.stepper.step.kyc.form.bo.naissanace.placeholder'
                      ) || ''
                    }
                    onChange={(e) =>
                      setBoForm({ ...boForm, birthdate: e.target.value })
                    }
                    name="birthdate"
                    className="text-extra"
                    required
                  />
                </div>
              </div>
              <div className="form-input col-md-12 d-flex justify-content-center align-items-center gap-2">
                <button
                  type="submit"
                  className="btn btn-sm btn-basic text-white"
                >
                  {t('souscriptions.stepper.button.enregistrer')}
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <Modal
        size="sm"
        show={showDeleteBOModal}
        onHide={() => setShowDeleteBOModal(false)}
        centered
      >
        <Modal.Body className="text-black py-4">
          <div className="card-title text-center">
            {t('souscriptions.stepper.step.kyc.bo.delete')}: <br />
            <span style={{ fontWeight: 'bold' }}>
              {selectedBO?.firstname} {selectedBO?.lastname}
            </span>
          </div>

          <div className="mt-3 d-flex justify-content-center align-items-center gap-2">
            <button
              className="btn btn-sm btn-primary"
              onClick={() => handleSubmitDeleteBO(selectedBO?.id || '')}
            >
              {t('souscriptions.stepper.button.confirmer')}
            </button>
            &nbsp;
            <button
              className="btn btn-sm btn-danger text-white"
              onClick={() => setShowDeleteBOModal(false)}
            >
              {t('souscriptions.stepper.button.annuler')}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default BOListBox;
