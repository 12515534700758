import { useTranslation } from 'react-i18next';
import 'simplebar/dist/simplebar.min.css';
import {
  ImportFileType,
  UBOUserType
} from '@pages/Space/Souscription/Stepper/Dossier/types';
import { CountryType } from '@models/Country';
import NewDocumentTypeBO from '@pages/Space/Souscription/Stepper/Dossier/NewDocumentTypeBO';
import NewDocumentTypeEntity from '@pages/Space/Souscription/Stepper/Dossier/NewDocumentTypeEntity';

interface NewDocumentTypeProps {
  showNewDocumentTypeNoIDCard: boolean;
  docType: string;
  selectedImage: ImportFileType;
  uboUserForm: UBOUserType;
  countries: CountryType[];
  showNewDocumentTypeIDCard: boolean;
  setShowBODocumentType: (value: boolean) => void;
  setDocType: (type: string) => void;
  setSelectedImage: (image: ImportFileType) => void;
  setShowDocumentType: (value: boolean) => void;
  setShowNewDocumentTypeNoIDCard: (value: boolean) => void;
  setShowNewDocumentTypeIDCard: (value: boolean) => void;
  handleSubmitNewDocumentType: (event: any, type: string) => void;
  onFileChange: (data: any) => void;
  setUboUserForm: (data: UBOUserType) => void;
  isBO?: boolean;
}

const NewDocumentType = ({ isBO, ...restProps }: NewDocumentTypeProps) => {
  const { t } = useTranslation();

  const {
    docType,
    selectedImage,
    setShowBODocumentType,
    setDocType,
    setShowDocumentType,
    handleSubmitNewDocumentType,
    onFileChange,
    uboUserForm
  } = restProps;

  return isBO ? (
    <NewDocumentTypeBO
      docType={docType}
      selectedImage={selectedImage}
      setShowBODocumentType={setShowBODocumentType}
      setDocType={setDocType}
      setShowDocumentType={setShowDocumentType}
      handleSubmitNewDocumentType={handleSubmitNewDocumentType}
      onFileChange={onFileChange}
      uboUserForm={uboUserForm}
    />
  ) : (
    <NewDocumentTypeEntity {...restProps} />
  );
};

export default NewDocumentType;
