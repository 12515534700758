import { Fragment, useEffect, useState, FormEvent } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { mdiClipboardTextOutline, mdiLifebuoy } from '@mdi/js';
import { Modal } from 'react-bootstrap';
import Icon from '@mdi/react';
import { useTranslation } from 'react-i18next';

// import profileImg from '@assets/images/profile/help.jpg';
import { Header } from '@containers';
import { WHITE } from '@constants/colors';
import { MailService, UserService } from '@services';
import Select from '@components/Select';
import { useAppSelector } from '@store';
import Souscription from './Souscription';
import './Space.scss';
import { isConnected } from '@store/auth/selectors';
import { getUserProfile } from '@store/user/selectors';
import { pages } from '@pages/Space/pages';

const Space = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const [showHelpContactModal, setShowHelpContactModal] = useState(false);
  const [showDocumentationIcon, setShowDocumentationIcon] = useState(false);
  const [contactForm, setContactForm] = useState({
    subject: '',
    comment: ''
  });

  const isLogging = useAppSelector(isConnected);
  const profile = useAppSelector(getUserProfile);

  if (!isLogging)
    <Navigate to="/" replace state={{ path: location.pathname }} />;

  const handleSendQuestion = async (event: FormEvent) => {
    event.preventDefault();

    await MailService.contact(contactForm.subject, contactForm.comment);
    setShowHelpContactModal(false);
    setContactForm({
      subject: '',
      comment: ''
    });
  };

  useEffect(() => {
    if (!profile) UserService.profile();
  }, [profile]);

  // useEffect(() => {
  //   UserService.loadProfile();
  // }, []);

  return (
    <Fragment>
      <div className="space">
        <Header classNames="space__header" />

        <div className="space__content">
          <Routes>
            {pages.map(({ component, path }) => {
              return <Route key={path} element={component} path={path} />;
            })}
            <Route path="*" element={<Souscription />} />
          </Routes>
        </div>

        <div className="space__buttons">
          {showDocumentationIcon && (
            <div className="space__buttons__document shadow">
              <Icon
                path={mdiClipboardTextOutline}
                size={'1.5rem'}
                color={WHITE}
              />
              <span className="space__buttons__document__text fw-500 ms-2">
                {t('contactForm.legalDocument')}
              </span>
            </div>
          )}

          <div
            className="space__buttons__help shadow"
            onClick={() => setShowHelpContactModal(true)}
          >
            <Icon path={mdiLifebuoy} size={'1.5rem'} color={WHITE} />
            <span className="space__buttons__help__text fw-500 ms-2">
              {t('contactForm.needHelpQuestion')}
            </span>
          </div>
        </div>
      </div>

      <Modal
        show={showHelpContactModal}
        onHide={() => setShowHelpContactModal(false)}
        contentClassName="bg-accent shadow-lg help-contact-modal"
        aria-labelledby="help-contact-modal"
        centered
        size="lg"
      >
        <Modal.Body className="bg-white text-extra rounded-lg px-4 py-3 help-modal">
          <Modal.Title className="fw-500 fs-3">
            {t('contactForm.title')}
          </Modal.Title>
          <form className="mt-4" onSubmit={handleSendQuestion}>
            <div className="row">
              <div className="col-md-12">
                <Select
                  lable={t('contactForm.questionAbout').toString()}
                  placeholder={t('contactForm.pickTopic').toString()}
                  value={contactForm.subject}
                  required
                  onChange={(e) =>
                    setContactForm({ ...contactForm, subject: e.target.value })
                  }
                >
                  <option value="Gestion utilisateurs">
                    {t('contactForm.topicOptions._1')}
                  </option>
                  <option value="Dossier KYC">
                    {t('contactForm.topicOptions._2')}
                  </option>
                  <option value="E-Signature">
                    {t('contactForm.topicOptions._3')}
                  </option>
                </Select>
              </div>

              <div className="col-md-12">
                <label className={`fw-500 form-input__lable mb-1`}>
                  {t('contactForm.message')}
                </label>
                <textarea
                  className="form-control form-input__control"
                  value={contactForm.comment}
                  required
                  onChange={(e) =>
                    setContactForm({ ...contactForm, comment: e.target.value })
                  }
                  rows={5}
                ></textarea>
              </div>
            </div>

            <div className="text-center mt-4">
              <button type="submit" className="btn btn-primary">
                {t('contactForm.submitQuestion')}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default Space;
