import { Dialog } from '@components/Dialog';
import Logo from '@components/Logo';
import { LG_MIN, SM_MAX } from '@constants/breakpoints';
import { EXTRA } from '@constants/colors';
import { useBreakpoint } from '@hooks';
import { AuthService } from '@services';
import { useAppSelector } from '@store';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import './Header.scss';
import LanguageDropdown from './LanguageDropdown';
import NavMenu from './NavMenu';
import ProfileMenu from './ProfileMenu';

type HeaderProps = {
  classNames?: string;
};

const Header = ({ classNames = '' }: HeaderProps) => {
  const { t } = useTranslation();

  const [toggleMenu, setToggleMenu] = useState(false);
  const [toggleProfile, setToggleProfile] = useState(false);
  const [sticky, setSticky] = useState(true);
  const [logoutDialogOpen, setLogoutDialogOpen] = useState<boolean>(false);

  const menuRef = useRef<HTMLDivElement>(null);
  const profileMenuRef = useRef<HTMLDivElement>(null);
  const navbarRef = useRef<HTMLDivElement>(null);

  const breakpoint = useBreakpoint();
  const location = useLocation();

  const navigate = useNavigate();

  const countSouscription = useAppSelector(
    (state) => state.souscription.countPrepared
  );

  useEffect(() => {
    setToggleMenu(false);
    setToggleProfile(false);
  }, [location]);

  useEffect(() => {
    const handleSticky = () => {
      if (window.scrollY >= 70) {
        setSticky(true);
      } else if (window.scrollY <= 0) {
        setSticky(false);
      }
    };

    window.addEventListener('scroll', handleSticky);

    return () => {
      window.removeEventListener('scroll', handleSticky);
    };
  }, []);

  useEffect(() => {
    const eventClickOutsideProfileMenu = (event: MouseEvent) => {
      if (!profileMenuRef.current) return;

      if (
        !toggleProfile ||
        profileMenuRef.current.contains(event.target as Node)
      )
        return;

      setToggleMenu(false);

      setToggleProfile(false);
    };

    if (toggleProfile)
      document.addEventListener('click', eventClickOutsideProfileMenu);

    return () => {
      document.removeEventListener('click', eventClickOutsideProfileMenu);
    };
  }, [toggleProfile]);

  useEffect(() => {
    const eventClickOutsideMenu = (event: MouseEvent) => {
      if (!menuRef.current) return;

      if (!toggleMenu || menuRef.current.contains(event.target as Node)) return;

      setToggleMenu(false);
      setToggleProfile(false);
    };

    if (toggleMenu) document.addEventListener('click', eventClickOutsideMenu);

    return () => {
      document.removeEventListener('click', eventClickOutsideMenu);
    };
  }, [toggleMenu]);

  const handleLogout = async () => {
    await AuthService.logout();
    navigate('/auth', { replace: true, state: null });
  };

  const toggleLogoutDialog = () => {
    setLogoutDialogOpen((prevOpen) => !prevOpen);
  };

  return (
    <header
      className={`header bg-white p-2 d-flex justify-content-between align-items-center ${
        sticky ? 'header-sticky' : ''
      } ${classNames}`}
      ref={navbarRef}
    >
      <Link
        to="space/souscriptions"
        className="header__brand d-flex align-items-center "
        style={{ textDecoration: 'none' }}
      >
        <Logo color={EXTRA} style={{ width: '8rem' }} />
        {breakpoint.width > SM_MAX && (
          <span className="text-extra fs-8 fw-400">
            {t('menu.aboutPortal')}
          </span>
        )}
      </Link>

      <div className="header__nav d-flex align-items-center">
        {breakpoint.width > LG_MIN && (
          <NavMenu countSouscription={countSouscription} variant="desktop" />
        )}

        <div className="header__nav__item d-flex flex-row justify-center align-items-center gap-4">
          {/* <Avatar classBox="cursor-pointer" onClick={() => setToggleProfile(!toggleProfile)} /> */}
          <ProfileMenu
            toggleProfile={toggleProfile}
            handleToggleProfile={() => setToggleProfile(!toggleProfile)}
            toggleLogoutDialog={toggleLogoutDialog}
            profileMenuRef={profileMenuRef}
          />
          <LanguageDropdown />
        </div>

        {breakpoint.width <= LG_MIN && (
          <NavMenu
            countSouscription={countSouscription}
            variant="mobile"
            handleToggleMenu={() => setToggleMenu(!toggleMenu)}
            toggleMenu={toggleMenu}
            menuRef={menuRef}
          />
        )}
      </div>

      <Dialog
        centered
        show={logoutDialogOpen}
        onHide={toggleLogoutDialog}
        onSubmit={handleLogout}
        title={t('logout.question')}
        yesText={t('logout.confirm')}
        noText={t('logout.cancel')}
      />
    </header>
  );
};

export default Header;
