import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';

import { InvestorService } from '@services';
import EntiteList from './List';
import EntiteForm from './Form';
import EntiteDetails from './Details';

const pages: any[] = [
  { path: '/', component: <EntiteList /> },
  { path: '/nouveau', component: <EntiteForm /> },
  { path: '/:id/modifier', component: <EntiteForm /> },
  { path: '/:id', component: <EntiteDetails /> }
];

const Entites = () => {
  useEffect(() => {
    InvestorService.all();
  }, []);

  return (
    <Routes>
      {pages.map(({ component, path }) => {
        return <Route key={path} element={component} path={path} />;
      })}
      <Route path="*" element={<EntiteList />} />
    </Routes>
  );
};

export default Entites;
