import { WHITE } from '@constants/colors';
import { mdiEye } from '@mdi/js';
import Icon from '@mdi/react';
import Investor from '@models/Investor';
import { useAppSelector } from '@store';
import { getInvestorsCount } from '@store/investor/selectors';
import { useTranslation } from 'react-i18next';
import { MdEdit } from 'react-icons/md';
import { Link } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

export interface EntiteListTableProps {
  entites: Investor[];
}

const EntiteListTable = ({ entites }: EntiteListTableProps) => {
  const { t } = useTranslation();

  const entiteCount = useAppSelector(getInvestorsCount);

  return (
    <div className="table-responsive">
      <table className="entites-list__table table table-hover align-middle text-extra mb-0 border-bottom border-2 border-extra">
        <thead>
          <tr>
            <th scope="col" style={{ width: '22%' }}>
              {t('entites.tableau._1')}
            </th>
            <th scope="col" style={{ width: '22%' }}>
              {t('entites.tableau._2')}
            </th>
            <th scope="col" style={{ width: '22%' }}>
              {t('entites.tableau._3')}
            </th>
            <th scope="col" style={{ width: '22%' }}>
              {t('entites.tableau._4')}
            </th>
            <th scope="col" style={{ width: '10%' }}></th>
          </tr>
        </thead>
      </table>
      <SimpleBar style={{ maxHeight: '50vh' }}>
        <table className="entites-list__table table table-hover align-middle text-extra">
          <tbody>
            {(entites || []).map((entite, index) => (
              <tr key={index} className="tr-ligne">
                <th scope="row" style={{ width: '22%' }}>
                  {entite?.name}
                </th>
                <td className="fw-500" style={{ width: '22%' }}>
                  {entite?.isParent
                    ? `${t('entites.organisation.LP').toString()}`
                    : `${t('entites.organisation.EJ').toString()}`}
                </td>
                <td className="fw-500" style={{ width: '22%' }}>
                  {entite?.fiscal_identifier}
                </td>
                <td className="fw-500" style={{ width: '22%' }}>
                  {entite.country}
                </td>
                <td style={{ width: '10%' }}>
                  <div className="d-flex tr-ligne__icon justify-content-center align-items-center">
                    {/* <button className="btn btn-danger btn-icon btn-icon-sm me-2">
                                                            <BiTrash size={'1rem'} color={WHITE} />
                                                        </button> */}
                    <Link
                      to={`/space/entites/${entite?.id}`}
                      className="btn btn-accent btn-icon btn-icon-sm me-2"
                    >
                      <Icon path={mdiEye} size={'1rem'} color={WHITE} />
                    </Link>
                    <Link
                      to={`/space/entites/${entite?.id}/modifier`}
                      className="btn btn-accent btn-icon btn-icon-sm"
                    >
                      <MdEdit size={'1rem'} color={WHITE} />
                    </Link>
                  </div>
                </td>
              </tr>
            ))}

            {entiteCount <= 0 && (
              <tr>
                <th className="text-center py-5 fs-3" colSpan={4}>
                  {t('entites.none')}
                </th>
              </tr>
            )}
          </tbody>
        </table>
      </SimpleBar>
    </div>
  );
};

export default EntiteListTable;
