import { EXTRA, WHITE } from '@constants/colors';
import { mdiFileDocumentMultiple } from '@mdi/js';
import Icon from '@mdi/react';
import { Section } from '@models/Souscription';
import { downloadDocument } from '@pages/Space/Souscription/Stepper/Dossier/helpers';
import { FundService } from '@services';
import { isAllFormulaComplete, isDocumentsComplete } from '@utils/functions';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { BiRefresh } from 'react-icons/bi';
import { BsCheckLg } from 'react-icons/bs';
import { HiOutlineCloudDownload } from 'react-icons/hi';
import { TbClipboardList } from 'react-icons/tb';

interface DossierSidebarProps {
  sections: Section[];
  selectedSection: Section | null;
  fund: string;
  setSectionStep: (step: number) => void;
  setSelectedSection: (section: Section | null) => void;
  resetForms: (newSection?: boolean) => void;
  setShowFormulaQuestions: (value: boolean) => void;
  setReadonlyForm: (value: boolean) => void;
}

const DossierSidebar = ({
  sections,
  selectedSection,
  fund,
  setSectionStep,
  setSelectedSection,
  resetForms,
  setShowFormulaQuestions,
  setReadonlyForm
}: DossierSidebarProps) => {
  const { t } = useTranslation();
  const [fundDocuments, setFundDocuments] = useState([]);
  const [showDocumentationModal, setShowDocumentationModal] = useState(false);

  const goToSelectedSection = (section: Section) => {
    setSelectedSection(section);
    setSectionStep(sections.indexOf(section));
    resetForms(true);
  };

  useEffect(() => {
    if (fund) {
      const loadDocuments = async () => {
        const data = await FundService.loadDocuments(fund);
        setFundDocuments(data);
      };

      loadDocuments();
    }
  }, [fund]);

  return (
    <div className="souscriptions-stepper-dossier__sidebar">
      <div className="souscriptions-stepper-dossier__sidebar__wrapper">
        <div className="stepper">
          {(sections || []).map((section, index, array) => (
            <div className="stepper__item-box" key={index}>
              <div className={`stepper__step`}>
                {(section.type || '').includes('document') ? (
                  <span
                    className={`stepper__step__icon
                              ${
                                selectedSection?.id === section.id
                                  ? 'active'
                                  : ''
                              }
                              ${
                                isAllFormulaComplete(section) ? 'complete' : ''
                              }`}
                  >
                    {isAllFormulaComplete(section) ? (
                      <BsCheckLg size={15} />
                    ) : selectedSection?.id === section.id ? (
                      <BiRefresh size={20} />
                    ) : (
                      <TbClipboardList size={20} />
                    )}
                  </span>
                ) : (
                  <span
                    className={`stepper__step__icon
                              ${
                                selectedSection?.id === section.id
                                  ? 'active'
                                  : ''
                              }
                              ${
                                isDocumentsComplete(
                                  section.data.documents || []
                                )
                                  ? 'complete'
                                  : ''
                              }`}
                  >
                    {isDocumentsComplete(section.data.documents || []) ? (
                      <BsCheckLg size={15} />
                    ) : selectedSection?.id === section.id ? (
                      <BiRefresh size={20} />
                    ) : (
                      <TbClipboardList size={20} />
                    )}
                  </span>
                )}

                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    goToSelectedSection(section);
                    setShowFormulaQuestions(false);
                    setReadonlyForm(false);
                  }}
                  className={`stepper__step__label ${
                    selectedSection?.id === section.id ? 'active' : ''
                  } ${isAllFormulaComplete(section) ? 'complete' : ''}`}
                >
                  {t(`KYCSectionTitles.${section.category.code}`)}
                </span>
              </div>

              {index + 1 !== array.length && (
                <div className="stepper__progress">
                  <div
                    className={`progress ${
                      selectedSection?.id === section.id ? 'progress--50' : ''
                    } ${isAllFormulaComplete(section) ? 'progress--100' : ''}`}
                  ></div>
                </div>
              )}
            </div>
          ))}
        </div>

        {fundDocuments.length > 0 && (
          <div
            className="d-flex justify-content-center align-items-center gap-2 doc fw-600"
            onClick={() => {
              setShowDocumentationModal(true);
            }}
          >
            <Icon path={mdiFileDocumentMultiple} size={'1rem'} color={WHITE} />
            {t('souscriptions.stepper.button.fundDocumentationButton')}
          </div>
        )}
      </div>

      <Modal
        show={showDocumentationModal}
        onHide={() => setShowDocumentationModal(false)}
        contentClassName="bg-accent shadow-lg help-contact-modal"
        aria-labelledby="help-contact-modal"
        centered
      >
        <Modal.Body className="bg-white text-extra rounded-lg px-4 py-3 help-modal">
          <Modal.Title className="fw-700 fs-4">
            {t('souscriptions.stepper.step.kyc.fund.title')}
          </Modal.Title>
          <div className="mt-4">
            <ul className="list-group list-group-flush">
              {(fundDocuments || []).map((fd: any) => {
                return (
                  <li
                    onClick={() =>
                      downloadDocument(
                        fd?.content || '',
                        fd?.name || '',
                        fd?.filetype || ''
                      )
                    }
                    className="list-group-item d-flex align-items-center justify-content-between cursor-pointer px-0 text-extra"
                    key={fd?.name}
                  >
                    <span className="label-text">{fd?.name}</span>
                    <HiOutlineCloudDownload size={16} color={EXTRA} />
                  </li>
                );
              })}
            </ul>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DossierSidebar;
