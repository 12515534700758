import { useTranslation } from 'react-i18next';
import './Configuration.scss';

export interface ActionButtonsProps {
  isNextActive: boolean;
  goToNext: () => void;
  goToPrevious: () => void;
}

export default function ActionButtons({
  isNextActive,
  goToNext,
  goToPrevious
}: ActionButtonsProps) {
  const { t } = useTranslation();

  return (
    <div className="d-flex justify-content-end align-items-center pt-3 px-2 w-100">
      <button
        type="button"
        onClick={goToPrevious}
        className="btn btn-extra border-2 border-white me-2"
      >
        {t('souscriptions.stepper.button.retour')}
      </button>

      <button
        type="button"
        onClick={goToNext}
        className={`btn ${
          isNextActive ? 'btn-basic text-white' : 'disabled--griser'
        }`}
      >
        {t('souscriptions.stepper.button.suivant')}
      </button>
    </div>
  );
}
