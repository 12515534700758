import { Question } from '@models/Souscription';
import { hasValueInQuestion } from '@utils/functions';

export interface FormKYCMultyCheckboxProps {
  question: Question;
  handleQuestionUpdate: (answer: any, id: string, type?: string) => void;
  readonly?: boolean;
}

const FormKYCMultyCheckbox = ({
  question: { values, id, answer },
  handleQuestionUpdate,
  readonly
}: FormKYCMultyCheckboxProps) => {
  return (
    <div className="checkbox-div">
      {(values || [])?.map((value: string, index2: number) => (
        <div key={`question-value-${id}-${index2}`} className="checkbox-field">
          <label
            htmlFor={`question-${id}-${index2}`}
            className="d-flex gap-3 field mb-4 justify-content-start align-items-start"
          >
            <input
              checked={hasValueInQuestion((answer || '').split(' | '), value)}
              type="checkbox"
              value={value}
              name={id}
              onChange={(e) =>
                handleQuestionUpdate(e.target.value, id || '', 'CHECKBOX')
              }
              className={`input-box d-none`}
              id={`question-${id}-${index2}`}
              disabled={readonly}
            />
            <div className="box" />
            {value}
          </label>
        </div>
      ))}
    </div>
  );
};

export default FormKYCMultyCheckbox;
