import { Navigate, useLocation } from 'react-router-dom';

import { useAppSelector } from '@store';
import { isConnected } from '@store/auth/selectors';

interface RouteGuardProps {
  component: JSX.Element;
}

const RouteGuard = ({ component }: RouteGuardProps) => {
  const isLogging = useAppSelector(isConnected);
  const location = useLocation();

  return isLogging ? (
    component
  ) : (
    <Navigate to="/auth/login" replace state={{ path: location.pathname }} />
  );
};

export default RouteGuard;
