import { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import Icon from "@mdi/react";
// import { mdiHelp } from "@mdi/js";

// import Tooltip from "@components/Tooltip";
import pdf2 from '@assets/images/pdf2.jpg';
import { FundService } from '@services';
import { Fund } from '@models/Fund';
import { base64toBlobV2 } from '@utils/functions';
import './Validated.scss';

interface SouscriptionStepperValidatedProps {
  fund_id: string;
}

const SouscriptionStepperValidated = ({
  fund_id
}: SouscriptionStepperValidatedProps) => {
  const [fund, setFund] = useState<Fund | null>(null);
  const { t } = useTranslation();

  const { id } = useParams();

  const navigate = useNavigate();

  const goToPrevious = (): void => {
    navigate(`/space/souscriptions`);
    // navigate(`/space/souscriptions/${id}/stepper/dossiers`, {replace: true});
  };

  useEffect(() => {
    if (fund_id) {
      const loadFund = async () => {
        const data = await FundService.load(fund_id);
        setFund(data);
      };

      loadFund();
    }
  }, [fund_id]);

  const downloadDocument = async (base64: string, filename: string) => {
    try {
      const blob = await base64toBlobV2(base64);
      const blobURL = window.URL.createObjectURL(blob);

      // const tempLink = document.createElement("a");
      // tempLink.style.display = "none";
      // tempLink.href = blobURL;
      // tempLink.setAttribute("download", filename);

      // if (typeof tempLink.download === "undefined") {
      //   tempLink.setAttribute("target", "_blank");
      // }

      // document.body.appendChild(tempLink);
      // tempLink.click();
      // document.body.removeChild(tempLink);

      setTimeout(() => {
        window.URL.revokeObjectURL(blobURL);
      }, 100);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Fragment>
      <Helmet>
        <title>{t('validatedKyc.title')}</title>
      </Helmet>

      <div className="souscriptions-stepper-validated card shadow-lg rounded border-0 overflow-hidden">
        <div className="card-body p-0 souscriptions-stepper-validated__wrapper">
          <div className="souscriptions-stepper-validated__content d-flex align-items-center justify-content-center">
            <div className="souscriptions-stepper-validated__done">
              <div className="souscriptions-stepper-validated__done__lable text-center">
                {t('validatedKyc.subtitle_1')}
                <br /> {t('validatedKyc.subtitle_2')}
              </div>
              {(fund?.library_documents || []).length > 0 && (
                <div className="souscriptions-stepper-validated__done__lable text-center mt-3">
                  {t('validatedKyc.doc')} :
                </div>
              )}
              <div className="souscriptions-stepper-validated__done__termes mt-4">
                {(fund?.library_documents || []).map((doc, index) => (
                  <div
                    className="souscriptions-stepper-validated__document"
                    key={`fund-doc-${index}`}
                  >
                    <div className="souscriptions-stepper-validated__document__title">
                      {doc.name}
                    </div>
                    <div className="souscriptions-stepper-validated__document__preview">
                      <img src={pdf2} alt="" />
                      <div className="souscriptions-stepper-validated__document__preview__icon">
                        <button
                          className="btn btn-icon btn-accent"
                          onClick={() =>
                            downloadDocument(doc.content, doc.name)
                          }
                        >
                          <svg
                            width="15pt"
                            height="15pt"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <path
                              style={{ fill: '#fff' }}
                              d="M 11 2 L 11 4 L 13 4 L 13 2 Z M 11 6 L 11 14.585938 L 7.707031 11.292969 L 6.292969 12.707031 L 12 18.414063 L 17.707031 12.707031 L 16.292969 11.292969 L 13 14.585938 L 13 6 Z M 2 17 L 2 20 C 2 21.09375 2.90625 22 4 22 L 20 22 C 21.09375 22 22 21.09375 22 20 L 22 17 L 20 17 L 20 20 L 4 20 L 4 17 Z "
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-end align-items-center pt-3 px-2 w-100">
        <button
          type="button"
          onClick={goToPrevious}
          className="btn btn-extra border-2 border-white me-2"
        >
          {t('action.back')}
        </button>

        <Link
          to={`/space/souscriptions/${id}/stepper/signature`}
          className={`btn btn-primary`}
        >
          {t('action.next')}
        </Link>
      </div>
    </Fragment>
  );
};

export default SouscriptionStepperValidated;
