import { pays } from '@data/pays';

export enum Languages {
  French = 'fr',
  English = 'en'
}

type LanguageTranslations = {
  [key in Languages]?: string;
};

type OtherCountryTranslations = {
  [key: string]: LanguageTranslations;
};

const otherCountries: OtherCountryTranslations = {
  an: { fr: 'Antilles Néerlandaises', en: 'Netherlands Antilles' },
  cs: { fr: 'Serbie-et-Monténégro', en: 'Serbia and Montenegro' }
};

export const getCountry = (
  key: string,
  language: Languages = Languages.French
) => {
  const country = pays.find(
    (country: any) =>
      (country?.alpha2Code || '').toUpperCase() === (key || '').toUpperCase()
  );
  if (country?.translations[language]) {
    return country.translations[language];
  } else {
    const otherCountry = otherCountries[key];
    if (otherCountry && language in otherCountry) {
      return otherCountry[language];
    }
  }
  return 'Not Specified';
};
