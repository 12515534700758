import FormDescription from '@components/FormDescription';
import Input from '@components/Input';
import InputNumber from '@components/InputNumber';
import PhoneNumberInput from '@components/PhoneNumberInput';
import Select from '@components/Select';
import Textarea from '@components/Textarea';
import { QUESTION_TYPES } from '@constants/question';
import { CountryType } from '@models/Country';
import { Question, QuestionForm } from '@models/Souscription';
import { getInputTypeByQuestionLabel } from '@pages/Space/Souscription/Stepper/Configuration/helpers';
import {
  canShowQuestion,
  filterVisibleQuestions,
  hasValueInQuestion,
  transformQuestionValues
} from '@utils/functions';
import { SyntheticEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MultiSelect } from 'react-multi-select-component';
import SimpleBar from 'simplebar-react';
import './Configuration.scss';

export interface Step2longProps {
  step: number;
  groupedVisibleQuestions: Question[][];
  id?: string;
  setStep: (step: number) => void;
  setAllQuestions: (questions: Question[]) => void;
  goToPrevious: () => void;
  allQuestions: Question[];
  setVisibleQuestions: (questions: Question[]) => void;
  setQuestionsValues: (form: QuestionForm[]) => void;
  countries: CountryType[];
  postData: () => void;
}

export default function Step2long({
  step,
  groupedVisibleQuestions,
  id,
  setStep,
  setAllQuestions,
  goToPrevious,
  allQuestions,
  setVisibleQuestions,
  setQuestionsValues,
  countries,
  postData
}: Step2longProps) {
  const { t } = useTranslation();

  const getQuestionPreviousAnswer = (key: string) => {
    const qst = allQuestions.find((question) => question.id === key);
    return !qst?.answer ? '' : qst.answer;
  };

  const getCountry = (name: string) =>
    countries.find((country) => country.name === name || country.id === +name);

  const refractCountryMultiselectValues = (answer: string) => {
    if (!answer) return [];

    if (typeof answer === 'string') {
      return answer.split(' | ').map((countryName: string) => {
        const country = countries.find(
          (country) => country.name === countryName
        );
        return {
          label: country?.name || '',
          value: country?.id || ''
        };
      });
    } else {
      return answer;
    }
  };

  const refractMultiselectValues = (answer: string) => {
    if (!answer) return [];

    if (typeof answer === 'string') {
      return answer.split(' | ').map((value: string) => {
        return {
          label: value,
          value
        };
      });
    } else {
      return answer;
    }
  };

  const updateVisibleQuestions = (refracted: any) => {
    const data: QuestionForm = refracted;

    let answers: Question[] = allQuestions.map((qes) => {
      if (qes.id_condition === data.id) qes.answer = null;
      return qes.id === data.id ? { ...qes, answer: data.value } : qes;
    });

    answers = answers.map((qes, index, questions) => {
      const { id_condition, condition_answer } = qes;
      qes.canSee = canShowQuestion(
        id_condition || null,
        condition_answer || null,
        questions || [],
        qes.visible
      );
      return qes;
    });

    answers = answers.map((qes) => {
      const { canSee } = qes;
      if (!canSee) qes.answer = null;
      return qes;
    });

    const visibleQuestions = filterVisibleQuestions(answers);

    setAllQuestions(answers);
    setVisibleQuestions(visibleQuestions);
  };

  const handleQuestionUpdate = (answer: any, qesId: string, type = 'ONE') => {
    let data = {
      id: qesId,
      value: '',
      currency: '',
      other_value: ''
    };

    switch (type) {
      case 'CHECKBOX': {
        const previousAnswer = getQuestionPreviousAnswer(id || '') as string;
        let values = previousAnswer.split(' | ').filter((val: any) => val);
        if (hasValueInQuestion(values, answer)) {
          values = values.filter((value) => value !== answer);
        } else {
          values = [...values, answer];
        }
        data.value = (values || []).join(' | ');
        break;
      }
      case 'MULTISELECT': {
        data.value = answer
          .filter((ans: any) => ans.label)
          .map((ans: any) => ans.label);
        break;
      }
      case 'MULTICOUNTRY': {
        data.value = answer
          .filter((ans: any) => ans.label)
          .map((ans: any) => ans.label);
        break;
      }
      case 'COUNTRY': {
        const country = getCountry(answer);
        data.value = country?.name || '';
        break;
      }
      case 'MONTANT': {
        data = {
          ...data,
          currency: '€',
          value: answer
        };
        break;
      }
      default:
        data.value = answer;
        break;
    }

    updateVisibleQuestions(data);
  };

  useEffect(() => {
    const refractQuestionsValues: QuestionForm[] = allQuestions.map((qes) =>
      transformQuestionValues(qes, countries)
    );
    setQuestionsValues(refractQuestionsValues);
  }, [allQuestions]);

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    postData();
    setStep(step + 1);
  };

  return (
    <>
      <SimpleBar className="w-100" style={{ height: '50vh' }}>
        <form
          onSubmit={handleSubmit}
          id="step2long"
          style={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          {groupedVisibleQuestions[step - 2].map(
            ({
              label = '',
              id = '',
              type,
              answer = '',
              values = [],
              help,
              mandatory
            }) => {
              const inputType = getInputTypeByQuestionLabel(
                label.toLowerCase()
              );
              return (
                <div
                  key={id}
                  className="souscriptions-stepper-configuration__questions"
                >
                  {type !== QUESTION_TYPES.CHECKBOX && (
                    <div className="title form-component__important-label">
                      {label}
                    </div>
                  )}
                  <>
                    {/* INPUT */}
                    {type === QUESTION_TYPES.INPUT &&
                      (inputType === 'tel' ? (
                        <PhoneNumberInput
                          id={`question-${id}`}
                          value={answer}
                          onChangeValue={(value) =>
                            handleQuestionUpdate(value, id)
                          }
                          type={inputType}
                          required={mandatory}
                        />
                      ) : (
                        <Input
                          id={`question-${id}`}
                          value={answer}
                          name={id}
                          onChange={(e) =>
                            handleQuestionUpdate(e.target.value, id)
                          }
                          type={inputType}
                          required={mandatory}
                        />
                      ))}
                    {/* PERCENTAGE */}
                    {type === QUESTION_TYPES.PERCENTAGE && (
                      <InputNumber
                        id={`question-${id}`}
                        value={answer}
                        name={id}
                        type="text"
                        required={mandatory}
                        numberSign="%"
                        onChangeValue={(value) =>
                          handleQuestionUpdate(value, id)
                        }
                      />
                    )}
                    {/* TEXTAREA */}
                    {type === QUESTION_TYPES.TEXTAREA && (
                      <Textarea
                        id={`question-${id}`}
                        value={answer}
                        name={id}
                        onChange={(e) =>
                          handleQuestionUpdate(e.target.value, id)
                        }
                        rows={6}
                        cols={80}
                        required={mandatory}
                      />
                    )}
                    {/* DATE PICKER */}
                    {type === QUESTION_TYPES.DATE && (
                      <Input
                        id={`question-${id}`}
                        value={answer}
                        name={id}
                        onChange={(e) =>
                          handleQuestionUpdate(e.target.value, id)
                        }
                        type="date"
                        required={mandatory}
                      />
                    )}
                    {/* MULTI SELECT */}
                    {type === QUESTION_TYPES.MULTI_SELECT && (
                      <MultiSelect
                        options={values?.map((value: string) => ({
                          label: value,
                          value
                        }))}
                        value={refractMultiselectValues(answer)}
                        onChange={(value: any) =>
                          handleQuestionUpdate(value, id, 'MULTISELECT')
                        }
                        className="w-100"
                        labelledBy="Select"
                      />
                    )}
                    {/* MULTI SELECT COUNTRY */}
                    {type === QUESTION_TYPES.COUNTRY_MULTI_SELECT && (
                      <MultiSelect
                        options={countries.map((c) => ({
                          label: c.name,
                          value: c.id
                        }))}
                        value={refractCountryMultiselectValues(answer)}
                        onChange={(value: any) =>
                          handleQuestionUpdate(value, id, 'MULTICOUNTRY')
                        }
                        className="w-100"
                        labelledBy="Select"
                      />
                    )}
                    {/* RADIO */}
                    {type === QUESTION_TYPES.RADIO && (
                      <div className="checkbox-div">
                        {values?.map((value: string, index2: number) => (
                          <div
                            key={`question-value-${id}-${index2}`}
                            className="checkbox-field"
                          >
                            <label
                              htmlFor={`question-${id}-${index2}`}
                              className="d-flex gap-3 field mb-4 justify-content-start align-items-start"
                            >
                              <input
                                checked={answer === value}
                                type="radio"
                                value={value}
                                name={id}
                                onChange={(e) =>
                                  handleQuestionUpdate(e.target.value, id)
                                }
                                className="input-box required"
                                id={`question-${id}-${index2}`}
                                required={mandatory}
                              />
                              <div className="box box--radio" />
                              {value}
                            </label>
                          </div>
                        ))}
                      </div>
                    )}
                    {/* MULTI CHECKBOX */}
                    {type === QUESTION_TYPES.CHECKBOX &&
                      values?.map((value: string, index2: number) => (
                        <div
                          key={`question-value-${id}-${index2}`}
                          className="checkbox-field"
                        >
                          <label
                            htmlFor={`question-${id}-${index2}`}
                            className="d-flex gap-3 field mb-4 justify-content-start align-items-start"
                          >
                            <input
                              checked={hasValueInQuestion(
                                (answer || '').split(' | '),
                                value
                              )}
                              type="checkbox"
                              value={value}
                              name={id}
                              onChange={(e) =>
                                handleQuestionUpdate(
                                  e.target.value,
                                  id,
                                  'CHECKBOX'
                                )
                              }
                              className={`input-box required`}
                              id={`question-${id}-${index2}`}
                              required={mandatory}
                            />
                            <div className="box" />
                            {value}
                          </label>
                        </div>
                      ))}
                    {/* SELECT COUNTRY */}
                    {type === QUESTION_TYPES.COUNTRY_SELECT && (
                      <Select
                        placeholder={t('entites.form.pickCountry').toString()}
                        id={`label-${id}`}
                        value={getCountry(answer)?.id || ''}
                        name={id}
                        onChange={(e) =>
                          handleQuestionUpdate(e.target.value, id, 'COUNTRY')
                        }
                        required={mandatory}
                      >
                        {countries.map(
                          (country: CountryType, index2: number) => (
                            <option
                              key={`country-item-${index2}`}
                              value={country.id}
                            >
                              {country.name}
                            </option>
                          )
                        )}
                      </Select>
                    )}
                    {/* SELECT ANSWER */}
                    {type === QUESTION_TYPES.SELECT && (
                      <Select
                        placeholder={t(
                          'souscriptions.stepper.step.configuration.chooseResp'
                        ).toString()}
                        id={`label-${id}`}
                        value={answer || ''}
                        name={id}
                        onChange={(e) =>
                          handleQuestionUpdate(e.target.value, id)
                        }
                        required={mandatory}
                      >
                        {values?.map((value: string, index12: number) => (
                          <option key={`qes-12-value-${index12}`} value={value}>
                            {value}
                          </option>
                        ))}
                      </Select>
                    )}
                    {/* CHECKBOX */}
                    {type === QUESTION_TYPES.CHECKBOX && (
                      <label
                        id={`question-${id}`}
                        className="d-flex gap-3 field mb-4 justify-content-start align-items-center"
                      >
                        <input
                          checked={(answer || 'Non').toLowerCase() === 'oui'}
                          type="checkbox"
                          value={
                            (answer || 'Non').toLowerCase() === 'oui'
                              ? 'Non'
                              : 'Oui'
                          }
                          name={id}
                          onChange={(e) =>
                            handleQuestionUpdate(e.target.value, id)
                          }
                          className={`input-box required`}
                          id={`question-${id}-input`}
                          required={mandatory}
                        />
                        <div className="box" />
                        {label}
                      </label>
                    )}
                    {/* FORM DESCRIPTION */}
                    {type === QUESTION_TYPES.TEXT && (
                      <FormDescription text={help} />
                    )}
                  </>
                </div>
              );
            }
          )}
        </form>
      </SimpleBar>
      <div className="souscriptions-stepper-configuration__buttons d-flex justify-content-center gap-4 pt-4">
        <button
          type="button"
          form="step2long"
          onClick={goToPrevious}
          className="btn btn-extra border-2 me-2"
        >
          {t('souscriptions.stepper.button.retour')}
        </button>

        <button
          type="submit"
          form="step2long"
          className="btn btn-basic text-white"
        >
          {t('souscriptions.stepper.button.valider')}
        </button>
      </div>
    </>
  );
}
