import { Routes, Route } from 'react-router-dom';

import UserDetails from './Details';
import UserList from './List';
import UserForm from './Form';

const pages: any[] = [
  { path: '/', component: <UserList /> },
  { path: '/nouveau', component: <UserForm /> },
  { path: '/:id/modifier', component: <UserForm /> },
  { path: '/:id', component: <UserDetails /> }
];

const Users = () => {
  return (
    <Routes>
      {pages.map(({ component, path }) => {
        return <Route key={path} element={component} path={path} />;
      })}
      <Route path="*" element={<UserList />} />
    </Routes>
  );
};

export default Users;
