/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FormKYC, OriginSectionForm } from '@containers';
import { CountryType } from '@models/Country';
import {
  Document,
  DocumentForm,
  QuestionForm,
  Section,
  SectionCategory,
  SectionForm
} from '@models/Souscription';
import User, { UserForm } from '@models/User';
import AddEnterpriseDocument from '@pages/Space/Souscription/Stepper/Dossier/AddEnterpriseDocument';
import BODocumentType from '@pages/Space/Souscription/Stepper/Dossier/BODocumentType';
import BOFormModal from '@pages/Space/Souscription/Stepper/Dossier/BOFormModal';
import BOListBox from '@pages/Space/Souscription/Stepper/Dossier/BOListBox';
import Complete from '@pages/Space/Souscription/Stepper/Dossier/Complete';
import DossierGrid from '@pages/Space/Souscription/Stepper/Dossier/DossierGrid';
import DossierHeader from '@pages/Space/Souscription/Stepper/Dossier/DossierHeader';
import DossierList from '@pages/Space/Souscription/Stepper/Dossier/DossierList';
import DossierSidebar from '@pages/Space/Souscription/Stepper/Dossier/DossierSidebar';
import NewDocumentType from '@pages/Space/Souscription/Stepper/Dossier/NewDocumentType';
import Questions from '@pages/Space/Souscription/Stepper/Dossier/Questions';
import ValidationFormModal from '@pages/Space/Souscription/Stepper/Dossier/ValidationFormModal';
import {
  boUserInit,
  importFileInit,
  uboUserInit
} from '@pages/Space/Souscription/Stepper/Dossier/constants';
import { getBoDocumentsById } from '@pages/Space/Souscription/Stepper/Dossier/helpers';
import {
  Displays,
  ImportFileType,
  UBOUserType
} from '@pages/Space/Souscription/Stepper/Dossier/types';
import { CountryService, SouscriptionService, UserService } from '@services';
import { containsString } from '@utils/functions';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import './Dossier.scss';
import CountryNames from 'i18n-iso-countries';
import i18n from '@/i18n';
import { Languages, getCountry } from '@pages/Space/Entite/List/helpers';

interface SouscriptionStepperDossierProps {
  updateStepProgressState: (value: string) => void;
  investor: string;
  fund: string;
}

const SouscriptionStepperDossier = ({
  updateStepProgressState,
  investor,
  fund
}: SouscriptionStepperDossierProps) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const language = i18n.language as Languages;
  const [sections, setSections] = useState<Section[]>([]);
  const [countries, setCountries] = useState<CountryType[]>([]);
  const [beneficiareUsers, setBeneficiareUsers] = useState<User[]>([]);
  const [selectedSection, setSelectedSection] = useState<Section | null>(null);
  const [selectedFormulaire, setSelectedFormulaire] = useState<Section | null>(
    null
  );
  const [uboUserForm, setUboUserForm] = useState<UBOUserType>(uboUserInit);
  const [selectedImage, setSelectedImage] =
    useState<ImportFileType>(importFileInit);
  const [boForm, setBoForm] = useState<UserForm>(boUserInit);
  const [sectionStep, setSectionStep] = useState(0);
  const [docType, setDocType] = useState('');
  const [display, setDisplay] = useState<Displays>('LIST');
  const [progress, setProgress] = useState('incomplete');
  const [showFormulaQuestions, setShowFormulaQuestions] = useState(false);
  const [showNewDocumentType, setShowDocumentType] = useState(false);
  const [showBODocumentType, setShowBODocumentType] = useState(false);
  const [showNewDocumentTypeIDCard, setShowNewDocumentTypeIDCard] =
    useState(false);
  const [showNewDocumentTypeNoIDCard, setShowNewDocumentTypeNoIDCard] =
    useState(false);
  const [showAddDocumentFond, setShowAddDocumentFond] = useState(false);
  const [showValidationForm, setShowValidationForm] = useState(false);
  const [showAddEnterpriseDocument, setShowAddEnterpriseDocument] =
    useState(false);
  const [showNewBOFormModal, setShowNewBOFormModal] = useState(false);
  const [readonlyForm, setReadonlyForm] = useState<boolean>(false);

  const documents = (selectedSection?.data.documents ?? []).filter(
    ({ suggested_document }) => !suggested_document
  );

  const documentsData = documents.reduce(
    (acc: Record<string, Document[]>, cur: Document) => {
      if (!cur.user) {
        acc.toUpload.push(cur);
      } else {
        acc[cur.user] ? acc[cur.user].push(cur) : (acc[cur.user] = [cur]);
      }

      return acc;
    },
    { toUpload: [] }
  );

  const uploadedDocs = documentsData[uboUserForm.id] ?? [];

  const loadKYC = async () => {
    const data = await SouscriptionService.onboarding(id || '');
    const rsDocuments = (data.data.sections || []).filter((sec) =>
      sec.type.includes('document')
    );
    const rsFormularQestions = (data.data.sections || []).filter((sec) =>
      sec.type.includes('question')
    );
    const globalFormaular: Section = {
      id: 'form-453',
      type: 'formulaires',
      name: t('souscriptions.section.forms').toString(),
      category: {
        name: 'form',
        code: SectionCategory.FORM
      },
      data: { formulaires: rsFormularQestions }
    };
    const rsSections = [...rsDocuments, globalFormaular];
    setSections(rsSections);
    setSelectedSection(rsSections[sectionStep]);
  };

  const loadBOs = async () => {
    let BOs = await UserService.loadFromInvestor(investor);
    BOs = BOs.filter((bo) => containsString(bo.role, 'bo'));
    BOs = BOs.map((bo) => ({
      ...bo,
      docsStatics: getBoDocumentsById(bo.id, selectedSection)
    }));
    setBeneficiareUsers(BOs);
  };

  const loadCountries = async () => {
    let data = countries;
    if (!data.length) {
      data = await CountryService.all();
    }

    setCountries(
      data.map((country) => ({
        ...country,
        name:
          CountryNames.getName(country.code, language) ||
          getCountry(country.code, language) ||
          country.code
      }))
    );
  };

  const resetForms = (newSection?: boolean) => {
    setShowBODocumentType(showNewDocumentType && !newSection);
    !showNewDocumentType && !newSection && setUboUserForm(uboUserInit);
    setShowDocumentType(false);
    setShowAddEnterpriseDocument(false);
    setShowNewDocumentTypeNoIDCard(false);
    setShowNewDocumentTypeIDCard(false);
    setDocType('');
    setSelectedImage(importFileInit);
  };

  const handleSubmitDocumentType = async (
    file: ImportFileType,
    doc: Document
  ) => {
    const data: DocumentForm = {
      id_section: selectedSection?.id || '',
      id_document: 0,
      id_originaldoc:
        doc.doctype || (doc.type !== 'custom' ? doc.type : undefined),
      user: uboUserForm.id,
      filename: file.name,
      filetype: file.type,
      file: file.base64,
      name: doc.label,
      id: doc.doctype ? undefined : doc.id
    };

    await SouscriptionService.store(data, id || '');
    loadKYC();
  };

  const handleSubmitDocumentTypeEntity = async (
    file: ImportFileType,
    doc: Document
  ) => {
    const data: DocumentForm = {
      id_section: selectedSection?.id || '',
      id_document: doc.type !== 'custom' ? doc.type : 0,
      id_originaldoc:
        doc.doctype || (doc.type !== 'custom' ? doc.type : undefined),
      filename: file.name,
      filetype: file.type,
      file: file.base64,
      name: doc.label,
      id: doc.doctype ? undefined : doc.id
    };

    await SouscriptionService.store(data, id || '');
    loadKYC();
  };

  const handleSubmitNewDocumentType = async (event: any, type_form: string) => {
    event.preventDefault();

    if (!selectedImage.name) {
      toast.error(t('souscriptions.stepper.document_upload_alert'));
      return;
    }

    const data: DocumentForm = {
      id_section: selectedSection?.id || '',
      id_document: 0,
      filename: selectedImage.name,
      filetype: selectedImage.type,
      file: selectedImage.base64,
      name: docType,
      user: showBODocumentType ? uboUserForm.id : undefined
    };

    try {
      if (type_form !== 'ENTERPRISE_FORM') {
        await UserService.updateBO(
          { ...uboUserForm, role: 'bo' },
          uboUserForm.id
        );

        data.name = `${docType}, ${uboUserForm.id}`;

        loadBOs();
      }

      await SouscriptionService.store(data, id || '');

      loadKYC();

      resetForms();
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  const handleSubmitConfirmDocument = async (
    source: string,
    destination: string
  ) => {
    try {
      const data: any = {
        task: 'import',
        source: source,
        destination: destination
      };

      await SouscriptionService.store(data, id || '');
      loadKYC();
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmitDeleteDocument = async (id: any) => {
    await SouscriptionService.deleteDocument(id);
    loadKYC();
  };

  const handleSubmitFormulaQuestions = async (
    e: any,
    questionToSubmit: QuestionForm[]
  ) => {
    e.preventDefault();

    if (questionToSubmit.length <= 0) {
      toast.error(t('alert.questionToSubmit'));
      return;
    }

    const form: SectionForm = {
      id_section: selectedFormulaire?.id || '',
      // questions: questionToSubmit.map((question) => ({
      //   currency: question.currency,
      //   id: question.id,
      //   other_value: question.other_value,
      //   value: question.id === "qst-1087" ? ((question.value as object[])
      //     .map((o: any) => (o || { value: 582 }).value)
      //     .map((code: string) => code.toLowerCase())
      //     .map((code: string) => ((countries || []).find(c => c.code === code) || { id: 582 }).id)
      //     || [])
      //     : question.value,
      // })),
      questions: questionToSubmit
    };

    await SouscriptionService.store(form, id || '');
    setShowFormulaQuestions(false);
    setReadonlyForm(false);
    loadKYC();
  };

  // TOOOOOO CHECKKKKKK
  const handleUploadNewOrigineDocument = async (event: any) => {
    event.preventDefault();

    const data = {
      id_section: selectedSection?.id || '',
      id_document: 0,
      name: docType,
      filename: selectedImage.name,
      // filetype: selectedImage.type,
      file: selectedImage.base64
    };

    await SouscriptionService.store(data, id || '');
    setShowAddDocumentFond(false);
    setSelectedImage({
      base64: '',
      type: '',
      name: ''
    });
    setDocType('');
    loadKYC();
  };

  const handleFormulaQuestion = (formulaire: Section, readonly = false) => {
    setSelectedFormulaire(formulaire);
    setShowFormulaQuestions(true);
    setReadonlyForm(readonly);
  };

  const canShowListing = () =>
    !showNewDocumentType &&
    !showAddDocumentFond &&
    !showAddEnterpriseDocument &&
    !showFormulaQuestions &&
    !showBODocumentType;

  const onFileChange = (data: any) => {
    setSelectedImage({
      base64: data.file.base64,
      type: data.file.type,
      name: data.file.name
    });

    if (data.data) {
      setUboUserForm({
        ...uboUserForm,
        lastname: data.data.nom,
        firstname: data.data.prenom,
        expire: moment(data.data.expiration_date).format('YYYY-MM-DD'),
        birthdate: moment(data.data.birthday).format('YYYY-MM-DD'),
        citizenship: data.data.Country
      });
    }
  };

  useEffect(() => {
    switch (progress) {
      case 'complete':
        updateStepProgressState('50');
        break;
      default:
        updateStepProgressState('25');
        break;
    }
  }, [progress]);

  useEffect(() => {
    loadKYC();
  }, [id]);

  useEffect(() => {
    if (investor) {
      loadBOs();
    }
  }, [investor]);

  useEffect(() => {
    loadCountries();
  }, [language]);

  return (
    <>
      <Helmet>
        <title>{t('souscriptions.stepper.step.kyc.title')}</title>
      </Helmet>

      <div
        className={`souscriptions-stepper-dossier card shadow-lg rounded border-0 overflow-hidden`}
      >
        <div className={`card-body p-0 souscriptions-stepper-dossier__wrapper`}>
          {progress === 'incomplete' && (
            <>
              <DossierSidebar
                sections={sections}
                selectedSection={selectedSection}
                fund={fund}
                setSectionStep={setSectionStep}
                setSelectedSection={setSelectedSection}
                resetForms={resetForms}
                setShowFormulaQuestions={setShowFormulaQuestions}
                setReadonlyForm={setReadonlyForm}
              />

              <div className="souscriptions-stepper-dossier__content position-relative">
                {showNewDocumentType && (
                  <NewDocumentType
                    showNewDocumentTypeNoIDCard={showNewDocumentTypeNoIDCard}
                    docType={docType}
                    selectedImage={selectedImage}
                    uboUserForm={uboUserForm}
                    countries={countries}
                    showNewDocumentTypeIDCard={showNewDocumentTypeIDCard}
                    setShowBODocumentType={setShowBODocumentType}
                    setShowDocumentType={setShowDocumentType}
                    setShowNewDocumentTypeNoIDCard={
                      setShowNewDocumentTypeNoIDCard
                    }
                    setShowNewDocumentTypeIDCard={setShowNewDocumentTypeIDCard}
                    setSelectedImage={setSelectedImage}
                    setDocType={setDocType}
                    handleSubmitNewDocumentType={handleSubmitNewDocumentType}
                    onFileChange={onFileChange}
                    setUboUserForm={setUboUserForm}
                    isBO={selectedSection?.category.code === SectionCategory.BO}
                  />
                )}

                {showAddEnterpriseDocument && (
                  <AddEnterpriseDocument
                    docType={docType}
                    selectedImage={selectedImage}
                    resetForms={resetForms}
                    handleSubmitNewDocumentType={handleSubmitNewDocumentType}
                    setDocType={setDocType}
                    onFileChange={onFileChange}
                  />
                )}

                {showFormulaQuestions && (
                  <FormKYC
                    setShowFormulaQuestions={(cond: boolean) => {
                      setShowFormulaQuestions(cond);
                      setReadonlyForm(false);
                    }}
                    setShowNewBOFormModal={setShowNewBOFormModal}
                    formulaire={selectedFormulaire!}
                    countries={countries}
                    updateSectionQuestions={(e, data) =>
                      handleSubmitFormulaQuestions(e, data)
                    }
                    readonly={readonlyForm}
                  />
                )}

                {showAddDocumentFond && (
                  <OriginSectionForm
                    onCloseForm={() => setShowAddDocumentFond(false)}
                    type={docType}
                    setSelectedImage={setSelectedImage}
                    onTypeChange={setDocType}
                    onHandleForm={handleUploadNewOrigineDocument}
                    hasFile={selectedImage.name}
                  />
                )}

                {showBODocumentType && (
                  <BODocumentType
                    display={display}
                    uploadedDocuments={uploadedDocs}
                    toUploadDocuments={documentsData.toUpload}
                    uboUserForm={uboUserForm}
                    resetForms={resetForms}
                    setShowBODocumentType={setShowBODocumentType}
                    setShowNewDocumentTypeNoIDCard={
                      setShowNewDocumentTypeNoIDCard
                    }
                    setShowNewDocumentTypeIDCard={setShowNewDocumentTypeIDCard}
                    setDisplay={setDisplay}
                    handleSubmitDocumentType={handleSubmitDocumentType}
                    handleSubmitDeleteDocument={handleSubmitDeleteDocument}
                    handleSubmitConfirmDocument={handleSubmitConfirmDocument}
                    setShowDocumentType={setShowDocumentType}
                  />
                )}

                {canShowListing() && (
                  <>
                    {selectedSection?.category.code === SectionCategory.BO ? (
                      <BOListBox
                        beneficiareUsers={beneficiareUsers}
                        boForm={boForm}
                        countries={countries}
                        uploadedDocuments={documentsData}
                        toUploadDocumentsLength={
                          documentsData?.toUpload?.length ?? 0
                        }
                        setShowBODocumentType={setShowBODocumentType}
                        setUboUserForm={setUboUserForm}
                        setBoForm={setBoForm}
                        loadBOs={loadBOs}
                        setShowNewBOFormModal={setShowNewBOFormModal}
                      />
                    ) : (
                      <>
                        <DossierHeader
                          display={display}
                          selectedSection={selectedSection}
                          setShowAddEnterpriseDocument={
                            setShowAddEnterpriseDocument
                          }
                          setDisplay={setDisplay}
                        />

                        <SimpleBar
                          style={{ height: '50vh' }}
                          className="pb-4 px-4"
                        >
                          {display === 'LIST' && (
                            <DossierList
                              selectedSection={selectedSection}
                              showAddDocumentFond={showAddDocumentFond}
                              handleSubmitDocumentType={
                                handleSubmitDocumentTypeEntity
                              }
                              handleSubmitDeleteDocument={
                                handleSubmitDeleteDocument
                              }
                              handleSubmitConfirmDocument={
                                handleSubmitConfirmDocument
                              }
                              setShowAddDocumentFond={setShowAddDocumentFond}
                              handleFormulaQuestion={handleFormulaQuestion}
                              setShowDocumentType={setShowDocumentType}
                            />
                          )}

                          {display === 'GRID' && (
                            <DossierGrid
                              selectedSection={selectedSection}
                              docType={docType}
                              handleSubmitDocumentType={
                                handleSubmitDocumentTypeEntity
                              }
                              setShowDocumentType={setShowDocumentType}
                              setDocType={setDocType}
                              loadKYC={loadKYC}
                              handleFormulaQuestion={handleFormulaQuestion}
                            />
                          )}
                        </SimpleBar>
                      </>
                    )}
                  </>
                )}
              </div>
            </>
          )}

          {progress === 'complete' && <Complete />}
        </div>
      </div>

      {!showAddEnterpriseDocument &&
        !showNewDocumentTypeIDCard &&
        !showBODocumentType && (
          <Questions
            showFormulaQuestions={showFormulaQuestions}
            progress={progress}
            sections={sections}
            step={sectionStep}
            setProgress={setProgress}
            setShowValidationForm={setShowValidationForm}
            setSectionStep={setSectionStep}
            setSelectedSection={setSelectedSection}
          />
        )}

      <BOFormModal
        showNewBOFormModal={showNewBOFormModal}
        investor={investor}
        boForm={boForm}
        countries={countries}
        setShowNewBOFormModal={setShowNewBOFormModal}
        loadBOs={loadBOs}
        setBoForm={setBoForm}
      />

      <ValidationFormModal
        progress={progress}
        showValidationForm={showValidationForm}
        setShowValidationForm={setShowValidationForm}
        setProgress={setProgress}
      />
    </>
  );
};

export default SouscriptionStepperDossier;
