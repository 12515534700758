import { Question } from '@models/Souscription';
import { InputHTMLAttributes } from 'react';
import { QUESTION_TYPES } from '@constants/question';

export const groupQuestions = (questions: Question[]): Question[][] => {
  const groupedQuestions: Question[][] = [[]];
  let group = 0;

  for (const question of questions) {
    if (question.type === QUESTION_TYPES.SEPARATOR) {
      group += 1;
      groupedQuestions.push([]);
    } else {
      groupedQuestions[group].push(question);
    }
  }

  return groupedQuestions;
};

export const extractFirstQuestionGroup = (
  questions: Question[]
): Question[][] => {
  const groupedQuestions: Question[][] = [[]];

  let i = 0;
  for (; i < questions.length; ++i) {
    const question = questions[i];
    if (question.type === QUESTION_TYPES.SEPARATOR) {
      break;
    }
    groupedQuestions[0].push(question);
  }

  groupedQuestions.push(questions.slice(i + 1));

  return groupedQuestions;
};

export const getInputTypeByQuestionLabel = (
  label = ''
): InputHTMLAttributes<HTMLInputElement>['type'] => {
  if (label.includes('zip code')) {
    return 'number';
  }

  if (label.includes('email') || label.includes('e-mail')) {
    return 'email';
  }

  if (label.includes('téléphone') || label.includes('telephone')) {
    return 'tel';
  }

  return 'text';
};
