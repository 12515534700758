import { File } from '@/core/types/file';
import Input from '@components/Input';
import { EXTRA } from '@constants/colors';
import useDragAndDrop from '@hooks/useDragAndDrop';
import { checkFilesExist, convertBase64 } from '@utils/functions';
import { ChangeEvent, DragEvent, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineCloudUpload } from 'react-icons/hi';
import './index.scss';

interface OrigineSectionFormGridProps {
  type: string;
  onTypeChange: (value: string) => void;
  onUploadDoc?: (file: File) => void;
}

const OrigineSectionFormGrid = ({
  type,
  onUploadDoc = () => {},
  onTypeChange = () => {}
}: OrigineSectionFormGridProps) => {
  const { t } = useTranslation();

  const onHandleDrop = async (e: DragEvent<HTMLLabelElement>) => {
    e.preventDefault();

    setDragOver(false);

    if (checkFilesExist(e)) {
      const data = await convertBase64(
        e?.dataTransfer?.files[0] as unknown as File
      );
      onUploadDoc(data);
    }
  };

  const fileSelect = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files && e?.target?.files.length > 0) {
      const data = await convertBase64(e?.target?.files[0] as unknown as File);
      onUploadDoc(data);
    }

    e.target.value = '';
  };

  const { dragOver, setDragOver, onDragOver, onDragLeave } = useDragAndDrop();

  return (
    <Fragment>
      <input
        type="file"
        className="d-none"
        id={`file-input-type`}
        onChange={fileSelect}
      />

      <div className="souscriptions-stepper-dossier__item">
        <label
          htmlFor={`file-input-type`}
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          onDrop={onHandleDrop}
          className={`souscriptions-stepper-dossier__item__content is-dash`}
        >
          <HiOutlineCloudUpload size={15} color={EXTRA} />

          {!dragOver
            ? t('souscriptions.stepper.step.kyc.form.file.placeholder.default')
            : t('souscriptions.stepper.step.kyc.form.file.placeholder.hover')}
        </label>
        <div className="souscriptions-stepper-dossier__item__title">
          <Input
            classBox="mb-3"
            value={type || ''}
            classInput="upload-document-type"
            placeholder={
              t('souscriptions.stepper.step.kyc.form.type.label') || ''
            }
            onChange={(e) => onTypeChange(e.target.value)}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default OrigineSectionFormGrid;
