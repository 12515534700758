import Input from '@components/Input';
import PhoneNumberInput from '@components/PhoneNumberInput';
import Select from '@components/Select';
import { pays } from '@data/pays';
import { maxDate, minDate } from '@pages/Space/constants';
import { adjustDate } from '@pages/Space/helpers';
import { useAppSelector } from '@store/index';
import { getUserProfile } from '@store/user/selectors';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CountryNames from 'i18n-iso-countries';
import i18n from '@/i18n';

interface Step1ShowAllProps {
  entite: Record<string, string>;
  step: number;
  handleFormChange: (key: string, value: string) => void;
  setStep: (step: number) => void;
}

const Step1ShowAll = ({
  setStep,
  handleFormChange,
  entite,
  step
}: Step1ShowAllProps) => {
  const { t } = useTranslation();
  const [isTaxExempt, setIsTaxExempt] = useState(false);
  const language = i18n.language;
  const { activities } = useAppSelector(getUserProfile) ?? {};

  return (
    <form onSubmit={() => setStep(step + 1)} className="row step1-show-all">
      <Input
        required
        lable={t('entites.form.name').toString()}
        placeholder={t('entites.form.name').toString()}
        classLable="text-extra fw-600 mb-2"
        classBox="mb-4 col-md-12"
        value={entite?.name || ''}
        name="name"
        onChange={(e) => handleFormChange('name', e.target.value)}
      />

      <Input
        required
        type="email"
        lable={t('entites.form.email').toString()}
        placeholder={t('entites.form.email').toString()}
        classLable="text-extra fw-600 mb-2"
        classBox="mb-4 col-md-6"
        value={entite?.email || ''}
        name="email"
        onChange={(e) => handleFormChange('email', e.target.value)}
      />

      <PhoneNumberInput
        required
        lable={t('entites.form.phoneNumber').toString()}
        placeholder={t('entites.form.phoneNumber').toString()}
        classLable="text-extra fw-600 mb-2"
        classBox="mb-4 col-md-6"
        value={entite?.telephone || ''}
        onChangeValue={(value) => handleFormChange('telephone', value)}
      />

      <Select
        required
        lable={t('entites.form.activity').toString()}
        placeholder={t('entites.form.pickActivity').toString()}
        value={entite?.activity || ''}
        classSelect="text-extra"
        classLable="text-extra fw-600 mb-2"
        classBox="mb-4 col-md-4"
        name="activity"
        onChange={(e) => handleFormChange('activity', e.target.value)}
      >
        {activities?.map((activity: string) => (
          <option key={`activity-${activity}`} value={activity}>
            {activity}
          </option>
        ))}
      </Select>

      <Input
        required
        lable={t('entites.form.identifiantFiscal').toString()}
        placeholder={t('entites.form.identifiantFiscal').toString()}
        classLable="text-extra fw-600 mb-2"
        classBox="mb-4 col-md-4"
        value={entite?.fiscal_identifier || ''}
        name="fiscal_identifier"
        onChange={(e) => handleFormChange('fiscal_identifier', e.target.value)}
      />

      <Input
        required
        lable={t('entites.form.formeLegale').toString()}
        placeholder={t('entites.form.formeLegale').toString()}
        classLable="text-extra fw-600 mb-2"
        classBox="mb-4 col-md-4"
        value={entite?.legal_entity_type || ''}
        name="legal_entity_type"
        onChange={(e) => handleFormChange('legal_entity_type', e.target.value)}
      />

      <Select
        required
        lable={t('entites.form.domiciliation.pays').toString()}
        placeholder={t('entites.form.pickCountry').toString()}
        value={entite?.country || ''}
        classSelect="text-extra"
        classLable="text-extra fw-600 mb-2"
        classBox="mb-4 col-md-4"
        name="country"
        onChange={(e) => handleFormChange('country', e.target.value)}
      >
        {pays.map((country: any, index: number) => (
          <option key={index} value={country.alpha2Code}>
            {CountryNames.getName(country.alpha2Code, language) || ''}
          </option>
        ))}
      </Select>

      <Input
        required
        lable={t('entites.form.city').toString()}
        placeholder={t('entites.form.city').toString()}
        classLable="text-extra fw-600 mb-2"
        classBox="mb-4 col-md-8"
        value={entite?.city || ''}
        name="city"
        onChange={(e) => handleFormChange('city', e.target.value)}
      />

      <Input
        required
        lable={t('entites.form.postalCode').toString()}
        placeholder={t('entites.form.postalCode').toString()}
        classLable="text-extra fw-600 mb-2"
        classBox="mb-4 col-md-4"
        value={entite?.zipcode || ''}
        name="zipcode"
        onChange={(e) => handleFormChange('zipcode', e.target.value)}
      />

      <Input
        required
        lable={t('entites.form.domiciliation.adresse').toString()}
        placeholder={t('entites.form.domiciliation.adresse').toString()}
        classLable="text-extra fw-600 mb-2"
        classBox="mb-4 col-md-8"
        value={entite?.address || ''}
        name="address"
        onChange={(e) => handleFormChange('address', e.target.value)}
      />

      <Input
        required
        lable={t('entites.form.enregistrement.label').toString()}
        placeholder={t('entites.form.enregistrement.placeholder').toString()}
        classLable="text-extra fw-600 mb-2"
        classBox="mb-4 col-md-4"
        value={entite?.incorporation_city || ''}
        name="incorporation_city"
        onChange={(e) => handleFormChange('incorporation_city', e.target.value)}
      />

      <div className="col-md-8 d-flex align-items-center">
        <Input
          required={!isTaxExempt}
          type="date"
          lable={t('entites.form.dateImposition').toString()}
          placeholder={t('entites.form.dateImposition').toString()}
          classLable="text-extra fw-600"
          classBox="mb-4 col-md-6"
          value={adjustDate(entite?.taxable_year_end) || ''}
          onChange={(e) => handleFormChange('taxable_year_end', e.target.value)}
          min={minDate}
          max={maxDate}
          disabled={isTaxExempt}
        />

        <label className="d-flex gap-3 field justify-content-start align-items-center px-4">
          <input
            checked={isTaxExempt}
            type="checkbox"
            name="taxStatus"
            id="taxStatus"
            onChange={(e) => setIsTaxExempt(e.target.checked)}
            className={`input-box d-none`}
          />
          <div className="box" />
          {t(
            `souscriptions.stepper.step.configuration.form.entite.taxStatus.label`
          ) || ''}
        </label>
      </div>

      <div className="col-md-12 d-flex justify-content-center gap-4 pt-4">
        <button type="submit" className="btn btn-basic text-white">
          {t('action.next').toString()}
        </button>
      </div>
    </form>
  );
};

export default Step1ShowAll;
