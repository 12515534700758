// TODO: found out other question types
export enum QUESTION_TYPES {
  INPUT = 1,
  RADIO = 2,
  MULTI_CHECKBOX = 3,
  CURRENCY = 4,
  TEXTAREA = 5,
  SEPARATOR = 7,
  TITLE = 8,
  DATE = 9,
  COUNTRY_SELECT = 10,
  CHECKBOX = 11,
  SELECT = 12,
  MULTI_SELECT = 13,
  COUNTRY_MULTI_SELECT = 14,
  TABLE_START = 15,
  TABLE_END = 16,
  PERCENTAGE = 17,
  TEXT = 19,
  COMPOSE = 20,
  ADMIN = 21
}

export const noAnswerQuestionTypes = [
  QUESTION_TYPES.TITLE,
  QUESTION_TYPES.TEXT,
  QUESTION_TYPES.ADMIN
];

export enum QUESTION_STATUS {
  VALIDATED = 1,
  PENDING = 0,
  REJECTED = -1
}
