import { Section } from '@models/Souscription';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';

interface QuestionsProps {
  showFormulaQuestions?: boolean;
  progress: string;
  sections: Section[];
  step: number;
  setProgress: (value: string) => void;
  setShowValidationForm: (value: boolean) => void;
  setSectionStep: (value: number) => void;
  setSelectedSection: (section: Section) => void;
}

const Questions = ({
  showFormulaQuestions,
  progress,
  sections,
  step,
  setProgress,
  setShowValidationForm,
  setSectionStep,
  setSelectedSection
}: QuestionsProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const goToPreviousSouscriptionStep = () => {
    if (progress === 'complete') {
      setProgress('incomplete');
    } else {
      if (step === 0) {
        navigate(`/space/souscriptions/${id}/stepper/configuration`, {
          replace: true
        });
      } else {
        setSectionStep(step - 1);
        setSelectedSection(sections[step - 1]);
      }
    }
  };

  const validate = () => setShowValidationForm(true);

  const goToNexStep = () => {
    setSectionStep(step + 1);
    setSelectedSection(sections[step + 1]);
  };

  const isReadyForValidation =
    sections.length === 1 || sections.length === step + 1;

  return (
    <div className="d-flex justify-content-end align-items-center pt-3 px-2 w-100">
      {!showFormulaQuestions ? (
        progress === 'complete' ? (
          <button
            type="button"
            onClick={goToPreviousSouscriptionStep}
            className={`btn btn-extra border-2 border-white `}
          >
            {t('souscriptions.stepper.button.retour')}
          </button>
        ) : (
          <button
            type="button"
            onClick={goToPreviousSouscriptionStep}
            className={`btn btn-extra border-2 border-white me-2 ${
              progress === 'complete' ? 'disabled' : ''
            }`}
          >
            {t('souscriptions.stepper.button.retour')}
          </button>
        )
      ) : (
        ''
      )}

      {progress !== 'complete' && (
        <button
          type="button"
          onClick={isReadyForValidation ? validate : goToNexStep}
          className={`btn btn-primary`}
          disabled={showFormulaQuestions}
        >
          {t(
            isReadyForValidation
              ? 'souscriptions.stepper.button.demandeValidation'
              : 'action.next'
          )}
        </button>
      )}
    </div>
  );
};

export default Questions;
