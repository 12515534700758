import Input from '@components/Input';
import Title from '@components/Title';
import { Box } from '@containers';
import User from '@models/User';
import { getRole } from '@pages/Space/User/Details/helpers';
import { UserService } from '@services';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import './Details.scss';

const UserDetails = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [thumbnail, setThumbnail] = useState('');
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    if (id) {
      const loadUser = async () => {
        const data = await UserService.show(id);
        setUser(data);
        setThumbnail(data.identity_thumbnail);
      };

      loadUser();
    }
  }, [id]);

  return (
    <div className="user-details">
      <Helmet>
        <title>{`${user?.lastname} ${user?.firstname}`}</title>
      </Helmet>

      <div className="user-details__container container px-3">
        <Title
          title={
            t('titles.detailsUser').toString() +
            `: ${user?.lastname} ${user?.firstname}`
          }
        />

        <Box>
          <div className="user-details__form p-3">
            <div className="row gx-md-5" style={{ alignItems: 'stretch' }}>
              <div className="col-md-6">
                <Input
                  lable={t('users.firstname').toString()}
                  placeholder={t('users.placeholders._firstname').toString()}
                  disabled
                  value={user?.lastname}
                  classInput="text-extra"
                  classLable="text-extra fw-600"
                  classBox="mb-3"
                />
                <Input
                  lable={t('users.lastname').toString()}
                  placeholder={t('users.placeholders._lastname').toString()}
                  disabled
                  value={user?.firstname}
                  classLable="text-extra fw-600"
                  classBox="mb-3"
                />
                <Input
                  lable={t('users.role').toString()}
                  placeholder={t('users.placeholders._role').toString()}
                  disabled
                  value={t(getRole(user?.role || '')).toString()}
                  classLable="text-extra fw-600"
                  classBox="mb-3"
                />
                <Input
                  lable={t('users.email').toString()}
                  placeholder={t('users.placeholders._email').toString()}
                  type="email"
                  disabled
                  value={user?.email}
                  classLable="text-extra fw-600"
                  classBox="mb-3"
                />
                <Input
                  lable={t('users.phone').toString()}
                  placeholder={t('users.placeholders._phone').toString()}
                  disabled
                  value={user?.telephone}
                  classLable="text-extra fw-600"
                  classBox="mb-3"
                />

                <Input
                  lable={t('users.entity').toString()}
                  placeholder={t('users.placeholders._entity').toString()}
                  disabled
                  value={user?.investor.name}
                  classLable="text-extra fw-600"
                  classBox="mb-3"
                />
              </div>

              <div className="col-md-6">
                <div className="form-group h-100">
                  <label className="text-extra fw-600 mb-1">
                    {t('users.idCard')}
                  </label>
                  <div className="user-details__form__cni__container position-relative">
                    <label className={`user-details__form__cni__box`}>
                      {user?.identity && (
                        <img src={`${thumbnail}`} alt="Thumb" />
                      )}
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-md-12 mt-4">
                <div className="user-form__form__buttons row">
                  <div className="col-md-6">
                    {/* <Tooltip
                                            content="Ici seront transmis des complements d'informations pour vous aider à réaliser cette étape."
                                        >
                                            <button
                                                type="button"
                                                className="btn btn-accent btn-icon"
                                            >
                                                <Icon path={mdiHelp} size={'1.3rem'} color={WHITE} />
                                            </button>
                                        </Tooltip> */}
                  </div>
                  <div className="col-md-6 d-flex justify-content-between">
                    <div></div>
                    {/* <Link to={`/space/users/${id}/modifier`} className="btn btn-primary">
                                            MODIFIER
                                        </Link> */}

                    <Link to={`/space/users`} className="btn btn-accent">
                      {t('users.buttons._back')}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </div>
    </div>
  );
};

export default UserDetails;
