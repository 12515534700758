import { Fragment, PropsWithChildren, useEffect } from 'react';
import { useLocation } from 'react-router';

const ScrollToTop = ({ children }: PropsWithChildren<any>) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <Fragment>{children}</Fragment>;
};

export default ScrollToTop;
