import { Question } from '@models/Souscription';
import { useTranslation } from 'react-i18next';

export interface FormKYCTableProps {
  question: Question;
  handleQuestionUpdate: (answer: any, id: string, type?: string) => void;
  readonly?: boolean;
}

const FormKYCTable = ({
  question: { children = [] },
  handleQuestionUpdate
}: FormKYCTableProps) => {
  const { t } = useTranslation();

  return (
    <div className="form-kyc-table">
      <table>
        <thead>
          <tr className="item">
            <td>{t('formKYCTable.questions')}</td>
            <td className="radio">{children[0].values[0]}</td>
            <td className="radio">{children[0].values[1]}</td>
          </tr>
        </thead>
        <tbody>
          {children.map(({ id, label, values, answer }) => (
            <tr key={id} className="item">
              <td>{label}</td>
              {values.map((value: string) => (
                <td key={`${id}-${value}`} className="radio">
                  <label
                    htmlFor={`question-${id}-${value}`}
                    className="label p-1"
                  >
                    <input
                      checked={answer === value}
                      type="radio"
                      value={value}
                      name={id}
                      onChange={(e) =>
                        handleQuestionUpdate(e.target.value, id || '')
                      }
                      className="input-box"
                      id={`question-${id}-${value}`}
                    />
                  </label>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default FormKYCTable;
