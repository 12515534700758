import { UserForm } from '@models/User';
import { togglePassWord } from '@pages/Space/helpers';
import { barColors } from '@pages/Space/User/constants';
import { UserService } from '@services';
import { FormEvent, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import PasswordStrengthBar from 'react-password-strength-bar';

export interface UpdatePasswordModalProps {
  show: boolean;
  user: UserForm;
  userID: string | null;
  handleClose: () => void;
}

const UpdatePasswordModal = ({
  show,
  user,
  userID,
  handleClose
}: UpdatePasswordModalProps) => {
  const { t } = useTranslation();

  const [newPassword, setNewPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const refPassword = useRef<HTMLInputElement>(null);
  const refNewPassword = useRef<HTMLInputElement>(null);
  const refConfirmPassword = useRef<HTMLInputElement>(null);

  const updatePassword = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (refConfirmPassword.current?.value === refNewPassword.current?.value) {
      const data = {
        ...user,
        role: user.role || 'manager',
        password: refNewPassword.current?.value,
        oldpassword: refPassword.current?.value
      };

      await UserService.updatePassWord(data, userID || '');
      window.setTimeout(() => {
        handleClose();
      }, 1500);
    } else {
      toast.error(t('souscriptions.alert.notSamePassword'));
    }
  };

  return (
    <Modal show={show} onHide={handleClose} className="mt-10">
      <Modal.Header closeButton>
        <Modal.Title className="text-extra fw-600 align-self-center block ">
          {t('titles.modifyPassword')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-black py-4">
        <form onSubmit={updatePassword} className="form">
          <div className="form-group mb-4">
            <label htmlFor="password" className="text-extra fw-600 mb-2">
              {t('profile.password._actual')}
            </label>
            <div className="form__field">
              <input
                ref={refPassword}
                type="password"
                className="form-control text-extra fw-600"
                placeholder={t(
                  'profile.password.placeholders._actual'
                ).toString()}
              />
              {showPassword ? (
                <span
                  onClick={() => {
                    setShowPassword(!showPassword);
                    togglePassWord(refPassword);
                  }}
                  className="form__icon"
                >
                  <FaEye />
                </span>
              ) : (
                <span
                  onClick={() => {
                    setShowPassword(!showPassword);
                    togglePassWord(refPassword);
                  }}
                  className="form__icon"
                >
                  <FaEyeSlash />
                </span>
              )}
            </div>
          </div>
          <div className="form-group mb-4">
            <label htmlFor="password" className="text-extra fw-600 mb-2">
              {t('profile.password._new')}
            </label>
            <div className="form__field">
              <input
                ref={refNewPassword}
                type="password"
                className="form-control text-extra fw-600"
                placeholder={t('profile.password.placeholders._new').toString()}
                required
                pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#_?!@$%^&*-]).{8,}"
                title={t('profile.password.title').toString()}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              {showNewPassword ? (
                <span
                  onClick={() => {
                    setShowNewPassword(!showNewPassword);
                    togglePassWord(refNewPassword);
                  }}
                  className="form__icon"
                >
                  <FaEye />
                </span>
              ) : (
                <span
                  onClick={() => {
                    setShowNewPassword(!showNewPassword);
                    togglePassWord(refNewPassword);
                  }}
                  className="form__icon"
                >
                  <FaEyeSlash />
                </span>
              )}
            </div>

            <PasswordStrengthBar
              password={newPassword}
              minLength={8}
              barColors={barColors}
              shortScoreWord={t('users.securityLevel._veryShort')}
              scoreWords={[
                t('users.securityLevel._weak'),
                t('users.securityLevel._medium'),
                t('users.securityLevel._strong'),
                t('users.securityLevel._veryStrong')
              ]}
              style={{ marginTop: '9px' }}
              scoreWordStyle={{
                color: '#496075',
                fontSize: '12px',
                fontWeight: 'bold'
              }}
            />
          </div>
          <div className="form-group mb-4">
            <label htmlFor="password" className="text-extra fw-600 mb-2">
              {t('profile.password._confirm')}
            </label>
            <div className="form__field">
              <input
                ref={refConfirmPassword}
                type="password"
                className="form-control text-extra fw-600"
                placeholder={t(
                  'profile.password.placeholders._confirm'
                ).toString()}
              />
              {showConfirmPassword ? (
                <span
                  onClick={() => {
                    setShowConfirmPassword(!showConfirmPassword);
                    togglePassWord(refConfirmPassword);
                  }}
                  className="form__icon"
                >
                  <FaEye />
                </span>
              ) : (
                <span
                  onClick={() => {
                    setShowConfirmPassword(!showConfirmPassword);
                    togglePassWord(refConfirmPassword);
                  }}
                  className="form__icon"
                >
                  <FaEyeSlash />
                </span>
              )}
            </div>
          </div>
          <div className="d-flex justify-content-end align-items-center gap-3">
            <Button variant="secondary" onClick={handleClose}>
              {t('profile.buttons._cancel')}
            </Button>
            <button className="btn btn-primary">
              {t('profile.buttons._save')}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default UpdatePasswordModal;
