import { HTMLAttributes } from 'react';

import './FormDescription.scss';

interface FormDescriptionProps extends HTMLAttributes<HTMLDivElement> {
  text?: string;
}

const FormDescription = ({
  text = '',
  children,
  className = 'w-100',
  ...args
}: FormDescriptionProps) => {
  const strippedText = text
    ?.replace(/(<([^>]+)>)/gi, '')
    .replace(/&nbsp;/g, ' ');

  return (
    <div
      className={`form-description fw-500 fs-7 ${className}`}
      {...args}
      dangerouslySetInnerHTML={{ __html: strippedText }}
    >
      {children}
    </div>
  );
};

export default FormDescription;
