import DateFormat from '@components/DateFormat';
import { LOW_DANGER, WHITE } from '@constants/colors';
import { defaultDateFormat } from '@constants/format';
import { Document } from '@models/Souscription';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillEye, AiOutlineFileText } from 'react-icons/ai';
import { BiTrash } from 'react-icons/bi';
import { BsPencilFill } from 'react-icons/bs';
import { IoWarning } from 'react-icons/io5';

interface DocumentItemProps {
  variant: string;
  document: Document;
  onDownloadDoc: () => void;
  onDeleteDoc?: () => void;
}

const DocumentItem = ({
  document: {
    cleanfilename,
    suggested_document,
    updated,
    label,
    type,
    status,
    refusal_message,
    id
  },
  onDownloadDoc,
  onDeleteDoc,
  variant
}: DocumentItemProps) => {
  const { t } = useTranslation();
  const isNormal = variant === 'normal';
  const isValid = variant === 'valid';

  return (
    <Fragment>
      <div
        className={`kyc-folder-document-item ${variant} overflow-hidden rounded`}
      >
        <div className="wrapper d-flex">
          <div className="kyc-folder-document-item__filename fw-500 d-flex align-items-center gap-2 overflow-hidden rounded">
            <span>
              <AiOutlineFileText size={10} className="text-extra" />
            </span>
            <span className={`text-truncate ${isNormal && 'fs-7'} pe-2`}>
              {cleanfilename || suggested_document?.filename}
            </span>
            <div className="kyc-folder-document-item__date fw-500 fs-7">
              {t('souscriptions.stepper.step.kyc.form.file.lastUpdate')}:{' '}
              <DateFormat unix format={defaultDateFormat} date={updated} />
            </div>
          </div>
          <div className="kyc-folder-document-item__actions d-flex align-items-center justify-content-between ms-2">
            <span className="others-icons d-flex gap-1">
              <span
                className="p-1 cursor-pointer"
                onClick={onDownloadDoc}
                title={
                  t('souscriptions.stepper.step.kyc.form.file.download', {
                    document: label
                  }) || ''
                }
              >
                <AiFillEye size={15} className="text-extra" />
              </span>
              {!isValid && (
                <>
                  <label
                    htmlFor={`file-input-${type}-status-${status}-id-${id}`}
                    className="p-1 cursor-pointer"
                    title={
                      t('souscriptions.stepper.step.kyc.form.file.upload', {
                        document: label
                      }) || ''
                    }
                  >
                    <BsPencilFill size={10} />
                  </label>
                  <span
                    className="p-1 cursor-pointer"
                    onClick={onDeleteDoc}
                    title={
                      t('souscriptions.stepper.step.kyc.form.file.delete', {
                        document: label
                      }) || ''
                    }
                  >
                    <BiTrash size={15} className="text-extra" />
                  </span>
                </>
              )}
            </span>
          </div>
        </div>
        {variant === 'cancel' && (
          <div className="warn fs-9 fw-500 d-flex gap-2">
            <span>
              <IoWarning fill={LOW_DANGER} stroke={WHITE} size={12} />
            </span>
            <span>{refusal_message}</span>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default DocumentItem;
