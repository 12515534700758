import DoubleAuthenticate from './DoubleAuthenticate';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import LoginToken from './LoginToken';
import Login from './Login';
import { Navigate } from 'react-router';

export const pages: any[] = [
  { path: '/', component: <Navigate replace to="/auth/login" /> },
  { path: '/login', component: <Login /> },
  { path: '/login/token/:token', component: <LoginToken />, secure: false },
  {
    path: '/doubleauthenticate',
    component: <DoubleAuthenticate />,
    secure: true
  },
  { path: '/forgotpassword', component: <ForgotPassword />, secure: false },
  { path: '/resetpassword', component: <ResetPassword />, secure: false }
];
