import Input from '@components/Input';
import Title from '@components/Title';
import { Box } from '@containers';
import { InvestorForm } from '@models/Investor';
import Signataire from '@models/Signataire';
import { InvestorService } from '@services';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import CountryNames from 'i18n-iso-countries';
import i18n from '@/i18n';
import 'simplebar/dist/simplebar.min.css';
import './Detail.scss';

const EntiteDetails = () => {
  const [signers, setSigners] = useState<Signataire[]>([]);
  const [entite, setEntite] = useState<InvestorForm | null>(null);
  const language = i18n.language;
  const { id } = useParams();

  const { t } = useTranslation();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const rs = await InvestorService.show(id || '');
    setEntite(rs);
    if (rs.signers.length > 0) {
      setSigners(rs.signers);
    }
  };

  // const removeSigner = async (idSigner: string) => {
  //   await SignataireService.delete(idSigner);
  //   loadData();
  // };

  return (
    <div className="entite-form">
      <Helmet>
        <title> {`${entite?.name}`} </title>
      </Helmet>

      <div className="entite-form__container container px-3">
        <Title title={entite?.name} />

        <Box className="entite-form__box">
          <SimpleBar className="entite-form__top" style={{ maxHeight: 420 }}>
            <div className="entite-form__form">
              <div className="row g-md-4">
                <Input
                  disabled
                  required
                  classBox="col-md-6"
                  lable={t('entites.form.denomination').toString()}
                  placeholder={t('entites.form.pickdenomination').toString()}
                  value={entite?.name || ''}
                  classInput="text-extra"
                  classLable="text-extra fw-600"
                />

                <Input
                  required
                  disabled
                  classBox="col-md-6"
                  lable={t('entites.form.phoneNumber').toString()}
                  placeholder={t('entites.form.phoneNumber').toString()}
                  value={entite?.telephone || ''}
                  classInput="text-extra"
                  classLable="text-extra fw-600"
                />

                {/* <Select
                  disabled
                  required
                  classBox="col-md-6"
                  lable="Pays de domiciliation"
                  placeholder="Choisissez le pays de domiciliation"
                  value={entite?.country}
                  classSelect="text-extra"
                  classLable="text-extra fw-600"
                >
                  {pays.map((country: any, index: number) => (
                    <option key={index} value={country.alpha2Code}>{country.translations?.fr || ''}</option>
                  ))}
                </Select> */}

                <Input
                  disabled
                  required
                  classBox="col-md-6"
                  lable={t('entites.form.domiciliation.pays').toString()}
                  placeholder={t(
                    'entites.form.domiciliation.phpays'
                  ).toString()}
                  value={
                    entite?.country
                      ? CountryNames.getName(entite.country, language)
                      : ''
                  }
                  classInput="text-extra"
                  classLable="text-extra fw-600"
                />

                <Input
                  disabled
                  required
                  classBox="col-md-6"
                  lable={t('entites.form.identifiantFiscal').toString()}
                  placeholder={t('entites.form.identifiantFiscal').toString()}
                  value={entite?.fiscal_identifier || ''}
                  classInput="text-extra"
                  classLable="text-extra fw-600"
                />

                <Input
                  disabled
                  required
                  classBox="col-md-6"
                  lable={t('entites.form.formeLegale').toString()}
                  placeholder={t('entites.form.formeLegale').toString()}
                  value={entite?.legal_entity_type || ''}
                  classInput="text-extra"
                  classLable="text-extra fw-600"
                />

                <Input
                  disabled
                  required
                  classBox="col-md-6"
                  lable={t('entites.form.activity').toString()}
                  placeholder={t('entites.form.activity').toString()}
                  value={entite?.activity || ''}
                  classInput="text-extra"
                  classLable="text-extra fw-600"
                />

                <Input
                  disabled
                  required
                  classBox="col-md-5"
                  lable={t('entites.form.city').toString()}
                  placeholder={t('entites.form.city').toString()}
                  value={entite?.city || ''}
                  classInput="text-extra"
                  classLable="text-extra fw-600"
                />

                <Input
                  disabled
                  required
                  classBox="col-md-5"
                  lable={t('entites.form.domiciliation.adresse').toString()}
                  placeholder={t(
                    'entites.form.domiciliation.adresse'
                  ).toString()}
                  value={entite?.address || ''}
                  classInput="text-extra"
                  classLable="text-extra fw-600"
                />

                <Input
                  disabled
                  required
                  classBox="col-md-2"
                  lable={t('entites.form.postalCode').toString()}
                  placeholder={t('entites.form.postalCode').toString()}
                  value={entite?.zipcode || ''}
                  classInput="text-extra"
                  classLable="text-extra fw-600"
                />

                {(signers || []).map((signe, index) => (
                  <div className="col-md-12" key={index}>
                    <div className="my-3">
                      <div className="d-flex justify-content-between align-items-center w-100 mb-1">
                        <div className="lh-sm fw-600 fs-5">
                          {t('entites.form.signatory.label')} n°{index + 1}
                        </div>
                        <div className="d-flex align-items-center">
                          {/* <div className="cursor-pointer" onClick={() => removeSigner(signe.id)}>
                            <FaTrashAlt size={20} color={DANGER} />
                          </div> */}
                        </div>
                      </div>
                      <hr className="m-0" />
                    </div>
                    <div className="">
                      <p className="lh-sm fw-600 fs-6 mb-1">
                        {'>'} {t('entites.form.signatory.name.completeName')}:{' '}
                        {signe.lastname} {signe.firstname}
                      </p>
                      <p className="lh-sm fw-600 fs-6 mb-1">
                        {'>'} {t('entites.form.signatory.title')}: {signe.title}
                      </p>
                      <p className="lh-sm fw-600 fs-6 mb-1">
                        {'>'} {t('entites.form.signatory.email')}: {signe.email}
                      </p>
                      <p className="lh-sm fw-600 fs-6 mb-1">
                        {'>'} {t('entites.form.signatory.phone')}:{' '}
                        {signe.telephone}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </SimpleBar>

          <div className="row entite-form__bottom">
            <div className="col-md-6">
              {/* <Tooltip content="Ici seront transmis des complements d'informations pour vous aider à réaliser cette étape.">
                <button type="button" className="btn btn-accent btn-icon">
                  <Icon path={mdiHelp} size={"1.3rem"} color={WHITE} />
                </button>
              </Tooltip> */}
            </div>
            <div className="col-md-6">
              <div className="entite-form__form__buttons d-flex justify-content-end gap-3">
                <div></div>

                <div className="entite-form__form__buttons__right">
                  <Link to={'/space/entites'} className="btn btn-accent">
                    {t('souscriptions.stepper.button.retour')}
                  </Link>
                </div>
                <div className="entite-form__form__buttons__right">
                  <Link
                    to={`/space/entites/${id}/modifier`}
                    className="btn btn-basic text-white"
                  >
                    {t('souscriptions.stepper.button.modifier')}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </div>
    </div>
  );
};

export default EntiteDetails;
