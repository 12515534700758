import { QuestionForm } from '@models/Souscription';

export const montantInit: QuestionForm = {
  id: '',
  value: '',
  currency: '',
  other_value: ''
};

export const qesToExclude = [8, 21];
