import './index.scss';

interface KycFolderDocumentCategoryTitleProps {
  title: string;
  icon: JSX.Element;
}

const KycFolderDocumentCategoryTitle = ({
  title,
  icon
}: KycFolderDocumentCategoryTitleProps) => {
  return (
    <div className="kyc-folder-document-category-title d-flex align-items-center border-bottom gap-3 w-100 pb-2 mt-3 mb-2">
      <span
        className="relative inline-block"
        style={{ width: 18, height: 18, fontWeight: 'bold' }}
      >
        {icon && icon}
      </span>
      <div className="kyc-folder-document-category-title__lable fs-6 fw-700 m-0">
        {title}
      </div>
    </div>
  );
};

export default KycFolderDocumentCategoryTitle;
