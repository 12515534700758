import { FormEvent, Fragment, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Icon from '@mdi/react';
import { mdiCellphoneKey } from '@mdi/js';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Input from '@components/Input';
import { EXTRA } from '@constants/colors';
import { AuthService } from '@services';
import './ForgotPassword.scss';

const ForgotPassword = () => {
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [done, setDone] = useState(false);
  const [emailError, setEmailError] = useState({
    state: false,
    message: ''
  });

  const handelForgotPassword = async (event: FormEvent) => {
    event.preventDefault();
    try {
      await AuthService.forgotPassword(email);
      setDone(true);
    } catch (error) {
      setEmailError({
        state: true,
        message: ''
      });
    }
  };

  return (
    <div className="forgot-password">
      <Helmet>
        <title>{t('titles.doubleAuth')}</title>
      </Helmet>

      <div className="forgot-password__form container px-4">
        <h2 className="forgot-password__form__title text-center mb-4 fs-3">
          {done ? t('auth.passwordReinitMsg') : t('auth.passwordForgottenMsg')}
        </h2>

        {!done ? (
          <Fragment>
            <p className="forgot-password__form__text text-center fw-300">
              {t('auth.passwordForgottenEmail')}
            </p>

            <form onSubmit={handelForgotPassword}>
              <Input
                required
                type="email"
                value={email}
                error={emailError}
                icon={
                  <Icon path={mdiCellphoneKey} size={'1.2rem'} color={EXTRA} />
                }
                classInput="bg-white text-extra border-none"
                classBox="mb-2"
                onChange={(e) => setEmail(e.target.value)}
              />

              <div className="forgot-password__form__button d-flex justify-content-between mt-5">
                <Link
                  to="/auth/login"
                  className="btn btn-outline-secondary text-uppercase"
                >
                  {t('auth.passwordForgottenCancel')}
                </Link>
                <button
                  type="submit"
                  className="btn btn-primary text-uppercase"
                >
                  {t('auth.passwordForgottenSearch')}
                </button>
              </div>
            </form>
          </Fragment>
        ) : (
          <div className="forgot-password__form__button d-flex justify-content-center mt-5">
            <Link
              to="/auth/login"
              className="btn btn-outline-secondary text-uppercase"
            >
              {t('auth.otherLogin')}
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
