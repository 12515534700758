import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { HelmetProvider } from 'react-helmet-async';

import * as serviceWorker from './serviceWorker';

import './assets/scss/bootstrap/custom.scss';
import './assets/scss/responsive.scss';
import './index.scss';

import './i18n';

import App from './App';
import { store } from './core/store';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// serviceWorker.register();
