import FormDescription from '@components/FormDescription';
import Input from '@components/Input';
import InputNumber from '@components/InputNumber';
import { QuestionBoxProps } from '@components/Question';
import Select from '@components/Select';
import Textarea from '@components/Textarea';
import { QUESTION_TYPES } from '@constants/question';
import FormKYCCheckbox from '@containers/FormKYC/FormKYCCheckbox';
import FormKYCMultyCheckbox from '@containers/FormKYC/FormKYCMultyCheckbox';
import FormKYCRadio from '@containers/FormKYC/FormKYCRadio';
import FormKYCTable from '@containers/FormKYC/FormKYCTable';
import {
  getCountry,
  refractCountryMultiselectValues,
  refractMultiselectValues
} from '@containers/FormKYC/helpers';
import { CountryType } from '@models/Country';
import { Question } from '@models/Souscription';
import { containsString } from '@utils/functions';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { MultiSelect } from 'react-multi-select-component';

export const getQuestionConfig = (
  question: Question,
  countries: CountryType[],
  handleQuestionUpdate: (value: string, id: string, type?: string) => void,
  readonly?: boolean
) => {
  const { type, status, label, answer, id, values, help } = question;

  let result: PropsWithChildren<QuestionBoxProps> = {
    children: <></>
  };

  const { t } = useTranslation();

  result = {
    ...result,
    isRejected: status === -1,
    label: label || '',
    labelFor: `label-${id}`
  };

  switch (type) {
    case QUESTION_TYPES.INPUT:
      result = {
        ...result,
        children: (
          <Input
            id={`label-${id}`}
            value={answer || ''}
            onChange={(e) => handleQuestionUpdate(e.target.value, id || '')}
            type={containsString(label, 'zip code') ? 'number' : 'text'}
            disabled={readonly}
          />
        )
      };
      break;
    case QUESTION_TYPES.PERCENTAGE:
      result = {
        ...result,
        children: (
          <InputNumber
            type="text"
            required={false}
            name={id}
            numberSign="%"
            value={answer || ''}
            onChangeValue={(value) => handleQuestionUpdate(value, id || '')}
            disabled={readonly}
          />
        )
      };
      break;
    case QUESTION_TYPES.TEXTAREA:
      result = {
        ...result,
        children: (
          <Textarea
            id={`label-${id}`}
            onChange={(e) => handleQuestionUpdate(e.target.value, id || '')}
            value={answer || ''}
            rows={6}
            cols={80}
            disabled={readonly}
          />
        )
      };
      break;
    case QUESTION_TYPES.DATE:
      result = {
        ...result,
        children: (
          <Input
            id={`label-${id}`}
            value={answer || ''}
            name={id}
            onChange={(e) => handleQuestionUpdate(e.target.value, id || '')}
            type="date"
            disabled={readonly}
          />
        )
      };
      break;
    case QUESTION_TYPES.MULTI_SELECT:
      result = {
        ...result,
        additionalClassNames:
          'form-component__question-field--100 countries-box',
        children: (
          <MultiSelect
            options={values.map((value: string) => ({
              label: value,
              value
            }))}
            value={refractMultiselectValues(answer)}
            onChange={(value: any) =>
              handleQuestionUpdate(value, id || '', 'MULTISELECT')
            }
            className="w-100"
            labelledBy="Select"
            disabled={readonly}
          />
        )
      };
      break;
    case QUESTION_TYPES.COUNTRY_MULTI_SELECT:
      result = {
        ...result,
        additionalClassNames:
          'form-component__question-field--100 countries-box',
        children: (
          <MultiSelect
            options={countries.map((c) => ({
              label: c.name,
              value: c.id
            }))}
            value={refractCountryMultiselectValues(answer, countries)}
            onChange={(value: any) =>
              handleQuestionUpdate(value, id || '', 'MULTICOUNTRY')
            }
            className="w-100"
            labelledBy="Select"
            disabled={readonly}
          />
        )
      };
      break;
    case QUESTION_TYPES.CURRENCY:
      result = {
        ...result,
        children: (
          <InputNumber
            type="text"
            required={false}
            name={id}
            value={answer || ''}
            onChangeValue={(value) =>
              handleQuestionUpdate(value, id || '', 'MONTANT')
            }
            disabled={readonly}
          />
        )
      };
      break;
    case QUESTION_TYPES.RADIO:
      result = {
        ...result,
        labelFor: '',
        children: (
          <FormKYCRadio
            question={question}
            readonly={readonly}
            handleQuestionUpdate={handleQuestionUpdate}
          />
        )
      };
      break;
    case QUESTION_TYPES.MULTI_CHECKBOX:
      result = {
        ...result,
        labelFor: '',
        children: (
          <FormKYCMultyCheckbox
            question={question}
            readonly={readonly}
            handleQuestionUpdate={handleQuestionUpdate}
          />
        )
      };
      break;
    case QUESTION_TYPES.TITLE:
      result = {
        ...result,
        labelFor: '',
        label: '',
        isRejected: false,
        children: (
          <div className="title form-component__important-label">{label}</div>
        )
      };
      break;
    case QUESTION_TYPES.COUNTRY_SELECT:
      result = {
        ...result,
        children: (
          <Select
            placeholder={t(
              'souscriptions.stepper.step.kyc.form.formulaire.placeholder.country_selection'
            ).toString()}
            id={`label-${id}`}
            value={getCountry(answer, countries)?.id || ''}
            name={id}
            onChange={(e) =>
              handleQuestionUpdate(e.target.value, id || '', 'COUNTRY')
            }
            disabled={readonly}
          >
            {countries.map((country: CountryType, index2: number) => (
              <option key={`country-item-${index2}`} value={country.id}>
                {country.name}
              </option>
            ))}
          </Select>
        )
      };
      break;
    case QUESTION_TYPES.SELECT:
      result = {
        ...result,
        children: (
          <Select
            placeholder={t(
              'souscriptions.stepper.step.kyc.form.formulaire.placeholder.answer_selection'
            ).toString()}
            id={`label-${id}`}
            value={answer || ''}
            name={id}
            onChange={(e) => handleQuestionUpdate(e.target.value, id || '')}
            disabled={readonly}
          >
            {values.map((value: string, index12: number) => (
              <option key={`qes-12-value-${index12}`} value={value}>
                {value}
              </option>
            ))}
          </Select>
        )
      };
      break;
    case QUESTION_TYPES.CHECKBOX:
      result = {
        ...result,
        label: '',
        labelFor: '',
        children: (
          <FormKYCCheckbox
            question={question}
            readonly={readonly}
            handleQuestionUpdate={handleQuestionUpdate}
          />
        )
      };
      break;
    case QUESTION_TYPES.TEXT:
      result = {
        ...result,
        children: <FormDescription text={help} />
      };
      break;
    case QUESTION_TYPES.TABLE_START:
      result = {
        ...result,
        label: '',
        children: (
          <FormKYCTable
            question={question}
            readonly={readonly}
            handleQuestionUpdate={handleQuestionUpdate}
          />
        )
      };
      break;
    case QUESTION_TYPES.TABLE_END:
      result = {
        ...result,
        label: ''
      };
      break;
  }

  return result;
};
