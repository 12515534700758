import KycFolderDocument from '@containers/KycFolderDocument';
import KycFolderDocumentCategoryTitle from '@containers/KycFolderDocumentCategoryTitle';
import KycFolderFormula from '@containers/KycFolderFormula';
import { Document, Section } from '@models/Souscription';
import { formsConfig } from '@pages/Space/Souscription/Stepper/Dossier/constants';
import {
  filterByStatus,
  filterBySuggested,
  handleDownloadDocument
} from '@pages/Space/Souscription/Stepper/Dossier/helpers';
import { ImportFileType } from '@pages/Space/Souscription/Stepper/Dossier/types';
import { containsString, isFormulaStatus } from '@utils/functions';
import { useTranslation } from 'react-i18next';
import {
  BsCheckCircleFill,
  BsClockFill,
  BsFillXCircleFill
} from 'react-icons/bs';
import { MdDownloadForOffline } from 'react-icons/md';

interface DossierListProps {
  selectedSection: Section | null;
  showAddDocumentFond: boolean;
  handleSubmitDocumentType: (file: ImportFileType, doc: Document) => void;
  handleSubmitDeleteDocument: (id: string) => void;
  setShowDocumentType: (value: boolean) => void;
  handleSubmitConfirmDocument: (source: string, destination: string) => void;
  setShowAddDocumentFond: (value: boolean) => void;
  handleFormulaQuestion: (formuliare: Section, readonly?: boolean) => void;
}

const DossierList = ({
  selectedSection,
  showAddDocumentFond,
  handleSubmitDocumentType,
  handleSubmitDeleteDocument,
  setShowDocumentType,
  setShowAddDocumentFond,
  handleFormulaQuestion
}: DossierListProps) => {
  const { t } = useTranslation();

  const { type, name, data } = selectedSection || {};
  const { documents, formulaires } = data || {};

  console.log('documents ==> ', documents);

  const filterFormByStatus = (forms: Section[] = [], status: string) =>
    forms.filter((form) => isFormulaStatus(form) === status);

  return (
    <>
      {containsString(type, 'document') && (
        <>
          {(documents || []).filter(
            (doc) =>
              (doc.suggested_document === null || !doc.suggested_document) &&
              doc.status === undefined
          ).length > 0 && (
            <>
              <KycFolderDocumentCategoryTitle
                title={
                  t(
                    'souscriptions.stepper.step.kyc.form.display.document.fournir'
                  ) || ''
                }
                icon={
                  <MdDownloadForOffline
                    size={25}
                    className="absolute-full"
                    color="#19468E88"
                  />
                }
              />

              {(documents || [])
                .filter((doc) => !doc.status && doc.status !== 0)
                .filter((doc) =>
                  (documents || []).every(
                    (dc) => !dc.label.startsWith(`${doc.label}, usr-`)
                  )
                )
                .map((document, index) => {
                  return (
                    (document.suggested_document === null ||
                      !document.suggested_document) &&
                    !document.status && (
                      <KycFolderDocument
                        key={index}
                        document={document}
                        onUploadDoc={(value) =>
                          handleSubmitDocumentType(value, document)
                        }
                        onDeleteDoc={() =>
                          handleSubmitDeleteDocument(document?.id || '')
                        }
                      />
                    )
                  );
                })}
            </>
          )}
          {(filterByStatus(documents, 0).length > 0 ||
            filterBySuggested(documents).length > 0) && (
            <>
              <KycFolderDocumentCategoryTitle
                title={
                  t(
                    'souscriptions.stepper.step.kyc.form.display.document.validation'
                  ) || ''
                }
                icon={<BsClockFill size={15} color="#8a39adbb" />}
              />

              {filterByStatus(documents, 0).map(
                (document: Document, index: number) => (
                  <KycFolderDocument
                    key={index}
                    document={document}
                    onDeleteDoc={() =>
                      handleSubmitDeleteDocument(document?.id || '')
                    }
                    onDownloadDoc={() =>
                      handleDownloadDocument(document?.id || '')
                    }
                    onUploadDoc={(value) =>
                      handleSubmitDocumentType(value, document)
                    }
                  />
                )
              )}
              {filterBySuggested(documents).map(
                (document: Document, index: number) =>
                  (document.suggested_document || null)?.filename && (
                    <KycFolderDocument
                      confirm
                      key={index}
                      document={document}
                      onDownloadDoc={() =>
                        handleDownloadDocument(
                          document.suggested_document?.id || ''
                        )
                      }
                      onDeleteDoc={() =>
                        handleSubmitDeleteDocument(
                          document.suggested_document?.id || ''
                        )
                      }
                      onUploadDoc={(value) =>
                        handleSubmitDocumentType(value, document)
                      }
                    />
                  )
              )}
            </>
          )}

          {filterByStatus(documents, -1).length > 0 && (
            <>
              <KycFolderDocumentCategoryTitle
                title={
                  t(
                    'souscriptions.stepper.step.kyc.form.display.document.refuser'
                  ) || ''
                }
                icon={
                  <BsFillXCircleFill size={15} color="rgb(255, 18, 75,.6)" />
                }
              />

              {filterByStatus(documents, -1).map(
                (document: Document, index: number) => (
                  <KycFolderDocument
                    key={index}
                    document={document}
                    onDeleteDoc={() =>
                      handleSubmitDeleteDocument(document?.id || '')
                    }
                    onDownloadDoc={() =>
                      handleDownloadDocument(document?.id || '')
                    }
                    onUploadDoc={(value) =>
                      handleSubmitDocumentType(value, document)
                    }
                  />
                )
              )}
            </>
          )}
          {filterByStatus(documents, 1).length > 0 && (
            <>
              <KycFolderDocumentCategoryTitle
                title={
                  t(
                    'souscriptions.stepper.step.kyc.form.display.document.valider'
                  ) || ''
                }
                icon={<BsCheckCircleFill size={15} color="#269999" />}
              />

              {filterByStatus(documents, 1).map(
                (document: Document, index: number) => (
                  <KycFolderDocument
                    key={index}
                    document={document}
                    onUploadDoc={(value) =>
                      handleSubmitDocumentType(value, document)
                    }
                    onDownloadDoc={() =>
                      handleDownloadDocument(document?.id || '')
                    }
                  />
                )
              )}
            </>
          )}
          {containsString(name, 'ubo') && (
            <div className="text-center border-top pt-2">
              <button
                onClick={() => setShowDocumentType(true)}
                className="mt-4 btn btn-accent"
              >
                {t('souscriptions.stepper.button.addDocument')}
              </button>
            </div>
          )}
          {containsString(name, 'origine') && !showAddDocumentFond && (
            <div className="text-center border-top pt-4">
              <button
                onClick={() => setShowAddDocumentFond(true)}
                className="btn btn-accent"
              >
                {t('souscriptions.stepper.button.addDocument')}
              </button>
            </div>
          )}
        </>
      )}

      {/* SECTION FORMULAR */}
      {containsString(type, 'formulaire') && (
        <>
          {formsConfig.map(
            ({ status, title, icon, isEditable, needsAnswers }) => {
              const formsByStatus = filterFormByStatus(formulaires, status);

              if (!formsByStatus.length) {
                return null;
              }

              return (
                <div key={status}>
                  <KycFolderDocumentCategoryTitle
                    title={t(title) || ''}
                    icon={icon}
                  />

                  {formsByStatus.map((formula: Section, index: number) => (
                    <KycFolderFormula
                      key={index}
                      isEditable={isEditable}
                      needsAnswers={needsAnswers}
                      title={formula.name}
                      onOpenForm={(readonly) =>
                        handleFormulaQuestion(formula, readonly)
                      }
                    />
                  ))}
                </div>
              );
            }
          )}
        </>
      )}
    </>
  );
};

export default DossierList;
