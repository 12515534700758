import { Question } from '@models/Souscription';

export interface FormKYCRadioProps {
  question: Question;
  handleQuestionUpdate: (answer: any, id: string, type?: string) => void;
  readonly?: boolean;
}

const FormKYCRadio = ({
  question: { values, id, answer },
  handleQuestionUpdate,
  readonly
}: FormKYCRadioProps) => {
  return (
    <div className="checkbox-div">
      {(values || [])?.map((value: string, index2: number) => (
        <div key={`question-value-${id}-${index2}`} className="checkbox-field">
          <label
            htmlFor={`question-${id}-${index2}`}
            className="d-flex gap-3 field mb-4 justify-content-start align-items-start"
          >
            <input
              checked={answer === value}
              type="radio"
              value={value}
              name={id}
              onChange={(e) => handleQuestionUpdate(e.target.value, id || '')}
              className="input-box d-none"
              id={`question-${id}-${index2}`}
              disabled={readonly}
            />
            <div className="box box--radio" />
            {value}
          </label>
        </div>
      ))}
    </div>
  );
};

export default FormKYCRadio;
