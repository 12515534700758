import { ChangeEvent, DragEvent } from 'react';
import { TbCloudUpload } from 'react-icons/tb';
import { FaTimesCircle } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

import Input from '@components/Input';
import useDragAndDrop from '@hooks/useDragAndDrop';
import './index.scss';
import { File } from '@/core/types/file';
import { checkFilesExist, convertBase64 } from '@utils/functions';
import { ImportFileType } from '@pages/Space/Souscription/Stepper/Dossier/types';

interface OriginSectionFormProps {
  type: string;
  hasFile?: string;
  onCloseForm: () => void;
  onTypeChange: (value: string) => void;
  onHandleForm: (event: any) => void;
  setSelectedImage: (file: ImportFileType) => void;
}

const OriginSectionForm = ({
  type,
  hasFile,
  onTypeChange,
  onCloseForm,
  onHandleForm,
  setSelectedImage
}: OriginSectionFormProps) => {
  const { t } = useTranslation();
  const { dragOver, setDragOver, onDragOver, onDragLeave } = useDragAndDrop();

  const handleUploadDocument = async (file: File | null) => {
    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      const data = await convertBase64(file);
      setSelectedImage(data);
    }
  };

  const onHandleDrop = (e: DragEvent<HTMLLabelElement>) => {
    e.preventDefault();

    setDragOver(false);

    if (checkFilesExist(e)) {
      handleUploadDocument(e?.dataTransfer?.files[0] as unknown as File);
    }
  };

  const fileSelect = (e: ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files && e?.target?.files.length > 0) {
      handleUploadDocument(e?.target?.files[0] as unknown as File);
    }

    e.target.value = '';
  };

  return (
    <form onSubmit={onHandleForm} className="origine-section-form w-100 my-3">
      <div
        className="add-document__header w-150 mb-4 d-flex mt-3 justify-content-between align-items-center flex-row"
        style={{ width: '154.1% !important' }}
      >
        <span>{t('containers.originSectionForm.addDocumentLabel')}</span>
        <span onClick={onCloseForm} style={{ width: 30, height: 30 }}>
          <FaTimesCircle size={20} />
        </span>
      </div>

      <p className="fw-600 text-extra">
        {t('containers.originSectionForm.documentTypeLabel')}
      </p>
      <Input
        required
        classBox="mb-2"
        value={type || ''}
        classInput="upload-document-type"
        placeholder="Type de document"
        onChange={(e) => onTypeChange(e.target.value)}
      />
      <input
        type="file"
        style={{ width: '100% !important' }}
        id="new-doc-origine-input"
        className="d-none"
        onChange={fileSelect}
        accept=".xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt, .pdf"
      />
      <label className="mb-3 fw-600 mt-3 text-extra">
        {t('containers.originSectionForm.loadFilePlease')}
      </label>
      <label
        htmlFor="new-doc-origine-input"
        title={`Uploader le document`}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onHandleDrop}
        className="text-truncate mb-2 upload-document-btn rounded btn-outline-extra fw-500 fs-7 d-flex align-items-center gap-2"
      >
        <span>
          <TbCloudUpload size={15} />
        </span>
        {!dragOver ? (
          <span className="text-truncate">
            {hasFile ? hasFile : 'Télécharger ou glisser-déposer le fichier'}
          </span>
        ) : (
          'Déposer ici...'
        )}
      </label>
      <div
        className="d-flex btns justify-content-center align-items-center gap-3"
        style={{ marginTop: '4rem' }}
      >
        <button
          type="button"
          className="btn btn-sm btn-outline-basic bg-white text-basic"
          onClick={onCloseForm}
        >
          {t('action.back')}
        </button>
        <button type="submit" className="btn btn-sm btn-basic text-white">
          {t('action.validate')}
        </button>
      </div>
    </form>
  );
};

export default OriginSectionForm;
